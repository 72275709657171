import React, { useRef } from "react";
import DxPieChart, { Animation } from "devextreme-react/pie-chart";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import "./piechart.scss";

function PieChart(props) {
  // store

  const dataSource = {
    store: new CustomStore({
      //key      : 'id',
      load: (loadOption) => {
        return request({ url: `${props.url}` });
      },
      onLoaded: onLoaded,
    }),
  };

  // hooks
  const chart = useRef();

  // event handlers

  function onLoaded() {
    //chart.current && chart.current.instance && chart.current.instance.render({ force: true, animate: false });
  }

  return (
    <DxPieChart ref={chart} id="components-piechart" dataSource={dataSource} palette={"Office"} resolveLabelOverlapping={"shift"} {...props}>
      <Animation duration={1000} />
      {props.children}
    </DxPieChart>
  );
}

export default PieChart;
