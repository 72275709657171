import React, { useEffect } from "react";
import Chart from "components/chart/chart";
import { url } from "config.js";
import { currencyFormat, dict, colorLuminance, withMemo } from "helper/global";
import styles from "./profile.module.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { connect } from "react-redux";
import { CommonSeriesSettings, Series, ValueAxis, ArgumentAxis, Legend, Label, MinorTick, Format, Tooltip } from "devextreme-react/chart";
import { useImmerReducer } from "use-immer";

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.loading = false;
      draft.data = action.payload;
      return;
    case "escalate":
      draft.escalate = !draft.escalate;
      draft.render++;
      return;
    case "loading":
      draft.loading = !draft.loading;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // vars

  const intialState = {
    data: null,
    escalate: false,
    render: 0,
    loading: false,
  };

  // hooks

  const [state, dispatch] = useImmerReducer(reducer, intialState);

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: "loading" });
        //dispatch({ type: "data", payload: null });
        var result = await request({
          url: `${url}/financing/cost`,
          params: { escalate: state.escalate, scenarioId: props.scenarioId },
        });
        dispatch({ type: "data", payload: result });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.render]);

  // functions

  // event handlers

  function onEscalateChanged(e) {
    dispatch({ type: "escalate" });
  }

  //render

  // function cardRender(title, value) {
  //   return <Card title={title} value={value}></Card>;
  // }

  function toolbarRender() {
    return (
      <div className={`${styles.toolbar} theme-toolbar`}>
        <Toolbar>
          <Item location="after" locateInMenu="never">
            <div className={styles.toolbar_item}>{`${dict("Escalate")}:`}</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxCheckBox"
            options={{ value: state.escalate, onValueChanged: onEscalateChanged }}
          />
        </Toolbar>
      </div>
    );
  }

  // const profileRender = () => {
  //   return (
  //     <div className={styles.chart}>
  //       <Chart dataSource={state.data.profile}>
  //         <CommonSeriesSettings argumentField="year" type="stackedBar" />
  //         <Legend
  //           visible={true}
  //           verticalAlignment="top"
  //           horizontalAlignment="center"
  //           markerRender={markerTemplate}
  //           customizeText={customizeLegendText}
  //         />
  //         <Tooltip enabled={true} customizeTooltip={customizeText} />
  //         <ArgumentAxis allowDecimals={false} minorTickCount={4} visualRange={[props.firstYear, props.lastYear - 1]}>
  //           <MinorTick visible={false} />
  //           <Label>
  //             <Format type="decimal" />
  //           </Label>
  //         </ArgumentAxis>
  //         <ValueAxis minValueMargin={0} maxValueMargin={0} title=""></ValueAxis>
  //         {seriesRender()}
  //       </Chart>
  //     </div>
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // };

  function indicatorRender() {
    return (
      <div className={styles.loading}>
        <LoadIndicator />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {toolbarRender()}
      {state.data && <Profile data={state.data} firstYear={props.firstYear} lastYear={props.lastYear} />}
      {state.loading && indicatorRender()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);

const Profile = withMemo(
  (props) => {
    // event handlers

    function customizeText(e) {
      return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
    }

    function getColor(seriesName) {
      if (!seriesName) return "#fff";
      var object = props.data.funds.find((object) => {
        return seriesName.includes(object.name);
      });

      if (seriesName.includes("NotFunded")) return colorLuminance(object.color, 0.3);
      else return object.color;
    }

    function customizeLegendText(e) {
      if (e.seriesName.includes("NotFunded")) return e.seriesName.replace("NotFunded", " Needs Not Funded");
      return e.seriesName.replace("Funded", " Needs Funded");
    }

    // render

    function seriesRender() {
      var series = [];
      props.data.funds.forEach((object) => {
        var funded = `${object.name}Funded`;
        var notFunded = `${object.name}NotFunded`;
        series.push(<Series key={funded} valueField={funded} name={funded} color={getColor(funded)}></Series>);
        series.push(<Series key={notFunded} valueField={notFunded} name={notFunded} color={getColor(notFunded)}></Series>);
      });
      return series;
    }

    // function markerTemplate(item) {
    //   var color = getColor(item.series.name);
    //   return (
    //     <svg>
    //       <defs>
    //         <pattern id="horzLines" width="8" height="4" patternUnits="userSpaceOnUse">
    //           <line x1="0" y1="0" x2="8" y2="0" stroke="white" strokeWidth="1.5" />
    //         </pattern>
    //       </defs>{" "}
    //       <rect x={0} y={0} width={18} height={18} fill={color}></rect>
    //     </svg>
    //   );
    // }

    return (
      <div className={styles.chart}>
        <Chart dataSource={props.data.profile}>
          <CommonSeriesSettings argumentField="year" type="stackedBar" />
          <Legend
            visible={true}
            verticalAlignment="top"
            horizontalAlignment="center"
            // /markerRender={markerTemplate}
            customizeText={customizeLegendText}
          />
          <Tooltip enabled={true} customizeTooltip={customizeText} />
          <ArgumentAxis allowDecimals={false} minorTickCount={4}>
            <MinorTick visible={false} />
            <Label>
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <ValueAxis minValueMargin={0} maxValueMargin={0} title=""></ValueAxis>
          {seriesRender()}
        </Chart>
      </div>
    );
  },
  ["data"]
);
