import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { addFilter, currency, withMemo } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import DropDown from "components/grid-dropdown/grid-dropdown";
import ProgressPanel from "components/progress-panel/progress-panel";
import Expand from "components/expand-img-editor/expand-img-editor";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // stores
  const activityLogStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          if (!props.selectedRowsData.length === 0) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/activityLog/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedRowsData.map((i) => i.id) },
          });
        } else {
          if (!props.id) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/activityLog/byscenarionode`,
            loadOptions: loadOptions,
            params: { scenarioNodeId: props.id },
          });
        }
      },
      insert: (data) => {
        data.scenarioNodeId = props.id;
        return request({ url: `${url}/activityLog`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/activityLog`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/activityLog/${id}`, method: "Delete" });
      },
    }),
  };

  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/activity/scenarionode/${props.id}`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activity/${id}` });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/conditionRating`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/conditionRating/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event Handler

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITYLOG", url: `${url}/activitylog/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    e.component.columnOption("conditionRating", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("strategy", "visible", false);
    e.component.columnOption("conditionRating", "visible", false);
    exporting.current = false;
  }

  // render

  function dropDownRender(data) {
    return (
      <DropDown data={data} showColumnHeaders={true} height={250} width={500}>
        <Column dataField="name"> </Column>
        <Column dataField="type"> </Column>
      </DropDown>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_ACTIVITYLOG"}>
      <Expand
        id={props.id}
        urls={{
          image: {
            read: `${url}/activitylogimage/activitylog`,
            delete: `${url}/activitylogimage`,
            upload: `${url}/activitylogimage/blob/activitylog`,
          },
          document: {
            read: `${url}/activitylogdocument/activitylog`,
            delete: `${url}/activitylogdocument`,
            upload: `${url}/activitylogdocument/blob/activitylog`,
          },
        }}
      >
        <Grid
          title={"Activity"}
          showTitle={false}
          selection={{ mode: "single" }}
          dataSource={activityLogStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          onSelectionChanged={props.onSelectionChanged}
          allowExpanding={false}
        >
          <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"AssetId"} dataField={"node"} visible={false} width={200} formItem={{ visible: false }} />
          <Column caption={"Strategy"} dataField={"strategy"} visible={false} width={200} formItem={{ visible: false }} />
          <Column
            caption={"Name"}
            dataField={"name"}
            width={350}
            allowEditing={false}
            allowFiltering={false}
            formItem={{ visible: false }}
          />
          <Column
            caption={"Type"}
            dataField={"activityId"}
            calculateDisplayValue="type"
            allowSorting={false}
            width={200}
            editCellRender={dropDownRender}
          >
            <Lookup dataSource={activityStore} valueExpr={"id"} displayExpr="type" />
            <RequiredRule />
          </Column>
          <Column
            caption={"Date"}
            dataField={"dateTime"}
            dataType={"date"}
            dateSerializationFormat={"yyyy-MM-dd"}
            format="MM/dd/yyyy"
            width={150}
            sortOrder={"desc"}
          >
            <RequiredRule />
          </Column>
          <Column caption={"Condition"} dataField={"conditionRatingId"} calculateDisplayValue="conditionRating" width={150}>
            <Lookup dataSource={conditionRatingStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"Remaining Useful Life"}
            dataField={"remainingUsefulLife"}
            dataType={"number"}
            width={150}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column
            caption={"Cost"}
            dataField={"cost"}
            dataType={"number"}
            width={150}
            format={`${currency()}#,##0.##`}
            formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
          />
          <Column visible={false} formItem={{ itemType: "empty" }} />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            width={400}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
        </Grid>
      </Expand>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["id"]));
