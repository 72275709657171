import React from "react";
import PieChart from "components/piechart/piechart";
import { Series, Label, Connector, Legend, Font, Margin, Border } from "devextreme-react/chart";
import styles from "./risk.module.scss";
import { dict } from "helper/global";

function Component(props) {
  function customizePoint(e) {
    return {
      color: e.data.color,
    };
  }

  function customizeText(e) {
    return `${e.value}%`;
  }

  function chartRender() {
    return (
      <PieChart
        dataSource={props.data}
        type="donut"
        innerRadius={0.7}
        //centerRender={innerDonutRender}
        palette={"Office"}
        customizePoint={customizePoint}
      >
        <Series argumentField="rating" valueField="percent">
          <Label visible={true} position="inside" backgroundColor={"none"} customizeText={customizeText}>
            <Connector visible={false} width={1} />
            <Font size={14} />
          </Label>
        </Series>
        <Legend visible={true} orientation="horizontal" verticalAlignment="bottom" horizontalAlignment="center">
          <Margin right={0} left={0} Top={0} bottom={0}></Margin>
          <Border visible={false}></Border>
        </Legend>
        {/* <Title text="Risk" horizontalAlignment="center">
          <Font size={16} color={"gray"}></Font>
          <Margin top={1} bottom={15}></Margin>
        </Title> */}
      </PieChart>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>{dict("Risk")}</div>
      <div className={styles.chart}>{chartRender()}</div>
      <div className={styles.note}>% based on cost</div>
    </div>
  );
}

export default Component;
