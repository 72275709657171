import React from "react";
import Chart from "components/chart/chart";
import {
  CommonSeriesSettings,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Size,
  SeriesTemplate,
} from "devextreme-react/chart";
import styles from "./lifecycle-cost.module.scss";
import { dict } from "helper/global";
import { connect } from "react-redux";

function Component(props) {
  //render

  return (
    <div className={styles.main}>
      <div className={styles.title}>{dict("Cost Profile")}</div>
      <div className={styles.chart}>
        <Chart dataSource={props.data.profile}>
          <CommonSeriesSettings
            argumentField="year"
            type={"bar"}
            valueField={"value"}
            ignoreEmptyPoints={true}
            barPadding={0.9}
          />
          <Legend visible={false} />
          {/* <Tooltip enabled={true} customizeTooltip={customizeText} /> */}

          <ArgumentAxis
            allowDecimals={false}
            minorTickCount={4}
            minValueMargin={0.05}
            maxValueMargin={0.05}
            visualRange={[props.firstYear, props.lastYear - 1]}
          >
            <MinorTick visible={false} />
            <Label>
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <ValueAxis minValueMargin={0} maxValueMargin={0} />
          <SeriesTemplate nameField={"year"}></SeriesTemplate>
          {/* <Size width={500} /> */}
        </Chart>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
