import React, { useState } from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import styles from "./file.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import TileView from "components/doc-tileview/doc-tileview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon } from "helper/global";
import { dict } from "helper/global";

function Component(props) {
  // store

  const fileAssetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/assetfile/asset/${props.data.assetId}`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/assetfile`, method: "Post", data: { ...data, AssetId: props.data.assetId } });
      },
      remove: (id) => {
        return request({ url: `${url}/assetfile/asset/${props.data.assetId}/file/${id}`, method: "Delete" });
      },
      onModified: () => {
        onRefresh();
      },
    }),
  };

  //

  const fileStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/file`, loadOptions: loadOptions, params: { type: "document" } });
      },
      byKey: function (id) {
        return request({ url: `${url}/file/${id}` });
      },
    }),
  };

  // const

  // useState
  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  // render

  const gridRender = () => {
    return (
      <ProgressPanel name={"UPLOAD_ASSET_FILE"} onComplete={onRefresh}>
        <Grid
          title={"Files"}
          showTitle={false}
          dataSource={fileAssetStore}
          allowUpdating={false}
          allowExpanding={false}
          allowDownloading={false}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column dataField="url" caption={""} width={100} allowSorting={false} cellRender={imageRender} formItem={{ visible: false }} />
          <Column visible={false} dataField={"node"} caption={"AssetId"} formItem={{ visible: false }} />
          <Column caption={"Name"} dataField={"fileId"} calculateDisplayValue="name" formItem={{ colSpan: 2 }}>
            <Lookup dataSource={fileStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column visible={false} formItem={{ itemType: "empty" }} />
        </Grid>
      </ProgressPanel>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function imageRender(data) {
    return (
      <div className={styles.icon}>
        <FontAwesomeIcon icon={getIcon(data.value)} />
      </div>
    );
  }

  function tileRender() {
    return (
      <div>
        <TileView
          id={props.assetId}
          urls={{ read: `${url}/assetfile/asset/${props.data.assetId}` }}
          title={props.assetDescription}
          render={render}
          refresh={onRefresh}
        ></TileView>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Tiles")} render={tileRender} />
        <Item title={dict("Grid")} render={() => gridRender()} />
      </TabPanel>
    </div>
  );
}

export default Component;
