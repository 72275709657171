import React, { useState } from "react";
import styles from "./input.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { Column, Lookup } from "devextreme-react/data-grid";
import FieldMap from "./fieldmap/fieldmap";
import Setting from "./setting/setting";

function Component() {
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  // return (
  //   <div className={styles.main}>
  //     <div className={styles.source}>
  //       <Source onSelectionChanged={onSelectionChanged} />
  //     </div>
  //     <div className={styles.container}>
  //       <div className={styles.field}>
  //         {state && <FieldMap sourceId={state.id} />}
  //       </div>
  //       <div className={styles.setting}>
  //         {state && <Setting sourceId={state.id} />}
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className={styles.main}>
      <div className={styles.source}>
        <Source onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>
        {state && <FieldMap sourceId={state.id} />}
      </div>
    </div>
  );
}

const Source = React.memo(
  (props) => {
    const sourceStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `konnect/source`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `konnect/source`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `konnect/source`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `konnect/source/${id}`, method: "Delete" });
        },
      }),
    };

    const typeStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `konnect/source/type` });
        },
        byKey: function (id) {
          return request({ url: `konnect/source/type/${id}` });
        },
      }),
    };

    const authStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `konnect/source/auth` });
        },
        byKey: function (id) {
          return request({ url: `konnect/source/auth/${id}` });
        },
      }),
    };

    // event handlers

    function onSelectionChanged(e) {
      props.onSelectionChanged && props.onSelectionChanged(e);
    }
    //calculateDisplayValue="group"
    return (
      <List
        title={"Source"}
        allowDownloading={false}
        allowAdding={false}
        dataSource={sourceStore}
        onSelectionChanged={onSelectionChanged}
        onInitNewRow={(e) => {
          e.data.enabled = true;
          e.data.test = false;
        }}
      >
        <Column
          visible={false}
          dataField={"id"}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} />
        <Column
          caption={"Description"}
          dataField={"description"}
          visible={false}
        />
        {/* <Column
          caption={"Type"}
          dataField={"typeId"}
          calculateDisplayValue="type"
          visible={false}
        >
          <Lookup
            dataSource={typeStore}
            valueExpr={"id"}
            displayExpr="name"
            allowClearing={true}
          />
        </Column>
        <Column caption={"URL"} dataField={"url"} visible={false} />
        <Column caption={"Headers"} dataField={"headers"} visible={false} />
        <Column caption={"Body"} dataField={"body"} visible={false} />
        <Column caption={"UserName"} dataField={"userName"} visible={false} />
        <Column
          caption={"Password"}
          dataField={"password"}
          visible={false}
          formItem={{
            visible: true,
            editorOptions: {
              mode: "password",
            },
          }}
        />
        <Column caption={"AccessKey"} dataField={"accessKey"} visible={false} />
        <Column
          caption={"Authentication"}
          dataField={"authTypeId"}
          calculateDisplayValue="authType"
          visible={false}
        >
          <Lookup
            dataSource={authStore}
            valueExpr={"id"}
            displayExpr="name"
            allowClearing={true}
          />
        </Column> */}
        <Column
          caption={"Enabled"}
          dataField={"enabled"}
          dataType="boolean"
          visible={false}
        />
        <Column
          caption={"Test"}
          dataField={"test"}
          dataType="boolean"
          visible={false}
        />
        {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          allowFiltering={true}
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { height: 75 },
          }}
        />
      </List>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
  () => {
    return true;
  }
);

export default Component;
