import React, { useState, useEffect } from "react";
import styles from "./attributes.module.scss";
import "./attributes.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { currency } from "helper/global";
import { dict } from "helper/global";

function Component(props) {
  //
  // hooks

  const [state, setState] = useState(null);

  useEffect(() => {
    if (!props.nodeId) return;
    (async () => {
      try {
        var result = await request({ url: `${url}/node/summary/${props.nodeId}` });
        setState(result);
      } catch {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId]);

  // render

  function indicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  const editorOptions = { stylingMode: "filled", height: "40px", readOnly: true };

  function formRender() {
    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <Form formData={state || {}} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
            <GroupItem caption={dict("Attributes")} colCount={5} cssClass={"theme-form"}>
              <GroupItem>
                <SimpleItem dataField="name" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("AssetId")} />
                </SimpleItem>
                <SimpleItem dataField="description" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Description")} />
                </SimpleItem>
                <SimpleItem dataField="class" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Class")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="location" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Location")} />
                </SimpleItem>
                {/* <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Install Year")} />
                </SimpleItem> */}
                {/* <SimpleItem dataField="calculatedInstallYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Calculated Install Year")} />
                </SimpleItem> */}
                <SimpleItem dataField="strategy" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Strategy Id")} />
                </SimpleItem>
                <SimpleItem dataField="cost" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Cost Id")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="potentialUsefulLife" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Potential Useful Life")} />
                </SimpleItem>
                <SimpleItem dataField="effectiveUsefulLife" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Effective Useful Life")} />
                </SimpleItem>
                <SimpleItem dataField="cof" colSpan={1} editorOptions={editorOptions}>
                  <Label text="CoF" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("First Install Year")} />
                </SimpleItem>
                <SimpleItem dataField="lastReplacementYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Last Replacement Year")} />
                </SimpleItem>
                <SimpleItem dataField="remainingUsefulLife" colSpan={1} editorOptions={editorOptions}>
                  <Label text="Remaining Useful Life" />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="conditionRating" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Condition")} />
                </SimpleItem>
                <SimpleItem dataField="riskRating" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Risk")} />
                </SimpleItem>
                <SimpleItem
                  dataField="activityCost"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{ ...editorOptions, format: `${currency()}#,###` }}
                >
                  <Label text={props.nodeTypeId === 3 ? dict("Program Cost") : dict("Replacement Cost")} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </div>
    );
  }

  return state ? formRender() : indicatorRender();
}

export default React.memo(Component, (prev, next) => {
  return prev.nodeId === next.nodeId;
});
