import React, { useRef, useState, useContext, useMemo } from "react";
import styles from "./activity-grid.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { currency, dict } from "helper/global";
import Grid from "components/data-grid/data-grid.js";
import { Column } from "devextreme-react/data-grid";
import Summary from "../../project-activity/summary/summary";
import { TabPanel, Item } from "devextreme-react/tab-panel";

function Component(props) {
  //
  // stores
  //
  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.projectId) return Promise.resolve([]);
        return request({
          url: `${url}/projectactivitysnapshot`,
          params: {
            projectSnapshotId: props.projectId,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  const gridRender = useMemo(() => {
    return (
      <div className={styles.activity}>
        <Grid
          dataSource={activityStore}
          title={`Activity`}
          showTitle={true}
          allowAdding={false}
          allowUpdating={false}
          allowDeleting={false}
          allowDownloading={true}
          showButtons={false}
        >
          <Column visible={false} dataField={"id"} />
          <Column caption={"AssetId"} dataField={"name"} width={200} />
          <Column caption={"Asset Description"} dataField={"description"} width={400} />
          <Column caption={"Location"} dataField={"location"} width={200} />
          <Column caption={"Year"} dataField={"year"} width={75} />
          <Column caption={"Activity Name"} dataField={"activityName"} width={200} />
          <Column caption={"Activity Description"} dataField={"activityDescription"} width={200} visible={false} />
          <Column caption={"Activity Cost"} dataField={"activityCost"} width={200} format={`${currency()}#,###`} />
          <Column caption={"Strategy"} dataField={"strategy"} width={150} />
          <Column caption={"Ownership"} dataField={"ownership"} width={150} />
          <Column caption={"Status"} dataField={"status"} width={150} />
          <Column caption={"Class"} dataField={"class"} width={150} />
          <Column caption={"Type"} dataField={"type"} width={150} visible={true} />
          <Column caption={"Size"} dataField={"size"} width={150} visible={true} />
          <Column caption={"Material"} dataField={"material"} width={150} visible={true} />
          <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} visible={true} />
          <Column caption={"Model"} dataField={"model"} width={150} visible={true} />
          <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
          <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
          <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
          <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"PoF"} dataField={"pof"} dataType="number" width={150} format={"0.00"} />
          <Column caption={"CoF"} dataField={"cof"} dataType="number" width={150} format={"0.00"} />
          <Column caption={"R"} dataField={"r"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Standby"} dataField={"standby"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Bre"} dataField={"bre"} dataType="number" width={150} format={"0.##"} />
          <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
          <Column caption={"Risk  Rating"} dataField={"riskRating"} width={150} />
        </Grid>
      </div>
    );
  }, [props.projectId]);

  return <div className={styles.main}>{gridRender}</div>;
}

export default Component;
