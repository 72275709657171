import React, { useState } from "react";
import styles from "./resource-group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";
import Policy from "../policy/policy";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const [, setRender] = useState(0);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function onModified() {
    setRender((render) => render + 1);
  }

  // render

  function policyRender() {
    return state.id && <Policy roleId={props.roleId} resourceGroupId={state.id} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.top}>
        <ResourceGroup roleId={props.roleId} onSelectionChanged={onSelectionChanged} onModified={onModified} />
      </div>
      <div className={styles.top}>{policyRender()}</div>
    </div>
  );
}

export default Component;

const ResourceGroup = withMemo(
  (props) => {
    const resourcegroupStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/resourcegrouprole/role/${props.roleId}`, loadOptions: loadOptions });
        },
        update: (_, data) => {
          return request({ url: `${url}/resourcegrouprole`, method: "Put", data: data });
        },
        onModified: props.onModified,
      }),
    };

    return (
      <Grid
        title={"Resource Group"}
        dataSource={resourcegroupStore}
        selection={{ mode: "single" }}
        editingMode="batch"
        allowAdding={false}
        allowDownloading={false}
        allowUpdating={true}
        allowDeleting={false}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Column caption="Name" dataField="name" sortOrder={"asc"} sortIndex={1} allowEditing={false} />
        <Column caption="Description" dataField="description" allowEditing={false} />
        <Column caption="Included" dataField="included" sortOrder={"desc"} sortIndex={0} width={200} allowFiltering={false} />
      </Grid>
    );
  },
  ["roleId"]
);
