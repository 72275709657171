import React from "react";
import "./header.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Button from "devextreme-react/button";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import UserPanel from "../user-panel/user-panel";

function Header(props) {
  //
  // hooks

  let location = useLocation();

  // const

  const { toggleMenu } = props;
  const hideTitle = location.pathname === "/klient" || location.pathname === "/klient/home";

  // render

  function renderLabel() {
    return <div className="klient-layout-header-organization">{props.organization}</div>;
  }

  return (
    <header className={"klient-layout-header"}>
      <Toolbar className={"toolbar"}>
        <Item
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu"}
          options={{
            icon: "fas fa-bars",
            stylingMode: "text",
            onClick: toggleMenu,
          }}
        />
        {!hideTitle && (
          <Item location={"center"} locateInMenu="never">
            <div className="klient-layout-header-logo">
              <img className="klient-layout-header-logo" alt="/" src={props.logoUrl} height={props.logoHeight} />
            </div>
          </Item>
        )}
        {!hideTitle && <Item location="center" locateInMenu="never" render={renderLabel} />}
        <Item location={"after"} cssClass={"user"}>
          <Button className={"user-button"} height={"100%"} stylingMode={"text"}>
            <UserPanel />
          </Button>
        </Item>
      </Toolbar>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    organization: state.org.displayName || "",
    logoUrl: state.org.headerLogoUrl || `https://stirisprod001.blob.core.windows.net/logos/Logo_WebEx.png`,
    logoHeight: state.org.headerLogoHeight,
  };
}

export default connect(mapStateToProps, null)(Header);
