import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./audit.module.scss";
import UserLog from "./userlog/userlog";
import MasterLog from "./masterlog/masterlog";
import { selectedTabItem } from "helper/global";
import { dict } from "helper/global";

function Component(props) {
  // tabitems
  const tabItems = [
    {
      text: dict("User Log"),
      icon: "fad fa-users",
      path: "/user",
    },
    {
      text: dict("Master Log"),
      icon: "fad fa-users",
      path: "/master",
    },
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/admin/audit${e.path}`);
  }

  // render
  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/admin/audit", "/klient/admin/audit/user"]} component={UserLog} />
          <Route exact path="/klient/admin/audit/master" component={MasterLog} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
