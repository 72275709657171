import React, { useRef } from "react";
import styles from "./gis-field.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { RequiredRule } from "devextreme-react/validator";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // store

  const fieldStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/gisfield/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedRowsData.map((i) => i.id) },
          });
        } else {
          if (!props.layerId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/gisfield/layer/${props.layerId}`, loadOptions: loadOptions });
        }
      },
      insert: (data) => {
        data.layerId = props.layerId;
        return request({ url: `${url}/gisField`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/gisField`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/gisField/${id}`, method: "Delete" });
      },
    }),
  };

  var typeStore = [
    {
      id: 0,
      name: "String",
    },
    {
      id: 1,
      name: "Integer",
    },
    {
      id: 2,
      name: "Double",
    },
    {
      id: 3,
      name: "Date",
    },
  ];

  // event handlers

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("url", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("url", "visible", true);
    exporting.current = false;
  }

  // render

  function costComponentRender() {
    return (
      <div className={styles.main}>
        <Grid
          title={"Fields"}
          dataSource={fieldStore}
          onExported={onExported}
          onExporting={onExporting}
          allowDownloading={false}
          allowExpanding={true}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column caption={"Operation"} dataField={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
          <Column caption={"Name"} dataField={"name"} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"Type"} dataField={"typeId"} width={200}>
            <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column caption={"AssetId"} dataField={"node"} visible={false} formItem={{ visible: false }} />
          <Column caption={"Url"} dataField={"url"} visible={false} formItem={{ visible: false }} />
        </Grid>
      </div>
    );
  }

  return <ProgressPanel name={"UPLOAD_GISFIELD"}>{costComponentRender()}</ProgressPanel>;
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["layerId", "selectedRowsData"]));
