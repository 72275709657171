import React, { useState, useRef } from "react";
import styles from "./editor.module.scss";
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { dict, logError } from "helper/global";
import { Button } from "devextreme-react/button";
import FileUploader from "./file-uploader/file-uploader";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Popup } from "devextreme-react/popup";

function Component(props) {
  //
  // consts

  const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
  const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida Console", "Tahoma", "Times New Roman", "Verdana"];

  // hooks

  const [fileUpLoader, setFileUploader] = useState(false);
  const htmlEditor = useRef(null);
  const index = useRef(0);

  // event handlers

  function onUploaded(e) {
    var files = JSON.parse(e.request.responseText);

    files.forEach((i) => {
      // insert += `<p style="text-align: center;"><img src="${i.url}" alt="Photo" width="80%">`;
      // insert += `<p style="text-align: center;"> <a href="${i.url}" target="_blank">${i.name}</a></p>`;
      htmlEditor.current.insertEmbed(index.current, "extendedImage", {
        src: i.url,
        alt: "",
        width: "80%",
      });
      // htmlEditor.current.insertText(index + 1, i.name, {
      //   bold: true,
      //   color: "green",
      // });
      // htmlEditor.current.format("link", {
      //   href: i.url,
      //   text: i.name,
      //   title: "Go to Image",
      // });

      // htmlEditor.current.format("link", {
      //   href: i.url,
      //   text: i.name,
      //   title: "Go to Image",
      // });
    });

    hideFileUploader();
  }

  async function saveEditor() {
    try {
      props.dataSource.text = htmlEditor.current.option("value");
      if (props.type === "ticket") await request({ url: `${url}/ticket`, method: "Post", data: props.dataSource });
      else {
        if (props.operation === "create") await request({ url: `${url}/message`, method: "Post", data: props.dataSource });
        else await request({ url: `${url}/message`, method: "Put", data: props.dataSource });
      }
    } catch (error) {
      logError(error);
    } finally {
      hideEditor(true);
    }
  }

  function showEditor() {
    var o = htmlEditor.current.getSelection() || { index: 0 };
    index.current = o.index;
    setFileUploader(true);
  }

  function hideFileUploader() {
    setFileUploader(false);
  }

  function onInitialized(e) {
    htmlEditor.current = e.component;
  }

  function hideEditor(e) {
    htmlEditor.current.option("value", "");
    props.hideEditor(e);
  }

  function onValueChanged() {}

  // render

  return (
    <Popup
      visible={props.showEditor}
      onHiding={() => {
        hideEditor(false);
      }}
      showCloseButton={true}
      dragEnabled={true}
      hideOnOutsideClick={false}
      showTitle={true}
      title={"New Ticket"}
      width={900}
      height={800}
    >
      <div className={styles.main}>
        <FileUploader visible={fileUpLoader} onHiding={hideFileUploader} onUploaded={onUploaded} />
        <div className={styles.form}>
          <Form
            id={"form"}
            formData={props.dataSource}
            scrollingEnabled={true}
            showColonAfterLabel={false}
            showRequiredMark={false}
            labelLocation={"top"}
          >
            <GroupItem colCount={1}>
              <SimpleItem dataField="subject" editorOptions={{ placeholder: "Enter subject..." }}>
                <Label text={dict("Subject")} />
              </SimpleItem>
              {/* <SimpleItem
              dataField="typeId"
              editorType="dxSelectBox"
              editorOptions={{ dataSource: types, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
            >
              <Label text={dict("Material")} />
            </SimpleItem> */}
            </GroupItem>
          </Form>
        </div>
        <div className={styles.html_editor}>
          <HtmlEditor
            placeholder={"Enter text..."}
            height={"100%"}
            scrollByContent={true}
            scrollByThumb={true}
            // onContentReady={onContentReady}
            onInitialized={onInitialized}
            defaultValue={props.dataSource.text}
            onValueChanged={onValueChanged}
          >
            <Toolbar>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item name="size" acceptedValues={sizeValues} />
              <Item name="font" acceptedValues={fontValues} />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item
                widget="dxButton"
                options={{
                  icon: "image",
                  onClick: showEditor,
                }}
              />
              <Item name="link" />
            </Toolbar>
          </HtmlEditor>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttons_right}>
            <Button text={dict("Save")} type={"success"} icon={"save"} stylingMode="text" onClick={saveEditor} />
            {/* <Button text={dict("Index")} type={"success"} icon={"save"} stylingMode="text" /> */}
            <Button
              className={styles.cancelbutton}
              icon={"clear"}
              text={dict("Cancel")}
              type={"success"}
              stylingMode="text"
              onClick={() => {
                hideEditor(false);
              }}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default Component;
