import React from "react";
import styles from "./jsonviewer.module.scss";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { Popup } from "devextreme-react/popup";

function Component(props) {
  console.log("%cLog", "color: red", props.newValue);
  return (
    <div>
      <Popup
        visible={props.visible}
        onHiding={props.onHiding}
        showCloseButton={true}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={"Audit Log"}
        width={700}
        height={700}
        deferRendering={true}
      >
        <div className={styles.main}>
          <div className={styles.container1}>
            <div className={styles.caption}>New Value</div>
            <JSONPretty id="json-pretty" data={props.newValue}></JSONPretty>
          </div>
          <div className={styles.container2}>
            <div className={styles.caption}>Old Value</div>
            <JSONPretty id="json-pretty" data={props.oldValue}></JSONPretty>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default Component;
