import * as actionTypes from "../actions/actionTypes";

const initialState = {
  name: null,
  id: null,
  process: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.JOB_START:
      return {
        name: action.name,
        id: action.id,
        process: null,
      };
    case actionTypes.JOB_END:
      return {
        name: null,
        id: null,
        progress: null,
      };
    case actionTypes.JOB_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };

    default:
      return state;
  }
};

export default reducer;
