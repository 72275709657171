import React, { useEffect } from "react";
import styles from "./home.module.scss";
import Card from "./cards/card2/card";
import Condition from "./charts/condition/condition";
import Consumption from "./charts/consumption/consumption";
import Risk from "./charts/risk/risk";
import LifeCylce from "./charts/lifecylce-cost/lifecylce";
import Project from "./project/project";
import { currencyFormat, commaFormat, dict } from "helper/global";
import { connect } from "react-redux";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
  faSirenOn,
  faTruckPickup,
  faCalendar,
  faMoneyCheckEdit,
  faSack,
} from "@fortawesome/pro-duotone-svg-icons";
import * as actions from "redux/actions/index";

function Home(props) {
  // varilbes

  let banner_background_image =
    props.bannerUrl ||
    "https://stirisprod001.blob.core.windows.net/banners/Demo2.png";

  // useEffect

  useEffect(() => {
    if (!props.data) props.loadHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  function infoGraphicsRender() {
    return (
      <React.Fragment>
        <div className={styles.cards}>
          <div className={styles.cards_content}>
            <div className={styles.cards_content_item}>
              <Card
                title={dict("Total Number of Assets")}
                value={`${
                  (props.data.header && commaFormat(props.data.count)) || 0
                }`}
                cursor={true}
                icon={faTruckPickup}
                color={"#5f8b95"}
              />
            </div>
            <div className={styles.cards_content_item}>
              <Card
                title={dict("Range of Installation Years")}
                value={`${
                  props.data.installation.header.minInstallYear || 0
                } - ${props.data.installation.header.maxInstallYear || 0}`}
                cursor={true}
                icon={faCalendar}
                color={"#BA4D51"}
              />
            </div>
            <div className={styles.cards_content_item}>
              <Card
                title={dict("Total Replacement Cost")}
                value={`${
                  (props.data.header &&
                    currencyFormat(props.data.cost.header.replacementCost)) ||
                  0
                }`}
                cursor={true}
                icon={faSack}
                color={"#859666"}
              />
            </div>
            <div className={styles.cards_content_item}>
              <Card
                title={dict("Average Annual Cost")}
                value={`${
                  (props.data.header &&
                    currencyFormat(props.data.cost.header.annualCost)) ||
                  0
                }`}
                cursor={true}
                icon={faMoneyCheckEdit}
                color={"#955F71"}
              />
            </div>
            <div className={styles.cards_content_item}>
              <Card
                title={dict("High Risk Cost")}
                value={`${
                  (props.data.header &&
                    currencyFormat(props.data.header.highRiskCost)) ||
                  0
                }`}
                cursor={true}
                icon={faSirenOn}
                color={"#AF8A53"}
              />
            </div>
          </div>
        </div>
        <div className={styles.line_charts}>
          <LifeCylce data={props.data.cost} />
        </div>
        <div className={styles.condition}>
          <div className={styles.condition_item}>
            <Condition data={props.data.condition} />
          </div>
          <div className={styles.condition_item}>
            <Consumption data={props.data.consumption} />
          </div>
        </div>
        <div className={styles.risk}>
          <Risk data={props.data.risk} />
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.grid}>
        <div className={styles.banner_background}>
          <img src={banner_background_image} alt="" width="100%" />
        </div>
        {/* <div className={styles.banner} /> */}
        <div className={styles.banner_content}>
          <div className={styles.banner_content_logo}>
            <img
              src={props.bannerLogoUrl || props.logoUrl}
              alt=""
              height={props.bannerLogoHeight}
              width={"auto"}
            />
          </div>
          <div className={styles.banner_content_title}>{props.bannerName}</div>
        </div>
        {props.data === null ? (
          <div className={styles.loading}>
            <LoadIndicator />
          </div>
        ) : (
          infoGraphicsRender()
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    organization: state.org.name || "",
    logoUrl: state.org.logoUrl,
    bannerUrl: state.org.bannerUrl,
    bannerLogoUrl: state.org.bannerLogoUrl,
    bannerLogoHeight: state.org.bannerLogoHeight,
    bannerName: state.org.bannerName,
    data: state.home,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadHome: () => dispatch(actions.loadHome()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
