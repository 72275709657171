import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Logout from "../../logout/logout";

function Component(props) {
  //
  // hooks
  const [state, setState] = useState(false);
  const { instance } = useMsal();
  useEffect(() => {
    (async () => {
      await instance.logoutPopup({ scopes: ["User.Read"] });
      setState(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  return state && <Logout />;
}

export default Component;
