import React, { useState } from "react";
import styles from "./cost-group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import Cost from "./cost/cost";

function Component() {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.group}>
        <CostGroup onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.cost}>{<Cost costGroupId={state.id} />}</div>
    </div>
  );
}

export default Component;

const CostGroup = React.memo(
  (props) => {
    //
    // stores

    const costGroupStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/costgroup`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/costgroup`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/costgroup`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/costgroup/${id}`, method: "Delete" });
        },
      }),
    };

    // event handlers

    function onSelectionChanged(e) {
      props.onSelectionChanged && props.onSelectionChanged(e);
    }

    // render

    return (
      <List
        title={"Category"}
        dataSource={costGroupStore}
        selection={{ mode: "single" }}
        allowDownloading={false}
        onSelectionChanged={onSelectionChanged}
      >
        <Column visible={false} dataField={"id"} sortOrder={"asc"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} sortOrder={"asc"} sortIndex={1} />
        <Column caption={"Description"} dataField={"description"} visible={false} />
        <Column caption={"Internal"} dataField={"internal"} sortOrder={"desc"} sortIndex={0} visible={false} formItem={{ visible: false }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          allowFiltering={true}
          width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </List>
    );
  },
  () => {
    return true;
  }
);
