import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./access.module.scss";
import Organization from "./organization/organization";
import Department from "./department/department";
import User from "./user/user";
import Role from "./role/role";
import ResourceGroup from "./resource-group/resource-group";
import Resource from "./resource/resource";
import { connect } from "react-redux";
import { selectedTabItem } from "helper/global";
import { dict } from "helper/global";

function Access(props) {
  // tabitems
  const tabItems = [
    ...(props.accessAccess
      ? [
          {
            text: dict("User"),
            icon: "fad fa-users",
            path: "/user",
          },
        ]
      : []),
    ...(props.accessAccess
      ? [
          {
            text: dict("Role"),
            icon: "fad fa-theater-masks",
            path: "/role",
          },
        ]
      : []),

    ...(props.accessAdmin
      ? [
          {
            text: "Resource Groups",
            icon: "fad fa-object-group",
            path: "/resourcegroup",
          },
        ]
      : []),
    ...(props.accessAdmin
      ? [
          {
            text: dict("Organization"),
            icon: "fad fa-city",
            path: "/organization",
          },
        ]
      : []),
    ...(props.accessAdmin
      ? [
          {
            text: dict("Department"),
            icon: "fad fa-building",
            path: "/department",
          },
        ]
      : []),
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/admin/access${e.path}`);
  }

  // render
  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/admin", "/klient/admin/access"]} component={User} />
          <Route exact path="/klient/admin/access/user" component={User} />
          <Route exact path="/klient/admin/access/role" component={Role} />
          <Route exact path="/klient/admin/access/resourcegroup" component={ResourceGroup} />
          <Route exact path="/klient/admin/access/resource" component={Resource} />
          <Route exact path="/klient/admin/access/organization" component={Organization} />
          <Route exact path="/klient/admin/access/department" component={Department} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessAccess: state.user.accessAccess,
    accessAdmin: state.user.accessAdmin,
  };
};

export default connect(mapStateToProps, null)(Access);
