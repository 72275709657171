import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import Salary from "./salary/salary";
import ActivityType from "./activity-type/activity-type";
import CostType from "./cost-type/cost-type";
import CostCenter from "./cost-center/cost-center";
import ConditionRating from "./condition-rating/condition-rating";
import ConsumptionRating from "./consumption-rating/consumption-rating";
import RiskRating from "./risk-rating/risk-rating";
import ProjectStatus from "./project-status/project-status";
import Fund from "./fund/fund";
import { dict } from "helper/global";

function Lookup(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Activity"),
      icon: "fad fa-hiking",
      path: "/activityType",
    },
    {
      text: dict("Fund"),
      icon: "fad fa-piggy-bank",
      path: "/fund",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-search-dollar",
      path: "/costtype",
    },
    {
      text: dict("Cost Center"),
      icon: "fad fa-money-check-edit",
      path: "/costcenter",
    },
    {
      text: dict("Salary"),
      icon: "fad fa-hand-holding-usd",
      path: "/salary",
    },
    {
      text: dict("Condition"),
      icon: "fad fa-smile",
      path: "/conditionrating",
    },
    {
      text: dict("Consumption"),
      icon: "fad fa-meh",
      path: "/consumptionrating",
    },
    {
      text: dict("Risk"),
      icon: "fad fa-frown",
      path: "/riskrating",
    },
  ];

  // functions

  function changeView(e) {
    props.history.push(`/klient/manage/lookup${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs
          tabItems={tabItems}
          showNavButtons={true}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route
            exact
            path={[
              "/klient/manage",
              "/klient/manage/lookup",
              "/klient/manage/lookup/activitytype",
            ]}
            component={ActivityType}
          />
          <Route exact path="/klient/manage/lookup/fund" component={Fund} />
          <Route
            exact
            path="/klient/manage/lookup/costtype"
            component={CostType}
          />
          <Route
            exact
            path="/klient/manage/lookup/costcenter"
            component={CostCenter}
          />
          <Route exact path="/klient/manage/lookup/salary" component={Salary} />
          <Route
            exact
            path="/klient/manage/lookup/conditionrating"
            component={ConditionRating}
          />
          <Route
            exact
            path="/klient/manage/lookup/consumptionrating"
            component={ConsumptionRating}
          />
          <Route
            exact
            path="/klient/manage/lookup/riskrating"
            component={RiskRating}
          />
          <Route
            exact
            path="/klient/manage/lookup/projectstatus"
            component={ProjectStatus}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Lookup;
