import React, { useRef } from "react";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import Profile from "./cost/profile";
import { confirm } from "devextreme/ui/dialog";
import Jobpanel from "components/jobpanel/jobpanel";

function Component(props) {
  // useRef

  const selected = useRef(null);
  const toolbar = useRef({
    smoothing: 0,
    escalate: false,
  });

  // event handlers

  function onSelectionChanged(e) {
    selected.current = e;
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Run All",
        icon: "fas fa-play",
        onClick: onRun,
      },
    });
  }

  async function onRun() {
    if (!(await confirm("<i>Do you really want to run the smoothing tool?", "Confirm"))) return;
    props.run({ name: "RUN", url: `${url}/smoothing` });
  }

  function onToolbarChanged(e) {
    toolbar.current = e;
  }

  // render

  return (
    <Hierarchy
      title={"Hierarchy"}
      allowEditing={false}
      allowDragging={false}
      multiSelect={true}
      showFilter={false}
      showCount={true}
      countUrl={`${url}/engine/output/node-count`}
      onSelectionChanged={onSelectionChanged}
      onToolbarPreparing={onToolbarPreparing}
    >
      <Jobpanel name={"RUN"}>
        <Profile toolbar={toolbar.current} onToolbarChanged={onToolbarChanged} />
      </Jobpanel>
    </Hierarchy>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
