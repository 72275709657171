import React, { useState, useRef } from "react";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import styles from "./file.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import TileView from "components/doc-tileview/doc-tileview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon } from "helper/global";
import { dict } from "helper/global";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // useRef
  const exporting = useRef(false);

  // store

  const fileAssetStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/assetfile/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedHierarchyNodes.map((i) => i.id) },
          });
        } else {
          if (!props.assetId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/assetfile/asset/${props.assetId}`, loadOptions: loadOptions });
        }
      },
      insert: (data) => {
        return request({ url: `${url}/assetfile`, method: "Post", data: { ...data, AssetId: props.assetId } });
      },
      remove: (id) => {
        return request({ url: `${url}/assetfile/asset/${props.assetId}/file/${id}`, method: "Delete" });
      },
      onModified: () => {
        onRefresh();
      },
    }),
  };

  //

  const fileStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/file`, loadOptions: loadOptions, params: { type: "document" } });
      },
      byKey: function (id) {
        return request({ url: `${url}/file/${id}` });
      },
    }),
  };

  // const

  // useState
  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ASSET_FILE", url: `${url}/assetfile/upload/file/${fileId}` });
  }

  function onExporting(e) {
    // e.component.columnOption("id", "visible", true);
    // e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("url", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    // e.component.columnOption("id", "visible", false);
    // e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("url", "visible", true);
    exporting.current = false;
  }

  // render

  // function documentDropDownRender(data) {
  //   return (
  //     <DropDown keyExpr={"ImageDropDown"} data={data} showColumnHeaders={true} height={250} width={500}>
  //       <Column dataField="name"> </Column>
  //     </DropDown>
  //   );
  // }

  const gridRender = () => {
    return (
      <ProgressPanel name={"UPLOAD_ASSET_FILE"} onComplete={onRefresh}>
        <Grid
          title={"Files"}
          showTitle={false}
          dataSource={fileAssetStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowUpdating={false}
          allowExpanding={true}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column dataField="url" caption={""} width={100} allowSorting={false} cellRender={imageRender} formItem={{ visible: false }} />
          <Column visible={false} dataField={"node"} caption={"AssetId"} formItem={{ visible: false }} />
          <Column
            caption={"Name"}
            dataField={"fileId"}
            calculateDisplayValue="name"
            // editCellRender={documentDropDownRender}
            width={400}
            formItem={{ colSpan: 2 }}
          >
            <Lookup dataSource={fileStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column visible={false} formItem={{ itemType: "empty" }} />
          {/* <Column caption={"Url"} dataField={"url"} allowFiltering={true} width={600} allowEditing={false} formItem={{ visible: false }} /> */}
        </Grid>
      </ProgressPanel>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function imageRender(data) {
    return (
      <div className={styles.icon}>
        <FontAwesomeIcon icon={getIcon(data.value)} />
      </div>
    );
  }

  function tileRender() {
    return (
      <div>
        <TileView
          id={props.assetId}
          urls={{ read: `${url}/assetfile/asset/${props.assetId}` }}
          title={props.assetDescription}
          render={render}
          refresh={onRefresh}
        ></TileView>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Tiles")} render={tileRender} />
        <Item title={dict("Grid")} render={() => gridRender()} />
      </TabPanel>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["assetId", "selectedHierarchyNodes"]));
