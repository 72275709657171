import React, { useState } from "react";
import styles from "./document.module.scss";
import TileView from "components/doc-tileview/doc-tileview";
import { url } from "config.js";

function Component(props) {
  // useState
  const [render, setRender] = useState(0);

  function onRefresh() {
    setRender((render) => render + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <TileView
        urls={{
          read: `${url}/assetdocument/asset/${props.data.assetId}`,
          delete: `${url}/assetdocument`,
          upload: `${url}/assetdocument/blob/asset/${props.data.assetId}`,
        }}
        title={props.data.description}
        render={render}
        refresh={onRefresh}
        allowEditing={true}
      ></TileView>
    </div>
  );
}

export default Component;
