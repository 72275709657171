import React, { useState, useRef, useMemo } from "react";
import styles from "./project-activity.module.scss";
import ActivityGrid from "../activity-grid/activity-grid";
import ActivityMap from "../activity-map/activity-map";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
import { dict, withMemo } from "helper/global";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, FilterRow } from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import { LoadIndicator } from "devextreme-react/load-indicator";

function Component(props) {
  // hooks
  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const [gridRender, setGridRender] = useState(false);
  const [mapRender, setMapRender] = useState(false);
  const [loading, showLoading] = useState(false);
  const project = useRef(null);
  // event handlers

  function onSelectionChanged(e) {
    if (e.selectedRowsData[0]) {
      var p = e.selectedRowsData[0];
      setState(p);
      project.current = p;
    } else setState(initialState);
  }

  async function onProjectSaved() {
    var result = await confirm("<i>Do you really want to save the project?</i>", "Confirm");

    if (result) {
      showLoading(true);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      today = mm + "/" + dd + "/" + yyyy;
      await request({
        url: `${url}/projectsnapshot`,
        params: { projectId: project.current.id, name: `${project.current.name} - ${today}` },
        method: "Post",
      });
      showLoading(false);
    }
  }

  // render

  function activityGridRender() {
    return (
      state.id && (
        <ActivityGrid
          year={props.year}
          projectId={state.id}
          onBackButtonClick={props.onBackButtonClick}
          render={gridRender}
          onUpdated={() => setMapRender((state) => !state)}
        ></ActivityGrid>
      )
    );
  }

  function activityMapRender() {
    return (
      state.id && (
        <ActivityMap
          year={props.year}
          projectId={state.id}
          render={mapRender}
          onBackButtonClick={props.onBackButtonClick}
          onUpdated={() => setGridRender((state) => !state)}
        />
      )
    );
  }

  return (
    <div className={styles.main}>
      {loading && (
        <div className={styles.loading}>
          <LoadIndicator />
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.project}>
          <Project onSelectionChanged={onSelectionChanged} onProjectSaved={onProjectSaved} />
        </div>
        <div className={styles.activity}>
          <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
            <TabItem title={dict("Table")} render={activityGridRender} />
            <TabItem title={dict("Map")} render={activityMapRender} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default Component;

const Project = withMemo((props) => {
  //
  // stores

  const projectStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/project`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/project/${id}` });
      },
    }),
  };

  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        hint: "Save",
        icon: "save",
        onClick: props.onProjectSaved,
      },
    });
  }

  return (
    <Grid
      title={"Project"}
      dataSource={projectStore}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onToolbarPreparing={onToolbarPreparing}
      onSelectionChanged={onSelectionChanged}
    >
      <FilterRow visible={true} />
      <Column visible={false} dataField={"id"} sortOrder={"desc"} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true}></Column>
    </Grid>
  );
}, []);
