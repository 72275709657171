import React, { useEffect, useRef, useContext, useMemo } from "react";
import styles from "./activity-map.module.scss";
import "./activity-map.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import * as gis from "helper/gis";
import HierarchyContext from "components/hierarchy/context";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useImmerReducer } from "use-immer";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
import Summary from "../summary/summary";

function reducer(draft, action) {
  switch (action.type) {
    case "loaded":
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      return;
    case "showSummary":
      draft.activityId = action.payload.activityCostProfileId;
      draft.assetId = action.payload.assetId;
      return;
    case "hideSummary":
      draft.activityId = null;
      draft.assetId = null;
      return;
    case "render":
      draft.render = !draft.render;
      break;
    case "clear":
      draft.activityId = null;
      draft.assetId = null;
      draft.highlighted = [];
      draft.render = !draft.render;
      break;
    default:
      return;
  }
}

function Component(props) {
  //
  // constants
  const fields = [
    {
      name: "id",
      alias: "Id",
      type: "integer",
    },
    {
      name: "name",
      alias: "Name",
      type: "string",
    },
    {
      name: "riskRating",
      alias: "Risk Rating",
      type: "string",
    },
    {
      name: "conditionRating",
      alias: "Condition Rating",
      type: "string",
    },
    {
      name: "projectId",
      alias: "ProjectId",
      type: "integer",
    },
    {
      name: "activityCostProfileId",
      alias: "ActivityCostProfileId",
      type: "integer",
    },
    {
      name: "assetId",
      alias: "AssetId",
      type: "integer",
    },
    {
      name: "activity",
      alias: "Activity",
      type: "string",
    },
    {
      name: "selected",
      alias: "Selected",
      type: "integer",
    },
    {
      name: "visible",
      alias: "Visible",
      type: "integer",
    },
  ];

  // hooks

  const initialState = {
    highlighted: [],
    loading: false,
    activityCostProfileId: null,
    assetId: null,
    render: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  const mapRef = useRef();
  const firstRender = useRef(true);
  const highlighted = useRef([]);

  // useEffect

  useEffect(
    function () {
      if (firstRender.current) return;
      (async () => {
        highlighted.current = [];
        gis.clear();
        await getData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowsData, filter, props.render]
  );

  useEffect(
    function () {
      (async () => {
        let p1 = request({ url: `${url}/conditionrating` });
        let p2 = request({ url: `${url}/riskrating` });
        let p3 = request({ url: `${url}/activity` });
        let [conditionRatings, breRatings, activities] = await Promise.all([p1, p2, p3]);

        let ratings = [
          {
            field: "conditionRating",
            ratings: conditionRatings.data,
            selected: false,
          },
          {
            field: "riskRating",
            ratings: breRatings.data,
            selected: true,
          },
          {
            field: "activity",
            ratings: activities.data,
            selected: true,
          },
        ];
        await gis.initialize(mapRef, fields, ratings, "riskRating", onHighlight, onClearHighlight, onClick, true, false);
        await getData();
        firstRender.current = false;
      })();

      return function () {
        gis.dispose();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // functions

  async function getData() {
    try {
      dispatch({ type: "loading" });
      gis.indicator(true);
      let layers = await request({
        url: `${url}/gis/layers`,
        params: { ids: selectedRowsData.map((i) => i.id) },
      });
      var assets = null;
      if (!layers.every((i) => i.isRef)) {
        assets = await request({
          url: `${url}/projectactivity/gis`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            year: props.year,
            projectId: props.projectId,
            filterExpression: filter,
          },
        });
      }
      await gis.setLayers(layers, assets);
    } catch (error) {
      console.log("getData: ", error);
    } finally {
      dispatch({ type: "loaded" });
    }
  }

  function clear() {
    highlighted.current = [];
    gis.clearHighlight();
    dispatch({ type: "hideSummary" });
  }

  // event handler

  async function onHighlight(e) {
    highlighted.current.push(...e);
    dispatch({ type: "render" });
  }

  function onClearHighlight() {
    highlighted.current = [];
    dispatch({ type: "clear" });
  }

  function onClick(e, attr) {
    if (attr) {
      if (e === "hold") {
        // highlighted.current = [];
        // gis.clearHighlight();
        if (!highlighted.current.includes((i) => i.id === attr.id)) {
          highlighted.current.push(attr);
          gis.highlight(attr.id, true);
        }
        dispatch({ type: "showSummary", payload: attr });
      } else if (highlighted.current.find((i) => i.id === attr.id)) {
        highlighted.current = highlighted.current.filter((i) => i.id !== attr.id);
        gis.highlight(attr.id, false);
        dispatch({ type: "render" });
      } else {
        highlighted.current.push(attr);
        gis.highlight(attr.id, true);
        dispatch({ type: "render" });
      }
    }
  }

  async function onSave() {
    gis.indicator(true);
    try {
      await request({
        url: `${url}/projectactivity`,
        data: {
          models: highlighted.current.map((i) => {
            return { projectId: i.projectId, activityCostProfileId: i.activityCostProfileId };
          }),
        },
        method: "Post",
      });
      gis.select(
        highlighted.current.map((i) => i.id),
        true
      );
      clear();
      props.onUpdated();
    } catch (error) {
      console.log(error);
    } finally {
      gis.indicator(false);
    }
  }

  async function onDelete() {
    gis.indicator(true);
    try {
      await request({
        url: `${url}/projectactivity`,
        data: {
          models: highlighted.current.map((i) => {
            return { projectId: i.projectId, activityCostProfileId: i.activityCostProfileId };
          }),
        },
        method: "Delete",
      });
      gis.select(
        highlighted.current.map((i) => i.id),
        false
      );
      clear();
      props.onUpdated();
    } catch (error) {
      console.log(error);
    } finally {
      gis.indicator(false);
    }
  }

  // render

  function toolbarRender() {
    return (
      <div className={`${styles.toolbar} theme-toolbar project-activity-map`}>
        <Toolbar>
          <Item
            location="before"
            widget="dxButton"
            options={{
              icon: "back",
              onClick: props.onBackButtonClick,
            }}
          />
          <Item
            location="before"
            render={() => {
              return <div className={styles.text}>{`Activity Year: ${props.year}`}</div>;
            }}
          />
          <Item
            location="after"
            widget="dxButton"
            disabled={highlighted.current.length === 0}
            options={{
              icon: "save",
              onClick: onSave,
            }}
          />
          <Item
            location="after"
            widget="dxButton"
            disabled={highlighted.current.length === 0}
            options={{
              icon: "close",
              onClick: onDelete,
            }}
          />
        </Toolbar>
      </div>
    );
  }

  const mapRender = useMemo(() => {
    return (
      <div className={styles.map} ref={mapRef}>
        <div id={"gis_indicator"} className={styles.loading}>
          <LoadIndicator />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, state.render, filter]);

  function summaryRender() {
    return <Summary activityId={state.activityId} assetId={state.assetId} />;
  }

  return (
    selectedRowsData.length > 0 &&
    props.projectId && (
      <div className={styles.main}>
        {state.loading && <div className={styles.overlay} />}
        <div className={styles.header}>{toolbarRender()}</div>
        <div className={styles.container}>
          {mapRender}
          {state.activityId && (
            <div className={styles.summary}>
              <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
                <TabItem title={"Summary"} render={summaryRender} />
              </TabPanel>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default Component;
