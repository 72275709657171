import React, { useRef } from "react";
import styles from "./alias.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { withMemo } from "helper/global";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // stores

  const aliasStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          return request({
            url: `${url}/alias/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedHierarchyNodes.map((i) => i.id) },
          });
        } else {
          if (!props.nodeId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/alias/node/${props.nodeId}`, loadOptions: loadOptions });
        }
      },
      insert: (data) => {
        data.nodeId = props.nodeId;
        return request({ url: `${url}/alias`, method: "Post", data: data });
      },
      update: (key, data) => {
        return request({ url: `${url}/alias`, method: "Put", data: data });
      },
      remove: (key) => {
        return request({ url: `${url}/alias/${key}`, method: "Delete" });
      },
    }),
  };

  const aliasTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/aliasType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/aliasType/${id}` });
      },
    }),
  };

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ALIAS", url: `${url}/alias/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("unit", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("unit", "visible", true);
    exporting.current = false;
  }

  // render

  const aliasRender = () => {
    return (
      <Grid
        title={"Aliases"}
        showTitle={false}
        dataSource={aliasStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={true}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Operation"} dataField={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
        <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
          <Lookup dataSource={aliasTypeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Value"} dataField={"value"} allowFiltering={true} width={200}></Column>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div className={styles.main}>
      <ProgressPanel name={"UPLOAD_ALIAS"}>{aliasRender()}</ProgressPanel>
    </div>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["nodeId", "selectedHierarchyNodes"]));
