import React, { useState, useEffect } from "react";
import styles from "./organization.module.scss";
import Form, { GroupItem, SimpleItem, EmptyItem, Label } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { dict, notify } from "helper/global";
import { url } from "config.js";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";

function Component(props) {
  //
  // useState

  const [state, setState] = useState();

  async function onSave() {
    try {
      await request({ url: `${url}/organizationsetting`, method: "Put", data: state });
      window.location.reload(false);
      notify(dict("Organization updated successfully"), "success");
    } catch (error) {
      console.log("error");
    }
  }

  // hooks

  useEffect(() => {
    (async () => {
      let result = await request({ url: `${url}/organizationsetting` });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={"Save"} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
      </div>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function formRender() {
    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <Form id={"form"} formData={state} width={1200} showColonAfterLabel={true} showRequiredMark={false} labelLocation={"top"}>
            <GroupItem colCount={4}>
              <GroupItem te colSpan={2}>
                <SimpleItem dataField="displayName">
                  <Label text={dict("Header Title")} />
                </SimpleItem>
                <SimpleItem dataField="headerLogoUrl">
                  <Label text={`${dict("Header Logo")} Url`} />
                </SimpleItem>
                <SimpleItem dataField="headerLogoHeight">
                  <Label text={dict("Header Logo Height")} />
                </SimpleItem>
                <SimpleItem dataField="bannerName">
                  <Label text={dict("Banner Title")} />
                </SimpleItem>
                <SimpleItem dataField="bannerUrl">
                  <Label text={`${dict("Banner")} Url`} />
                </SimpleItem>
                <SimpleItem dataField="bannerLogoUrl">
                  <Label text={`${dict("Banner Logo")} Url`} />
                </SimpleItem>
                <SimpleItem dataField="bannerLogoHeight">
                  <Label text={dict("Banner Logo Height")} />
                </SimpleItem>
                <EmptyItem />
                <SimpleItem render={buttonRender} />
              </GroupItem>
              <GroupItem colSpan={2}>
                <SimpleItem dataField="gisCenterLatitude" editorOptions={{ dataType: "number" }}>
                  <Label text={dict("GIS Center Latitude")} />
                </SimpleItem>
                <SimpleItem dataField="gisCenterLongitude">
                  <Label text={dict("GIS Center Longitude")} editorOptions={{ dataType: "number" }} />
                </SimpleItem>
                <SimpleItem dataField="gisZoom">
                  <Label text={dict("GIS Zoom")} />
                </SimpleItem>
                <SimpleItem dataField="gisBasemap">
                  <Label text={`${dict("GIS Basemap")}`} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
      </div>
    );
  }

  return state ? formRender() : loadingRender();
}

export default Component;
