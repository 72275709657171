import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";
import { status } from "../enums/auth";

const initialState = {
  status: status.UnAuthenticated,
  error: null,
  loading: false,
};

const session = (state, action) => {
  return updateObject(state, {
    status: action.payload.status,
  });
};

const loginStart = (state) => {
  return updateObject(state, {
    loading: true,
  });
};

const loginEnd = (state, action) => {
  return updateObject(state, {
    status: action.payload.status,
    loading: false,
  });
};

const logout = (state) => {
  return updateObject(state, {
    status: status.LoggedOut,
  });
};

const error = (state, action) => {
  return updateObject(state, {
    status: status.Error,
    error: action.payload.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION:
      return session(state, action);
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_END:
      return loginEnd(state, action);
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.ERROR:
      return error(state, action);
    default:
      return state;
  }
};

export default reducer;
