import React, { useContext, useRef, useState } from "react";
import { request } from "helper/http-client";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import styles from "./gis-layer.module.scss";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";
import HierarchyContext from "components/hierarchy/context";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { withMemo } from "helper/global";
import Field from "../gis-field/gis-field";

function Component() {
  //
  // useStore

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.layer}>
        <Layer selectedRowsData={selectedRowsData} filter={filter} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.field}>{<Field layerId={state.id} selectedRowsData={selectedRowsData} />}</div>
    </div>
  );
}

// redux

export default Component;

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Layer = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      //
      // hooks
      const gisLayerStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            if (props.selectedRowsData.length === 0) return Promise.resolve({ data: [] });
            return request({
              url: exporting.current ? `${url}/gislayer/download` : `${url}/gislayer`,
              loadOptions: loadOptions,
              params: { ids: props.selectedRowsData.map((i) => i.id) },
            });
          },
          insert: (data) => {
            return request({ url: `${url}/gislayer`, method: "Post", data: data });
          },
          update: (_, data) => {
            return request({ url: `${url}/gislayer`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/gislayer/${id}`, method: "Delete" });
          },
        }),
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("id", "sortOrder", "asc");
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("nodeId", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("id", "sortOrder", "desc");
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("nodeId", "visible", false);
        exporting.current = false;
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_GISLAYER", url: `${url}/gislayer/upload/file/${fileId}` });
      }

      // render

      return (
        props.selectedRowsData.length > 0 && (
          <ProgressPanel ProgressPanel name={"UPLOAD_GISLAYER"}>
            <div className={styles.main}>
              <Grid
                dataSource={gisLayerStore}
                title={"Layer"}
                selection={{ mode: "single" }}
                onUploaded={onUploaded}
                onExported={onExported}
                onExporting={onExporting}
                onInitNewRow={(e) => {
                  e.data.nodeId = props.selectedRowsData[0].id;
                  e.data.isRef = false;
                }}
                onSelectionChanged={props.onSelectionChanged}
              >
                <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
                <Column
                  caption={"Operation"}
                  dataField={"operation"}
                  calculateCellValue={() => "Update"}
                  width={100}
                  visible={false}
                  formItem={{ visible: false }}
                />
                <Column caption={"AssetId"} dataField={"nodeId"} visible={false} formItem={{ visible: false }} />
                <Column caption={"Url"} dataField={"url"} allowFiltering={false} formItem={{ colSpan: 2 }} />
                <Column caption={"GISId"} dataField={"gisId"} allowFiltering={false} width={150} />
                <Column caption={"IsRef"} dataField={"isRef"} allowFiltering={false} width={150} dataType={"boolean"} />
              </Grid>
            </div>
          </ProgressPanel>
        )
      );
    },
    ["selectedRowsData", "filter", "render"]
  )
);
