import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styles from "./setting.module.scss";
import Tabs from "components/tabs/tabs";
import { selectedTabItem } from "helper/global";
import GisLayer from "./gis-layers/gis-layers";
import { dict } from "helper/global";
import IgnoreStatus from "./ignore-status/ignore-status";
import Scenario from "./scenario/scenario";
import Organization from "./organization/organization";
import Department from "./department/department";
import { connect } from "react-redux";
import access from "../access/access";

function Component(props) {
  // tabItems

  const tabItems = [
    ...(props.accessScenario
      ? [
          {
            text: dict("Scenario"),
            icon: "fas fa-tools",
            path: "/scenario",
          },
        ]
      : []),
    ...(props.accessScenario
      ? [
          {
            text: dict("Ignore"),
            icon: "fad fa-bullhorn",
            path: "/ignorestatus",
          },
        ]
      : []),
    ...(props.accessGis
      ? [
          {
            text: `GIS ${dict("Layers")}`,
            icon: "fad fa-layer-group",
            path: "/gislayer",
          },
        ]
      : []),
    ...(props.accessDisplay
      ? [
          {
            text: dict("Organization"),
            icon: "fad fa-city",
            path: "/organization",
          },
        ]
      : []),
    ...(props.accessDisplay
      ? [
          {
            text: dict("Department"),
            icon: "fas fa-building",
            path: "/department",
          },
        ]
      : []),
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/admin/setting${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route
            exact
            path={"/klient/admin/setting"}
            component={props.accessScenario ? Scenario : props.accessDisplay ? Organization : GisLayer}
          />
          <Route exact path={"/klient/admin/setting/scenario"} component={Scenario} />
          <Route exact path="/klient/admin/setting/organization" component={Organization} />
          <Route exact path="/klient/admin/setting/department" component={Department} />
          <Route exact path="/klient/admin/setting/ignorestatus" component={IgnoreStatus} />
          <Route exact path="/klient/admin/setting/gislayer" component={GisLayer} />

          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    accessDisplay: state.user.accessDisplay,
    accessScenario: state.user.accessScenario,
    accessGis: state.user.accessGis,
  };
};

export default connect(mapStateToProps, null)(Component);
