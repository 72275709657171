import React, { useState, useEffect } from "react";
import styles from "./message.module.scss";
import MessageBox from "./messagebox/messagebox";
import ScrollView from "devextreme-react/scroll-view";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { request } from "helper/http-client";
import { url } from "config.js";
import { logError } from "helper/global";

function Component(props) {
  //
  // hooks

  const [data, setData] = useState(null);
  const [render, setRender] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setData(null);
        const results = await request({ url: `${url}/message/ticket/${props.ticket.id}` });
        setData(results);
      } catch (error) {
        logError(error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticket.id, props.render, render]);

  function onRefresh() {
    setRender((state) => state + 1);
  }

  // render

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function messageBoxRender() {
    return data
      .filter((i) => i.typeId === 1 || i.typeId === 2)
      .map((i) => {
        return (
          <div key={i.id} className={styles.message}>
            <MessageBox dataSource={data.filter((j) => j.id === i.id || j.parentId === i.id)} onRefresh={onRefresh} />
          </div>
        );
      });
  }

  function messageRender() {
    return (
      <div className={styles.container}>
        <ScrollView>{messageBoxRender()}</ScrollView>
      </div>
    );
  }

  return <div className={styles.main}>{data ? messageRender() : loadingRender()}</div>;
}

export default Component;
