import React, { useEffect } from "react";
import { Route, withRouter, useHistory } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, useOktaAuth, SecureRoute } from "@okta/okta-react";
import { getAuthInfo } from "helper/global";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import Loading from "components/loading/loading";
import { status } from "redux/enums/auth";
import Error from "../error/error";
import Login from "../login/login";
import Logout from "../logout/logout";

function Okta(props) {
  //
  const authInfo = getAuthInfo();

  const oktaAuth = new OktaAuth({
    issuer: authInfo.validIssuer,
    clientId: authInfo.validAudience,
    redirectUri: window.location.origin + "/okta/callback",
  });

  const history = useHistory();

  var restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute path="/" exact={false} component={Home} />
      <Route path="/okta/callback" exact={true} component={LoginCallback} />
    </Security>
  );
}

export default withRouter(Okta);

function mapStateToProps(state) {
  return {
    status: state.auth.status,
  };
}

function mapsDispatchToProps(dispatch) {
  return {
    session: (state) => dispatch(actions.session(state)),
    login: (orgId, token) => dispatch(actions.tokenLogin(orgId, token)),
  };
}

const Home = connect(
  mapStateToProps,
  mapsDispatchToProps
)((props) => {
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    var orgId = getAuthInfo().organizationId;
    props.login(orgId, authState.idToken.idToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.status === status.LoggedOut) oktaAuth.signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  function render() {
    switch (props.status) {
      case status.UnAuthenticated:
        return <Loading />;
      case status.IsAuthenticated:
        return <Login />;
      case status.LoggedOut:
        return <Logout />;
      case status.LoginDenied:
        return <Error text={"UnAuthorized Access..!"} />;
      default:
        return <Error />;
    }
  }
  return render();
});
