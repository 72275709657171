import React, { useEffect, useRef, useContext, useMemo } from "react";
import styles from "./map.module.scss";
import "./data-map.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import * as gis from "helper/gis";
import HierarchyContext from "components/hierarchy/context";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useImmerReducer } from "use-immer";
import Toolbar, { Item } from "devextreme-react/toolbar";
import Editor from "components/asset-editor/asset-editor";
import AssetList from "components/asset-grid/asset-grid";
import { withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";

function reducer(draft, action) {
  switch (action.type) {
    case "loaded":
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      return;
    case "showGrid":
      draft.showGrid = true;
      draft.highlighted = action.payload.highlighted;
      return;
    case "hideGrid":
      draft.showGrid = false;
      draft.highlighted = [];
      return;
    case "showEditor":
      draft.showEditor = true;
      draft.nodeId = action.payload.nodeId;
      draft.assetId = action.payload.assetId;
      return;
    case "hideEditor":
      draft.showEditor = false;
      return;
    case "render":
      draft.render = !draft.render;
      break;
    case "clear":
      draft.showGrid = false;
      draft.showEditor = false;
      draft.highlighted = [];
      draft.render = !draft.render;
      break;
    default:
      return;
  }
}

function Component(props) {
  //
  // constants
  const fields = [
    {
      name: "id",
      alias: "Id",
      type: "integer",
    },
    {
      name: "name",
      alias: "Name",
      type: "string",
    },
    {
      name: "selected",
      alias: "Selected",
      type: "integer",
    },
    {
      name: "visible",
      alias: "Visible",
      type: "integer",
    },
  ];

  // hooks

  const initialState = {
    highlighted: [],
    loading: false,
    render: false,
    showGrid: false,
    showEditor: false,
    nodeId: null,
    assetId: null,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  const mapRef = useRef();
  const firstRender = useRef(true);
  const highlighted = useRef([]);

  // useEffect

  useEffect(
    function () {
      if (firstRender.current) return;
      (async () => {
        highlighted.current = [];
        gis.clear();
        await getData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRowsData, filter, props.render]
  );

  useEffect(
    function () {
      (async () => {
        await gis.initialize(mapRef, fields, null, null, onHighlight, onClearHight, onClick, false, false);
        await getData();
        firstRender.current = false;
      })();

      return function () {
        gis.dispose();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // functions

  async function getData() {
    try {
      dispatch({ type: "loading" });
      gis.indicator(true);
      let layers = await request({ url: `${url}/gis/layers`, params: { ids: selectedRowsData.map((i) => i.id) } });
      var assets = null;
      if (!layers.every((i) => i.isRef)) {
        assets = await request({
          url: `${url}/gis`,
          params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
        });
      }
      await gis.setLayers(layers, assets);
    } catch (error) {
      console.log("getData: ", error);
    } finally {
      dispatch({ type: "loaded" });
    }
  }

  // event handler

  async function onHighlight(e) {
    highlighted.current.push(...e);
    dispatch({ type: "render" });
  }

  function onClearHight() {
    highlighted.current = [];
    dispatch({ type: "clear" });
  }

  function showGrid() {
    dispatch({ type: "showGrid", payload: { highlighted: JSON.parse(JSON.stringify(highlighted.current)) } });
  }

  function hideGrid() {
    dispatch({ type: "hideGrid" });
  }

  function hideEditor() {
    dispatch({ type: "hideEditor" });
  }

  function onClick(e, attr) {
    if (attr) {
      if (e === "hold") {
        // highlighted.current = [];
        // gis.clearHighlight();
        if (!highlighted.current.includes((i) => i.id === attr.id)) {
          highlighted.current.push(attr);
          gis.highlight(attr.id, true);
        }
        dispatch({ type: "showEditor", payload: { nodeId: attr.id, assetId: attr.assetId } });
      } else if (highlighted.current.find((i) => i.id === attr.id)) {
        highlighted.current = highlighted.current.filter((i) => i.id !== attr.id);
        gis.highlight(attr.id, false);
        dispatch({ type: "render" });
      } else {
        highlighted.current.push(attr);
        gis.highlight(attr.id, true);
        dispatch({ type: "render" });
      }
    }
  }

  // render

  function toolbarRender() {
    return (
      <div className={`${styles.toolbar} theme-toolbar manage-map`}>
        <Toolbar>
          <Item
            location="after"
            widget="dxButton"
            disabled={highlighted.current.length === 0}
            options={{
              icon: "folder",
              onClick: showGrid,
            }}
          />
        </Toolbar>
      </div>
    );
  }

  function gridRender() {
    return (
      <div className={styles.grid}>
        <Asset highlighted={state.highlighted} onBackButtonClick={hideGrid} />
      </div>
    );
  }

  const mapRender = useMemo(() => {
    return (
      <div className={styles.map} ref={mapRef}>
        <div id={"gis_indicator"} className={styles.loading}>
          <LoadIndicator />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, state.render, filter]);

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <Editor nodeId={state.nodeId} visible={state.showEditor} hideEditor={hideEditor} />
        <div className={styles.header}>{toolbarRender()}</div>
        {state.loading && <div className={styles.overlay} />}
        <div className={styles.container}>
          {mapRender}
          {state.showGrid && gridRender()}
        </div>
      </div>
    )
  );
}

export default Component;

const Asset = withMemo(
  (props) => {
    //
    // hooks

    const loadOptions = useRef(null);

    // stores

    const nodeStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          loadOptions.current = loadOptions;
          return request({
            method: "Post",
            url: `${url}/gis/nodes`,
            data: {
              ids: props.highlighted.map((i) => i.id),
            },
            loadOptions: loadOptions,
          });
        },
      }),
    };

    // render

    return (
      <AssetList
        title="Assets"
        dataSource={nodeStore}
        onBackButtonClick={props.onBackButtonClick}
        multiEditOptions={{
          url: `${url}/`,
          params: {
            ids: props.highlighted.map((i) => i.id),
          },
          loadOptions: loadOptions.current,
        }}
      />
    );
  },
  ["highlighted"]
);
