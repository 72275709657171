import React, { useRef } from "react";
import styles from "./grid.module.scss";
import { FilterRow, Scrolling } from "devextreme-react/data-grid";
import { DataGrid as DxGrid } from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import { confirm } from "devextreme/ui/dialog";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import ExcelJS from "exceljs";
import { now, dict, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { connect } from "react-redux";

function Component(props) {
  // stores

  const queryStore = {
    store: new CustomStore({
      key: "Id",
      load: (loadOptions) => {
        if (!props.query.id) return Promise.resolve([]);
        return exporting.current
          ? request({ url: `${url}/query/result/${props.query.id}/download`, loadOptions: loadOptions })
          : request({ url: `${url}/query/result/${props.query.id}`, loadOptions: loadOptions });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);
  const exporting = useRef(false);

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  async function onDownloadClick() {
    var result = await confirm(`<i>${dict("Do you really want to download the data")}?</i>`, "Confirm");
    if (result) {
      dataGrid.current.beginUpdate();
      dataGrid.current.columnOption("#", "visible", false);
      props.onExporting && props.onExporting({ component: dataGrid.current });
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet("Main sheet");

      var options = {};
      options.dateUTC = true;

      if (props.cultureInfo && props.cultureInfo !== "en-US") {
        var map = (valueText) => {
          var number = Number(valueText);
          if (!isNaN(number) && !Number.isInteger(number)) {
            return number.toLocaleString(props.cultureInfo, {
              minimumFractionDigits: number.countDecimals(),
            });
          }
          if (typeof valueText === "object") {
            return new Date(valueText).toISOString();
          }
          return valueText;
        };
        options.map = map;
      }
      if (props.csvDelimiter && props.csvDelimiter !== ",")
        options.formatterOptions = {
          delimiter: props.csvDelimiter,
          headers: true,
        };

      exportDataGrid({
        component: dataGrid.current,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer(options).then(function (buffer) {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), `IRIS Query Result ${now()}.csv`);
          onExported({ component: dataGrid.current });
        });
      });
    }
  }

  function onExporting(e) {
    e.component.columnOption("#", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    props.onExported && props.onExported(e);
    e.component.columnOption("#", "visible", true);
    exporting.current = false;
  }

  function titleRender() {
    return (
      <div className="theme-grid-title">
        <div>{dict("Result")}</div>
      </div>
    );
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        template: "titleRender",
      },
      {
        location: "after",
        widget: "dxButton",
        visible: props.allowDownload && true,
        options: {
          hint: "Download",
          icon: "fas fa-cloud-download",
          onClick: onDownloadClick,
        },
      }
    );
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <DxGrid
          dataSource={queryStore}
          height={"100%"}
          selection={{ mode: "single" }}
          remoteOperations={true}
          showRowLines={true}
          showColumnLines={true}
          showBorders={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
          onToolbarPreparing={onToolbarPreparing}
          onInitialized={onInitialized}
          onExporting={onExporting}
          onExported={onExported}
          onContentReady={(e) => {
            e.component.columnOption("Id", "visible", false);
          }}
        >
          <FilterRow visible={true} />
          <Scrolling mode="infinite" />
          <Template name="titleRender" render={titleRender} />
        </DxGrid>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    csvDelimiter: state.preference.csvDelimiter,
    cultureInfo: state.preference.cultureInfo,
  };
};

export default connect(mapStateToProps, null)(withMemo(Component, ["query", "render"]));
