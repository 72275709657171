import React, { useContext } from "react";
import Grid from "components/data-grid/data-grid.js";
import { currency } from "helper/global";
import { Column } from "devextreme-react/data-grid";
import HierarchyContext from "components/hierarchy/context";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";

function Component(props) {
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // stores

  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/output/activitylogs`,
          params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  return (
    <Grid
      dataSource={activityStore}
      title={""}
      showTitle={false}
      allowEditing={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      onToolbarPreparing={props.onToolbarPreparing}
    >
      <Column visible={false} dataField={"id"} />
      <Column caption={"AssetId"} dataField={"name"} width={200} />
      <Column caption={"Asset Description"} dataField={"description"} width={400} />
      <Column caption={"Year"} dataField={"year"} width={75} />
      <Column caption={"Activity Name"} dataField={"activityName"} width={200} />
      <Column caption={"Cost"} dataField={"cost"} width={200} format={`${currency()}#,###`} />
      <Column caption={"Strategy"} dataField={"strategy"} width={150} />
      <Column caption={"Location"} dataField={"location"} width={200} visible={false} />
      <Column caption={"Ownership"} dataField={"ownership"} width={150} />
      <Column caption={"Status"} dataField={"status"} width={150} />
      <Column caption={"Class"} dataField={"class"} width={150} />
      <Column caption={"Type"} dataField={"type"} width={150} visible={true} />
      <Column caption={"Size"} dataField={"size"} width={150} visible={true} />
      <Column caption={"Material"} dataField={"material"} width={150} visible={true} />
      <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} visible={true} />
      <Column caption={"Model"} dataField={"model"} width={150} visible={true} />
      <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
      <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
      <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
      <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
      <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
      <Column caption={"Remaining Useful Life"} dataField={"remainingUsefulLife"} width={150} />
      <Column caption={"Scope"} dataField={"scope"} width={150} allowFiltering={false} />
    </Grid>
  );
}

export default Component;
