import React, { useContext } from "react";
import styles from "./asset.module.scss";
import AssetList from "components/asset-grid/asset-grid";
import { url } from "config.js";
import HierarchyContext from "components/hierarchy/context";
import { Slider } from "devextreme-react/slider";
import { connect } from "react-redux";
import { Button } from "devextreme-react/button";
import { useImmerReducer } from "use-immer";
import { dict, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
// reducer

function reducer(draft, action) {
  switch (action.type) {
    case "loaded":
      draft.firstYearLoaded = draft.year === draft.firstYear;
      draft.otherYearLoaded = draft.year !== draft.firstYear;
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "unload":
      draft.firstYearLoaded = false;
      draft.otherYearLoaded = false;
      return;
    case "year":
      draft.year = action.payload;
      draft.otherYearLoaded = false;
      if (action.payload === draft.firstYear && !draft.firstYearLoaded) {
        render(draft);
      }
      return;
    case "escalate":
      draft.escalate = action.payload;
      draft.otherYearLoaded = false;
      //render(draft);
      return;
    case "run":
      render(draft);
      return;
    default:
      return;
  }

  function render(draft) {
    draft.render++;
    draft.loading = true;
    draft.firstYearLoaded = false;
    draft.otherYearLoaded = false;
  }
}

function Component(props) {
  const initialState = {
    data: null,
    year: props.firstYear,
    loading: false,
    firstYear: props.firstYear,
    firstYearLoaded: true,
    otherYearLoaded: false,
    render: 0,
    escalate: false,
    showGrid: false,
  };

  // hooks
  const [state, dispatch] = useImmerReducer(reducer, initialState);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  // const

  const isFirstYear = state.year === props.firstYear;
  const isButton = !isFirstYear && !state.otherYearLoaded && !state.loading;
  const forecast = props.forecast === undefined || props.forcast;

  function onSliderYearChanged(e) {
    dispatch({ type: "year", payload: e.value });
  }

  function onRunClick() {
    dispatch({ type: "run" });
  }

  function sliderRender() {
    return (
      <div className={styles.slider_container}>
        <div className={styles.slider_container_title}>{`${dict("Year")}:`}</div>
        <div className={styles.slider_container_slider}>
          <Slider
            min={props.firstYear}
            max={props.lastYear}
            defaultValue={state.year}
            value={state.year}
            step={1}
            showRange={true}
            onValueChanged={onSliderYearChanged}
            tooltip={{
              enabled: true,
              showMode: "always",
              position: "top",
            }}
          />
        </div>
      </div>
    );
  }

  function runButtonRender() {
    return (
      <div className={styles.overlay}>
        <Button text={"Run"} stylingMode="outlined" icon={"spinright"} type={"success"} onClick={onRunClick} />
      </div>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        {forecast && <div className={`${styles.header}`}>{sliderRender()}</div>}
        <Asset
          forecast={forecast}
          selectedRowsData={selectedRowsData}
          filter={filter}
          year={state.year}
          render={state.render}
          rating={state.rating}
          dispatch={dispatch}
        />
        {isButton && runButtonRender()}
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);

const Asset = withMemo(
  (props) => {
    //
    // store

    const nodeStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({
            url: `${url}/node/summary`,
            params: {
              ids: props.selectedRowsData.map((i) => i.id),
              year: props.year,
              rating: props.rating,
              filterExpression: props.filter,
            },
            loadOptions: loadOptions,
          });
        },
        onLoading: () => {
          props.dispatch({ type: "loading" });
        },
        onLoaded: () => {
          props.dispatch({ type: "loaded" });
        },
      }),
    };

    return (
      <div className={styles.grid} style={{ gridRowStart: props.forecast ? 2 : 1 }}>
        <AssetList
          title="Assets"
          showTitle={false}
          dataSource={nodeStore}
          multiEditOptions={{
            url: `${url}/multinode`,
            params: {
              ids: props.selectedRowsData.map((i) => i.id),
              filterExpression: props.filter,
            },
            fields: {},
          }}
        />
      </div>
    );
  },
  ["selectedRowsData", "filter", "render"]
);
