import React from "react";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { currency, withMemo } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import DropDown from "components/grid-dropdown/grid-dropdown";
import ProgressPanel from "components/progress-panel/progress-panel";
import Expand from "components/expand-img-editor/expand-img-editor";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // stores
  const activityLogStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/activityLog/byscenarionode`,
          loadOptions: loadOptions,
          params: { scenarioNodeId: props.data.scenarioNodeId },
        });
      },
      insert: (data) => {
        data.scenarioNodeId = props.data.scenarioNodeId;
        return request({
          url: `${url}/activityLog`,
          method: "Post",
          data: data,
        });
      },
      update: (_, data) => {
        return request({
          url: `${url}/activityLog`,
          method: "Put",
          data: data,
        });
      },
      remove: (id) => {
        return request({ url: `${url}/activityLog/${id}`, method: "Delete" });
      },
    }),
  };

  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/activity/scenarionode/${props.data.scenarioNodeId}`,
          loadOptions: loadOptions,
        });
      },
      byKey: function (id) {
        return request({ url: `${url}/activity/${id}` });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/conditionRating`,
          loadOptions: loadOptions,
        });
      },
      byKey: function (id) {
        return request({ url: `${url}/conditionRating/${id}` });
      },
    }),
  };

  // render

  function dropDownRender(data) {
    return (
      <DropDown data={data} showColumnHeaders={true} height={250} width={500}>
        <Column dataField="name"> </Column>
        <Column dataField="type"> </Column>
      </DropDown>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_ACTIVITYLOG"}>
      <Grid
        title={"Activity"}
        showTitle={false}
        selection={{ mode: "single" }}
        dataSource={activityLogStore}
        allowDownloading={false}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"AssetId"} dataField={"node"} visible={false} width={200} formItem={{ visible: false }} />
        <Column caption={"Strategy"} dataField={"strategy"} visible={false} width={200} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} width={350} allowEditing={false} allowFiltering={false} formItem={{ visible: false }} />
        <Column
          caption={"Type"}
          dataField={"activityId"}
          calculateDisplayValue="type"
          width={200}
          allowSorting={false}
          editCellRender={dropDownRender}
        >
          <Lookup dataSource={activityStore} valueExpr={"id"} displayExpr="type" />
          <RequiredRule />
        </Column>
        <Column caption={"Date"} dataField={"dateTime"} dataType={"date"} format="MM/dd/yyyy" width={150} sortOrder={"desc"}>
          <RequiredRule />
        </Column>
        <Column caption={"Condition"} dataField={"conditionRatingId"} calculateDisplayValue="conditionRating" width={150}>
          <Lookup dataSource={conditionRatingStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column
          caption={"Remaining Useful Life"}
          dataField={"remainingUsefulLife"}
          dataType={"number"}
          width={150}
          formItem={{ editorOptions: { showClearButton: true } }}
        />
        <Column
          caption={"Cost"}
          dataField={"cost"}
          dataType={"number"}
          width={150}
          format={`${currency()}#,##0.##`}
          formItem={{
            editorOptions: {
              format: `${currency()}#,##0.##`,
              showClearButton: true,
            },
          }}
        ></Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          width={400}
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { height: 75 },
          }}
        />
      </Grid>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["id"]));
