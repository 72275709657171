import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import NodeStatus from "./node_status/node_status";
import Group from "./group/group";
import Tag from "./tag/tag";
import MeasurementType from "./measurement-type/measurement-type";
import AliasType from "./alias-type/alias-type";
import Fields from "./custom-field/custom-field";
import Ownership from "./ownership/ownership";
import Facility from "./facility/facility";
import { dict } from "helper/global";

function Lookup(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Status"),
      icon: "fad fa-bullhorn",
      path: "/status",
    },
    {
      text: dict("Measurement"),
      icon: "fad fa-weight",
      path: "/measurementType",
    },

    {
      text: dict("Alias"),
      icon: "fad fa-signature",
      path: "/alias",
    },
    {
      text: dict("Ownership"),
      icon: "fad fa-user-crown",
      path: "/ownership",
    },
    {
      text: dict("Group"),
      icon: "fad fa-ball-pile",
      path: "/group",
    },
    {
      text: dict("Tag"),
      icon: "fad fa-tag",
      path: "/tag",
    },
    {
      text: dict("Field"),
      icon: "fad fa-th-list",
      path: "/field",
    },
    {
      text: dict("Facility"),
      icon: "fad fa-industry",
      path: "/facility",
    },
  ];

  // functions

  function changeView(e) {
    props.history.push(`/klient/data/lookup${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/data", "/klient/data/lookup"]} component={NodeStatus} />
          <Route exact path="/klient/data/lookup/status" component={NodeStatus} />
          {/* <Route exact path="/klient/data/lookup/conditiontype" component={ConditionType} /> */}
          <Route exact path="/klient/data/lookup/group" component={Group} />
          <Route exact path="/klient/data/lookup/tag" component={Tag} />
          <Route exact path="/klient/data/lookup/measurementtype" component={MeasurementType} />
          <Route exact path="/klient/data/lookup/alias" component={AliasType} />
          <Route exact path="/klient/data/lookup/field" component={Fields} />
          <Route exact path="/klient/data/lookup/ownership" component={Ownership} />
          <Route exact path="/klient/data/lookup/facility" component={Facility} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Lookup;
