import React from "react";
import Grid from "components/data-grid/data-grid.js";
import styles from "./ignore-status.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  // stores

  const ignoreStatusStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/ignorestatus`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/ignorestatus`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/ignorestatus`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/ignorestatus/${id}`, method: "Delete" });
      },
    }),
  };

  const nodeStatusStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/nodestatus`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/nodestatus/${id}` });
      },
    }),
  };

  // render

  return (
    <div className={styles.main}>
      <Grid title={""} dataSource={ignoreStatusStore} allowDownloading={false}>
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Status"} dataField={"statusId"} calculateDisplayValue="status" width={400}>
          <RequiredRule />
          <Lookup dataSource={nodeStatusStore} valueExpr={"id"} displayExpr="name" />
        </Column>
      </Grid>
    </div>
  );
}

export default Component;
