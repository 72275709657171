import React, { useRef, useState, useEffect } from "react";
import DataGrid from "devextreme-react/data-grid";
import _ from "devextreme-react/text-area";
import { Template } from "devextreme-react/core/template";
import FileUploader from "components/csv-uploader/csv-uploader";
import { Scrolling, Paging, Column, Export, Sorting, Editing, Popup, Position, FilterRow } from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import styles from "./data-grid.module.scss";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { now } from "helper/global";
import { dict } from "helper/global";
import Expand from "../expand/expand";
import { connect } from "react-redux";

Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

  var str = this.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

function Component(props) {
  if (props.allowExpanding) {
    return (
      <Expand title={props.title} onToolbarPreparing={props.onToolbarPreparing}>
        <Grid {...props} />
      </Expand>
    );
  } else return <Grid {...props} />;
}

export default Component;

const mapStateToProps = (state) => {
  return {
    csvDelimiter: state.preference.csvDelimiter,
    cultureInfo: state.preference.cultureInfo,
  };
};

var Grid = connect(mapStateToProps, null)(_Grid);

function _Grid(props) {
  //
  // useState

  const [fileLoader, setFileLoader] = useState(false);

  // useRef

  const dataGrid = useRef(null);
  const columns = useRef();
  const rendered = useRef(false);

  const allowAdding = props.allowAdding === undefined || props.allowAdding;
  const allowUpdating = props.allowUpdating === undefined || props.allowUpdating;
  const allowDeleting = props.allowDeleting === undefined || props.allowDeleting;
  const allowEditing = allowUpdating || allowDeleting;
  const allowDownloading = props.allowDownloading === undefined || props.allowDownloading;
  const allowFiltering = props.allowFiltering === undefined || props.allowFiltering;
  const rowNumbering = props.rowNumbering === undefined || props.rowNumbering;
  const showTitle = props.showTitle === undefined || props.showTitle;
  const showButtons = props.showButtons === undefined || props.showButtons;

  useEffect(() => {
    rendered.current = true;
  });

  // event handlers

  function onKeyDown(e) {
    var selKey = e.component.getSelectedRowKeys();
    if (selKey.length) {
      var currentKey = selKey[0];
      var index = e.component.getRowIndexByKey(currentKey);
      if (e.event.keyCode === 38) {
        index--;
        if (index >= 0) {
          e.component.selectRowsByIndexes([index]);
          e.event.stopPropagation();
        }
      } else if (e.event.keyCode === 40) {
        index++;
        e.component.selectRowsByIndexes([index]);
        e.event.stopPropagation();
      }
    }
  }

  function hideFileLoader() {
    setFileLoader(false);
  }

  function onUploadClick() {
    setFileLoader(true);
  }

  function onUploaded(e) {
    setFileLoader(false);
    props.onUploaded && props.onUploaded(e);
  }

  async function onDownloadClick() {
    var result = await confirm(`<i>${dict("Do you really want to download the data")}?</i>`, "Confirm");
    if (result) {
      dataGrid.current.beginUpdate();
      setDefaultColumns();
      dataGrid.current.columnOption("#", "visible", false);
      props.onExporting && props.onExporting({ component: dataGrid.current });
      var workbook = new ExcelJS.Workbook();
      var worksheet = workbook.addWorksheet("Main sheet");

      var options = {};
      options.dateUTC = true;

      // const countDecimals = function () {
      //   if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
      //   return this.toString().split(".")[1].length || 0;
      // };

      if (props.cultureInfo && props.cultureInfo !== "en-US") {
        var map = (valueText) => {
          var number = Number(valueText);
          if (!isNaN(number) && !Number.isInteger(number)) {
            return number.toLocaleString(props.cultureInfo, {
              minimumFractionDigits: number.countDecimals(),
            });
          }
          if (typeof valueText === "object") {
            return new Date(valueText).toISOString();
          }
          return valueText;
        };
        options.map = map;
      }
      if (props.csvDelimiter && props.csvDelimiter !== ",")
        options.formatterOptions = {
          delimiter: props.csvDelimiter,
          headers: true,
        };

      exportDataGrid({
        component: dataGrid.current,
        worksheet: worksheet,
      }).then(function () {
        workbook.csv.writeBuffer(options).then(function (buffer) {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), `IRIS ${props.title.toUpperCase()} ${now()}.csv`);
          onExported({ component: dataGrid.current });
        });
      });
    }
  }

  function onSelectionChanged(e) {
    //render.current && props.onSelectionChanged && props.onSelectionChanged(e);
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  rendered.current = false;
  dataGrid.current && dataGrid.current.clearSelection();

  function onContentReady(e) {
    var selectionMode = e.component.option("selection").mode;
    let selectedRowKeys = e.component.option("selectedRowKeys");

    if (selectionMode === "single" && selectedRowKeys.length === 0) {
      if (e.component.totalCount() > 0) {
        e.component.selectRowsByIndexes([0]);
      } else {
        props.onSelectionChanged && props.onSelectionChanged({ selectedRowsData: [] });
      }
    }
    props.onContentReady && props.onContentReady(e);
  }

  function onRowUpdating(e) {
    e.newData = { ...e.oldData, ...e.newData };
  }

  function onAddRow() {
    dataGrid.current.addRow();
    props.onAddRow && props.onAddRow();
  }

  function onExporting(e) {
    e.cancel = true;
  }

  function onExported(e) {
    setDictColumns();
    props.onExported && props.onExported(e);
    e.component.columnOption("#", "visible", true);
    e.component.endUpdate();
  }

  function customizeExcelCell(e) {
    props.customizeExcelCell && props.customizeExcelCell(e);
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
    columns.current = {};
    e.component.getVisibleColumns().forEach((col) => {
      columns.current[col.index] = col.caption;
    });
    setDictColumns();
    props.onInitialized && props.onInitialized(e);
  }

  function onRowRemoving(e) {
    let selectedRowKeys = e.component.option("selectedRowKeys");
    if (selectedRowKeys.length > 0 && selectedRowKeys[0] === e.data.id) dataGrid.current && dataGrid.current.clearSelection();
    props.onRowRemoving && props.onRowRemoving();
  }

  // functions

  function setDictColumns() {
    for (const prop in columns.current) {
      dataGrid.current.columnOption(Number(prop), "caption", dict(columns.current[prop]));
    }
  }

  function setDefaultColumns() {
    for (const prop in columns.current) {
      dataGrid.current.columnOption(Number(prop), "caption", columns.current[prop]);
    }
  }

  // render

  function titleRender() {
    return props.title ? (
      <div className="theme-grid-title">
        <div>{dict(props.title)}</div>
      </div>
    ) : null;
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      template: "titleRender",
      visible: showTitle,
    });

    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",
        visible: allowAdding,
        options: {
          hint: dict("Add a row"),
          icon: "fas fa-plus",
          onClick: onAddRow,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.onUploaded,
        options: {
          hint: dict("Upload"),
          icon: "fas fa-cloud-upload",
          onClick: onUploadClick,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.allowDownload || !!allowDownloading,
        options: {
          hint: dict("Download"),
          icon: "fas fa-cloud-download",
          onClick: onDownloadClick,
        },
      }
    );

    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <div className={styles.main}>
      <FileUploader fileLoaderVisible={fileLoader} hideFileLoader={hideFileLoader} onUploaded={onUploaded} />
      <div className={styles.row}>
        <div className={`${styles.col} theme-datagrid`}>
          <DataGrid
            keyExpr={"id"}
            height={"100%"}
            remoteOperations={true}
            showColumnLines={true}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnResizingMode="widget"
            onKeyDown={onKeyDown}
            onRowUpdating={onRowUpdating}
            onContentReady={onContentReady}
            wordWrapEnabled={true}
            syncLookupFilterValues={false}
            {...props}
            onSelectionChanged={onSelectionChanged}
            onToolbarPreparing={onToolbarPreparing}
            onExported={onExported}
            onExporting={onExporting}
            customizeExcelCell={customizeExcelCell}
            onInitialized={onInitialized}
            onRowRemoving={onRowRemoving}
            onSaving={() => {
              dataGrid.current && dataGrid.current.clearSelection();
            }}
          >
            {allowEditing && (
              <Editing mode={props.editingMode || "popup"} allowUpdating={allowUpdating} allowDeleting={allowDeleting}>
                <Popup title={dict(props.title)} showTitle={true} width={700} height={700}>
                  <Position my="center" at="center" of={window} />
                </Popup>
              </Editing>
            )}
            {allowFiltering && <FilterRow visible={true} />}
            <Paging pageSize={50} />
            <Sorting mode="multiple" />
            <Scrolling mode={"infinite"} showScrollbar={"always"} />
            <Export enabled={false} fileName="iris_export" allowExportSelectedData={false} customizeExcelCell={props.customizeExcelCell} />
            <Template name="titleRender" render={titleRender} />
            {rowNumbering ? (
              <Column
                key={"#"}
                dataField={"#"}
                caption={"#"}
                width={75}
                allowEditing={false}
                formItem={{ visible: false }}
                allowFiltering={false}
                allowSorting={false}
                cellRender={(e) => {
                  return <div>{e.row.loadIndex + 1}</div>;
                }}
              />
            ) : null}
            {props.children}
            {showButtons && (allowUpdating || allowDeleting) && <Column type={"buttons"} width={100} fixed={true} />}
          </DataGrid>
        </div>
      </div>
    </div>
  );
}
