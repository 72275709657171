import React, { useContext } from "react";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
import Profile from "./profile/profile";
//import Distribution from "./cost-distribution/cost-distribution";
import Replacement from "./rep-cost-distribution/rep-cost-distribution";
import styles from "./cost.module.scss";
import HierarchyContext from "components/hierarchy/context";
import { dict } from "helper/global";

function Component() {
  //
  // useContext

  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;
  //
  // render

  function profileRender1() {
    return <Profile selectedRowsData={selectedRowsData} filter={filter} />;
  }

  // function distributionRender() {
  //   return <Distribution selectedRowsData={selectedRowsData} scenarioNodeId={scenarioNodeId} filter={filter} />;
  // }

  function replacmentCostRender() {
    return <Replacement selectedRowsData={selectedRowsData} filter={filter} />;
  }

  function renderTabs() {
    return (
      selectedRowsData.length > 0 && (
        <div className={styles.content}>
          <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
            <TabItem title={dict("Lifecycle")} render={profileRender1} />
            {/* <TabItem title="Distribution" render={distributionRender} /> */}
            <TabItem title={dict("Replacement")} render={replacmentCostRender} />
          </TabPanel>
        </div>
      )
    );
  }

  return <div className={styles.main}>{renderTabs()}</div>;
}

export default Component;
