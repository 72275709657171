import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { getAuthInfo } from "helper/global";

function Component(props) {
  //
  // hooks

  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    var orgId = getAuthInfo().organizationId;

    async function login() {
      try {
         await instance.loginPopup({
          scopes: ["User.Read"],
        });
        // if (loginResponse) props.login(orgId, loginResponse.idToken);
      } catch (error) {
        console.error(error);
      }
    }
    async function token() {
      try {
        const silentResponse = await instance.acquireTokenSilent({ scopes: ["User.Read"], account: accounts[0] });
        if (silentResponse) props.login(orgId, silentResponse.idToken);
        // console.log(silentResponse);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          login();
        } else console.error(error);
      }
    }

    if (inProgress === "none") {
      if (accounts.length > 0) token();
      else login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, inProgress]);

  // render

  return null;
}

function mapsDispatchToProps(dispatch) {
  return {
    login: (orgId, token) => dispatch(actions.tokenLogin(orgId, token)),
  };
}

export default connect(null, mapsDispatchToProps)(Component);
