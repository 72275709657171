import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";
import { loadUser } from "./user";
import { loadOrg } from "./org";
import { loadScenario } from "./scenario";
//import { loadDictionary } from "./dictionary";
import { clearHome } from "./home";
import { loadApp } from "./app";
import { status } from "../enums/auth";
import { loadPreference } from "./preference";

async function _loadSettings(dispatch) {
  await dispatch(loadUser());
  await dispatch(loadOrg());
  await dispatch(loadScenario());
  //dispatch(loadDictionary()),
  await dispatch(loadApp());
  await dispatch(loadPreference());
}
// public functions

export const session = () => {
  return async (dispatch) => {
    try {
      var session = false;
      session = await request({ url: `${url}/auth/session` });
      if (session) await _loadSettings(dispatch);
    } catch (e) {
      error(e);
    } finally {
      dispatch({
        type: actionTypes.SESSION,
        payload: {
          status: session ? status.InSession : status.SessionDenied,
        },
      });
    }

    return "done";
  };
};

export const credLogin = (cred) => {
  return async (dispatch) => {
    var auth = false;
    try {
      dispatch({ type: actionTypes.LOGIN_START });
      auth = await request({ url: `${url}/auth/login`, method: "Post", data: cred });
      if (auth) await _loadSettings(dispatch);
    } catch (e) {
      error(e);
    } finally {
      dispatch({
        type: actionTypes.LOGIN_END,
        payload: {
          status: auth ? status.IsAuthenticated : status.LoginDenied,
        },
      });
    }
    return "done";
  };
};

export const tokenLogin = (orgId, token) => {
  return async (dispatch) => {
    var auth = false;
    try {
      dispatch({ type: actionTypes.LOGIN_START });
      auth = await request({ url: `${url}/auth?organizationId=${orgId}`, method: "Post", token: token });

      if (auth) await _loadSettings(dispatch);
    } catch (e) {
      error(e);
    } finally {
      dispatch({
        type: actionTypes.LOGIN_END,
        payload: {
          status: auth ? status.IsAuthenticated : status.LoginDenied,
        },
      });
    }
    return "done";
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await request({ url: `${url}/auth/logout`, method: "Post" });
      dispatch(clearHome());
    } catch (e) {
      error(e);
    } finally {
      dispatch({
        type: actionTypes.LOGOUT,
      });
    }
    return "done";
  };
};

export const error = (error) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ERROR,
      payload: {
        error: error,
      },
    });
  };
};
