import React from "react";
import styles from "./project.module.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { selectedTabItem } from "helper/global";
import Tabs from "components/tabs/tabs";
// import Activity from "./activity";
import Activity1 from "./project-activity";
import ProjectShapshot from "./project-snapshot/project-snapshot";
// import Investment from "./investment/investment";
import Progress from "./progress/project-group";
import { dict } from "helper/global";

function Input(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Activity"),
      icon: "fad fa-chart-network",
      path: "/activity",
    },
    {
      text: dict("Shapshot"),
      icon: "fad fa-camera-polaroid",
      path: "/snapshot",
    },
    {
      text: dict("Progress"),
      icon: "fad fa-calendar-alt",
      path: "/progress",
    },
  ];

  // event handlers

  function onChangeView(e) {
    props.history.push(`/klient/plan/project${e.path}`);
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={onChangeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/plan/project", "/klient/plan/project/activity"]} component={Activity1} />
          <Route exact path={"/klient/plan/project/progress"} component={Progress} />
          <Route exact path={"/klient/plan/project/snapshot"} component={ProjectShapshot} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Input;
