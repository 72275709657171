import React, { useRef } from "react";
import Grid from "components/list/list.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { withMemo } from "helper/global";
import { connect } from "react-redux";
import { RequiredRule } from "devextreme-react/form";

function Component(props) {
  //
  // stores

  const financeScenarioStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/financeScenario`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/financeScenario`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/financeScenario`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/financeScenario/${id}`, method: "Delete" });
      },
    }),
  };

  const fundStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/fund`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/fund/${id}` });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/department/${id}` });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  return (
    <Grid
      title={"Scenario"}
      dataSource={financeScenarioStore}
      selection={{ mode: "single" }}
      allowDownloading={false}
      onSelectionChanged={onSelectionChanged}
      onInitialized={onInitialized}
      onInitNewRow={(e) => {
        e.data.escalate = false;
      }}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"}>
        <RequiredRule />
      </Column>
      <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} visible={false} />
      <Column caption={"Default Fund"} dataField={"fundId"} calculateDisplayValue="fund" width={200} visible={false}>
        <Lookup dataSource={fundStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      <Column caption={"Years"} dataField={"years"} dataType={"number"} width={150} visible={false} />
      <Column caption={"Department"} dataField={"departmentId"} visible={false} formItem={{ visible: props.departmentId === null }}>
        <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      <Column caption={"Escalate"} dataField={"escalate"} dataType={"boolean"} width={400} visible={false} />
      <Column visible={false} formItem={{ itemType: "empty", visible: props.departmentId !== null }} />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        allowFiltering={true}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentId: state.user.departmentId,
  };
};

export default connect(mapStateToProps, null)(withMemo(Component, []));
