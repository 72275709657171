import React from "react";
import DxTagBox from "devextreme-react/tag-box";
import "./tag-box.scss";
import { dict } from "helper/global";

function TagBox(props) {
  // event hanlder

  function onValueChanged(e) {
    if (e.event && e.value.length !== e.previousValue.length && Array.isArray(e.value)) {
      props.onValueChanged && props.onValueChanged([...e.value]);
    }
  }

  return (
    <div className={"components-tagbox"}>
      <DxTagBox
        dataSource={props.dataSource}
        value={props.selectedTags}
        displayExpr={props.displayExpr}
        valueExpr={props.valueExpr}
        onValueChanged={onValueChanged}
        placeholder={dict(props.placeholder) || "Select..."}
        height={"100%"}
        showSelectionControls={props.allowEditing === undefined || props.allowEditing}
        openOnFieldClick={props.allowEditing === undefined || props.allowEditing}
        stylingMode={"filled"}
        onInitialized={props.onInitialized}
        searchEnabled={true}
      />
    </div>
  );
}

export default TagBox;
