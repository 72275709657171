export const LOGIN_START = "LOGIN_START";
export const LOGIN_END = "LOGIN_END";
export const LOGOUT = "LOGOUT";
export const RESET = "RESET";
export const ERROR = "ERROR";
export const SESSION = "SESSION";

export const ORG_LOAD = "ORG_LOAD";
// export const ORG_SAVE = "ORG_SAVE";
// export const ORG_UPDATE = "ORG_UPDATE";

export const USER_LOAD = "USER_LOAD";
export const USER_SAVE = "USER_SAVE";
export const USER_UPDATE = "USER_UPDATE";

export const PROGRESS_START = "PROGRESS_START";
export const PROGRESS_CANCEL = "PROGRESS_CANCEL";
export const PROGRESS_END = "PROGRESS_END";

export const JOB_START = "JOB_START";
export const JOB_END = "JOB_END";
export const JOB_PROGRESS = "JOB_PROGRESS";

export const TOGGLE_MENU = "TOGGLE_MENU";
export const CHANGE_PATH = "CHANGE_PATH";

export const SCENARIO_LOAD = "SCENARIO_LOAD";

export const HOME_LOAD = "HOME_LOAD";
export const HOME_CLEAR = "HOME_CLEAR";

export const DICTIONARY_LOAD = "DICTIONARY_LOAD";
export const DICTIONARY_SAVE = "DICTIONARY_SAVE";
export const DICTIONARY_UPDATE = "DICTIONARY_UPDATE";

export const APP_LOAD = "APP_LOAD";

export const PREFERENCE_LOAD = "PREFERENCE_LOAD";
