import React, { useState, useRef } from "react";
import styles from "./readlog.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { Column } from "devextreme-react/data-grid";
import { withMemo, notify, useRefState } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
import DataLog from "../datalog/datalog";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useRefState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  async function onRestore(e) {
    var result = await confirm("<i>Do you want to restore?</i>", "Confirm");
    if (result) {
      try {
        props.run({
          name: "KONNECT",
          url: `konnect/restore/readlog/${e.row.data.id}`,
        });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.readlog}>
        <ReadLog sourceId={props.sourceId} onSelectionChanged={onSelectionChanged} onRestore={onRestore} />
      </div>
      <div className={styles.datalog}>{<DataLog readLogId={state.current.id} />}</div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const ReadLog = withMemo(
  (props) => {
    //
    // stores

    const readLogStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.sourceId) return Promise.resolve({ data: [] });
          return request({
            url: `konnect/readlog/source/${props.sourceId}`,
            loadOptions: loadOptions,
          });
        },
      }),
    };

    function onCellPrepared(e) {
      if (e.rowType === "data" && e.column.dataField && e.column.dataField === "status") {
        if (e.data.statusId === 0) e.cellElement.style.color = "#98c21f";
        else e.cellElement.style.color = "red";
      }

      // if (e.rowType === "data" && e.column.dataField) {
      //   if (e.data.message === 1) e.cellElement.style.color = "red";
      // }
    }

    return (
      <Grid
        title={"Read Log"}
        dataSource={readLogStore}
        selection={{ mode: "single" }}
        onSelectionChanged={props.onSelectionChanged}
        allowExpanding={true}
        allowAdding={false}
        allowUpdating={false}
        allowDownloading={false}
        showButtons={false}
        onCellPrepared={onCellPrepared}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Date"} dataField={"dateTime"} dataType={"date"} format="MM/dd/yyyy HH:mm" width={200} />
        <Column caption={"Status"} dataType={"statusId"} visible={false} width={100} />
        <Column caption={"Status"} dataField={"status"} />
        <Column
          type={"buttons"}
          buttons={[
            {
              hint: "Restore",
              icon: "revert",
              onClick: props.onRestore,
            },
          ]}
          width={100}
          fixed={true}
        />
      </Grid>
    );
  },
  ["sourceId"]
);
