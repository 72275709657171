import React, { useState } from "react";
import { Popup } from "devextreme-react/popup";
import styles from "./style.module.scss";
import { Button } from "devextreme-react/button";

function Component(props) {
  //
  // hooks

  const [expand, setExpand] = useState(props.showExpanded || false);

  //render

  function onToolbarPreparing(e) {
    props.onToolbarPreparing && props.onToolbarPreparing(e);
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Expand",
        icon: "fas fa-expand-alt",
        onClick: () => {
          setExpand(true);
        },
      },
    });
  }

  function titleRender() {
    return (
      <div className={styles.header}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.button}>
          <Button
            hint={"Close"}
            icon={"close"}
            onClick={() => {
              setExpand(false);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {!expand &&
          React.cloneElement(props.children, {
            onToolbarPreparing: onToolbarPreparing,
          })}
        {expand && (
          <Popup
            visible={expand}
            onHiding={() => {
              setExpand(false);
            }}
            showCloseButton={true}
            dragEnabled={true}
            closeOnOutsideClick={false}
            showTitle={true}
            width={1080}
            height={900}
            titleRender={titleRender}
          >
            {React.cloneElement(props.children, { showTitle: false })}
          </Popup>
        )}
      </div>
    </div>
  );
}

export default Component;
