import React, { useState, useEffect } from "react";
import { request } from "helper/http-client";
import { url } from "config.js";
import TagBox from "components/tag-box/tag-box";

function Component(props) {
  //
  // hooks
  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    (async () => {
      var result = await request({ url: `${url}/tag` });
      setTags(result.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var result = await request({ url: `${url}/nodetag/node/${props.data.id}` });
      setSelected(result.tagIds);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.id]);

  // event handlers
  async function onValueChanged(e) {
    await request({ url: `${url}/nodetag`, method: "Put", data: { nodeId: props.data.id, tagIds: e } });
    setSelected(e);
  }
  // render

  return (
    <div>
      <TagBox
        dataSource={tags}
        selectedTags={[...selected]}
        displayExpr="name"
        valueExpr="id"
        placeholder={"Click to select tags..."}
        onValueChanged={onValueChanged}
      />
    </div>
  );
}

export default Component;
