import React, { useState, useEffect, useRef } from "react";
import styles from "./jobpanel.module.scss";
import "./jobpanel.scss";
import { connect } from "react-redux";
import HierarchyContext from "./context";
import { url } from "config.js";
import { request } from "helper/http-client";
import { Circle } from "rc-progress";
import { Button } from "devextreme-react/button";
import * as actions from "redux/actions";
import { alert } from "devextreme/ui/dialog";

function Component(props) {
  //
  // useRef
  const processing = useRef(false);
  const stateRef = useRef(null);

  // useEffect
  useEffect(() => {
    if (props.jobName === props.name && !processing.current) {
      processing.current = true;
      return;
    }

    if (processing.current && !props.jobName) {
      props.onComplete && props.onComplete();
      processing.current = false;
    }
  });

  // event handlers

  function onStateChanged(e) {
    stateRef.current = e;
  }

  // render

  function childrenRender() {
    return (
      <HierarchyContext.Provider value={{ state: stateRef.current, onStateChanged: onStateChanged }}>
        <div className={styles.main}>{props.children}</div>
      </HierarchyContext.Provider>
    );
  }

  function progressRender() {
    return (
      <div className={styles.main}>
        <JobProgress jobId={props.jobId} />
      </div>
    );
  }

  return props.name === props.jobName ? progressRender() : childrenRender();
}

const mapStateToProps = (state) => {
  return {
    jobName: state.job.name,
    jobId: state.job.id,
  };
};

export default connect(mapStateToProps, null)(Component);

function _JobProgress(props) {
  //
  //  useState
  const initialState = { progress: 0 };
  const [state, setState] = useState(initialState);
  const isCompleted = useRef(false);

  // useEffect

  useEffect(() => {
    var interval = setInterval(async () => {
      if (!props.jobId) {
        props.endJob();
        return;
      }
      const result = await request({ url: `${url}/backgroundtask/${props.jobId}` });
      if (result) {
        if (result.isCompleted) {
          props.endJob();
          clearInterval(interval);
          if (!isCompleted.current && result.statusId === 3) {
            isCompleted.current = true;
            await alert(
              `
              <div style="color: red; width: 600px;"><span style="font-size: 14px;"> ${result.message}</span></div>
              `,
              "Error"
            );
            // await alert(result.message, "Error");
          }
        } else setState(result);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // event Handler

  async function onCancel() {
    await request({ method: "Post", url: `${url}/backgroundtask/cancel/${props.jobId}` });
    // await request({ url: `${url}/job/${props.jobId}` });
    props.endJob();
  }

  // render

  return (
    <div className={styles.jobprogress}>
      <div className={styles.jobprogress_container}>
        <div className={styles.jobprogress_circle}>
          <Circle percent={state.progress || 0} strokeWidth="6" strokeLinecap="round" strokeColor={"#98c21f"} />
        </div>
        <div className={styles.jobprogress_circle}>{`${state.progress || 0}%`}</div>
        <div className={styles.jobprogress_button}>
          <Button height={25} width={35} text="Cancel" type="normal" stylingMode="outlined" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    endJob: () => dispatch(actions.endJob()),
  };
};

const JobProgress = connect(null, mapDispatchToProps)(_JobProgress);
