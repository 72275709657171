import React, { useState, useContext, useRef } from "react";
import styles from "./asset.module.scss";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { dict, addFilter, withMemo, currency } from "helper/global";
import ProgressPanel from "components/progress-panel/progress-panel";
import HierarchyContext from "components/hierarchy/context";
import Measurement from "../measurement/measurement";
import Group from "../group/group";
import Tag from "../tag/tag";
import Alias from "../alias/alias";
import Image from "../image/image";
import Document from "../document/document";
import File from "../file/file";
import Summary from "../summary/summary";
import Field from "../field/field";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule, RangeRule, NumericRule } from "devextreme-react/validator";
import MultiEditor from "components/multi-editor/multi-editor";

function Component(props) {
  //
  // hooks

  const initialState = { id: null, nodeId: null, selectedRowsData: null, description: null, nodeselectedRowsData: null, type: null };
  const [state, setState] = useState(initialState);
  const [editor, setEditor] = useState(false);
  const loadOptions = useRef(null);

  // contenxt
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;
  const refresh = context.refresh;

  // event handlers

  function onSelectionChanged(e) {
    let selected = e.selectedRowsData[0];
    setState(
      selected ? { id: selected.assetId, description: selected.description, nodeId: selected.id, type: selected.nodeTypeId } : initialState
    );
  }

  function onUploadComplete() {
    refresh("hierarchy");
  }

  function onEditor() {
    setEditor(true);
  }

  function onLoadOptions(e) {
    loadOptions.current = e;
  }

  // render

  function summaryRender() {
    return state.id && <Summary assetId={state.id} nodeId={state.nodeId} />;
  }

  function measurementRender() {
    return state.id && <Measurement assetId={state.id} selectedHierarchyNodes={selectedRowsData} />;
  }

  function aliasRender() {
    return state.nodeId && <Alias nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function groupRender() {
    return state.nodeId && <Group nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function tagRender() {
    return state.nodeId && <Tag nodeId={state.nodeId} selectedHierarchyNodes={selectedRowsData} />;
  }

  function imageRender() {
    return state.id && <Image assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function documentRender() {
    return state.id && <Document assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function fileRender() {
    return state.id && <File assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function fieldRender() {
    return state.id && <Field assetId={state.id} assetDescription={state.description} selectedHierarchyNodes={selectedRowsData} />;
  }

  function detailsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        {state.type !== 3 && <Item title={dict("Summary")} render={summaryRender} />}
        {state.type !== 3 && <Item title={dict("Measurement")} render={measurementRender} />}
        <Item title={dict("Alias")} render={aliasRender} />
        <Item title={dict("Group")} render={groupRender} />
        <Item title={dict("Tag")} render={tagRender} />
        {state.type !== 3 && <Item title={dict("Field")} render={fieldRender} visible={state.type !== 3} />}
        {state.type !== 3 && <Item title={dict("Image")} render={imageRender} visible={state.type !== 3} />}
        {state.type !== 3 && <Item title={dict("Document")} render={documentRender} visible={state.type !== 3} />}
        {state.type !== 3 && <Item title={dict("File")} render={fileRender} visible={state.type !== 3} />}
      </TabPanel>
    );
  }

  function gridRender() {
    return (
      <ProgressPanel name={"UPLOAD_NODES"} onComplete={onUploadComplete}>
        <div className={styles.main}>
          <div className={`${styles.item1} klient-data-register-asset`}>
            <Asset
              selectedRowsData={selectedRowsData}
              filter={filter}
              refresh={refresh}
              onSelectionChanged={onSelectionChanged}
              onEditor={onEditor}
              onLoadOptions={onLoadOptions}
            />
          </div>
          <div className={styles.item2}>{detailsRender()}</div>
        </div>
      </ProgressPanel>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <MultiEditor
        options={{
          url: `${url}/multinode/bynodes`,
          loadOptions: loadOptions.current,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            filterExpression: filter,
          },
          showActivityTab: false,
          fields: {
            cof: false,
            r: false,
            usefulLife: false,
            lastYear: false,
            strategy: false,
            cost: false,
            costOverride: false,
          },
        }}
        visible={editor}
        onHiding={() => {
          setEditor(false);
        }}
      >
        {gridRender()}
      </MultiEditor>
    )
  );
}

export default Component;

// redux

function mapStateToProps(state) {
  return {
    multiEditNode: !!state.user.accessAdmin,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const Asset = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      //
      // variables

      let nodeTypes = [
        { id: 0, name: "Asset" },
        { id: 1, name: "Folder" },
        { id: 2, name: "SuperAsset" },
        { id: 3, name: "Program" },
      ];

      // let installationStatus = [
      //   { id: 0, name: "Installed" },
      //   { id: 1, name: "Not Installed" },
      // ];

      var editors = [];
      var nodeTypeId = null;
      var disabledForProgram = ["typeId", "sizeId", "materialId", "manufacturerId", "model", "installYear", "value"];

      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            props.onLoadOptions(loadOptions);
            var types = exporting.current ? [] : ["Asset", "SuperAsset", "Program"];
            return exporting.current
              ? request({
                  url: `${url}/node/download`,
                  params: { filterExpression: props.filter, ids: props.selectedRowsData.map((i) => i.id), types: types },
                  loadOptions: loadOptions,
                })
              : request({
                  url: `${url}/node`,
                  params: { filterExpression: props.filter, ids: props.selectedRowsData.map((i) => i.id), types: types },
                  loadOptions: loadOptions,
                });
          },
          insert: (data) => {
            return request({ url: `${url}/node`, method: "Post", data: data });
          },
          update: (_, data) => {
            return request({ url: `${url}/node`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/node/${id}`, method: "Delete" });
          },
        }),
      };

      const nodeStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/hierarchy`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/hierarchy/${id}` });
          },
        }),
        paginate: true,
        pageSize: 50,
      };

      const nodeStatusStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/nodeStatus`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/nodeStatus/${id}` });
          },
        }),
      };

      const ownershipStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/ownership`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/ownership/${id}` });
          },
        }),
      };

      const classStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/class`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/class/${id}` });
          },
        }),
      };

      const typeStore = (options) => {
        return {
          store: new CustomStore({
            key: "id",
            useDefaultSearch: true,
            load: (loadOption) => {
              let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
              return request({ url: `${url}/type`, loadOptions: options_ });
            },
            byKey: function (id) {
              return request({ url: `${url}/type/${id}` });
            },
          }),
        };
      };

      const sizeStore = (options) => {
        return {
          store: new CustomStore({
            key: "id",
            useDefaultSearch: true,
            load: (loadOption) => {
              let options_ = options.data ? addFilter(loadOption, "and", ["classId", "=", options.data.classId]) : loadOption;
              return request({ url: `${url}/size`, loadOptions: options_ });
            },
            byKey: function (id) {
              return request({ url: `${url}/size/${id}` });
            },
          }),
        };
      };

      const materialStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/material`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/material/${id}` });
          },
        }),
      };

      const manufacturerStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/manufacturer/${id}` });
          },
        }),
      };

      // hooks

      const dataGrid = useRef(null);
      const exporting = useRef(false);

      // event handlers

      function onRowInserted(e) {
        e.data.nodeTypeId === 2 && props.refresh("hierarchy");
        props.refresh("count");
      }

      function onRowRemoving(e) {
        dataGrid.current.clearSelection();
      }

      function onRowRemoved(e) {
        dataGrid.current.selectRowsByIndexes([0]);
        e.data.nodeTypeId === 2 && props.refresh("hierarchy");
        props.refresh("count");
      }

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("parentId", "visible", true);
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("nodeTypeId", "visible", true);
        e.component.columnOption("facility", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("parentId", "visible", false);
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("nodeTypeId", "visible", false);
        e.component.columnOption("facility", "visible", false);
        exporting.current = false;
      }

      function onInitialized(e) {
        dataGrid.current = e.component;
      }

      function setCellValue(newData, value, oldData) {
        newData.nodeTypeId = nodeTypeId || oldData.nodeTypeId;
        newData.classId = value;
        newData.typeId = null;
        newData.sizeId = null;
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_NODES", url: `${url}/node/upload/file/${fileId}` });
      }

      function onInitNewRow(e) {
        e.data.nodeTypeId = nodeTypeId = 0;
        e.data.parentId = props.selectedRowsData[0].id;
        e.data.count = 1;
      }

      function onEditorPreparing(e) {
        if (e.parentType === "dataRow") {
          if (disabledForProgram.some((i) => i === e.dataField)) {
            if (e.row.data.nodeTypeId === 3) {
              e.editorOptions.disabled = true;
            }
            e.editorOptions.onInitialized = function (e) {
              editors.push(e.component);
            };
          }
        }

        if (e.dataField === "nodeTypeId") {
          e.editorOptions.dataSource.store._array = [
            { id: 0, name: "Asset" },
            { id: 2, name: "SuperAsset" },
            { id: 3, name: "Program" },
          ];
        }
      }

      function onCellPrepared(e) {
        if (e.rowType === "data" && disabledForProgram.some((i) => i === e.column.dataField) && e.data.nodeTypeId === 3) {
          e.cellElement.style.background = "#44570E";
          e.cellElement.style.color = "#292929";
        }
      }

      function onNodeTypeChanged(e) {
        nodeTypeId = e.value;
        editors.forEach((i) => i.option("disabled", e.value === 3));
      }

      // rennder

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          visible: props.multiEditNode,
          options: {
            hint: "MultiEdit",
            icon: "fas fa-edit",
            onClick: props.onEditor,
          },
        });
      }

      return (
        <div className={styles.main}>
          <Grid
            title={"Asset"}
            selection={{ mode: "single" }}
            dataSource={assetStore}
            onUploaded={onUploaded}
            onSelectionChanged={props.onSelectionChanged}
            onInitialized={onInitialized}
            resetRowSelectionOnLoad={true}
            onInitNewRow={onInitNewRow}
            onExported={onExported}
            onExporting={onExporting}
            onEditorPreparing={onEditorPreparing}
            onRowRemoving={onRowRemoving}
            onRowInserted={onRowInserted}
            onRowRemoved={onRowRemoved}
            onCellPrepared={onCellPrepared}
            onToolbarPreparing={onToolbarPreparing}
            allowExpanding={true}
          >
            <Column caption={"Id"} dataField={"id"} visible={false} width={100} formItem={{ visible: false }} />
            <Column
              caption={"Operation"}
              dataField={"operation"}
              calculateCellValue={() => "Update"}
              width={100}
              visible={false}
              formItem={{ visible: false }}
            />
            <Column caption={"AssetId"} dataField={"name"} width={200}>
              <RequiredRule />
            </Column>
            <Column caption={"Description"} dataField={"description"} dataType={"string"} width={400}>
              <RequiredRule />
            </Column>
            <Column caption={"Parent"} dataField={"parentId"} calculateDisplayValue="parent" width={200} visible={false}>
              <Lookup dataSource={nodeStore} valueExpr={"id"} displayExpr="name" />
            </Column>
            <Column
              caption={"Node Type"}
              dataField={"nodeTypeId"}
              width={100}
              visible={false}
              allowSorting={false}
              formItem={{ visible: true, editorOptions: { readOnly: false, onValueChanged: onNodeTypeChanged } }}
            >
              <Lookup dataSource={nodeTypes} valueExpr={"id"} displayExpr="name" />
            </Column>
            <Column caption={"Location"} dataField={"location"} dataType={"string"} width={200} />
            <Column caption={"Ownership"} dataField={"ownershipId"} calculateDisplayValue="ownership" width={200}>
              <Lookup dataSource={ownershipStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
            </Column>
            <Column caption={"Status"} dataField={"statusId"} calculateDisplayValue="status" width={200}>
              <Lookup dataSource={nodeStatusStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
            </Column>
            <Column
              caption={"Class"}
              dataField={"classId"}
              calculateDisplayValue="class"
              width={200}
              setCellValue={setCellValue}
              dataType="number"
            >
              <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" />
              <RequiredRule />
            </Column>
            <Column caption={"Facility"} dataField={"facility"} visible={false} formItem={{ visible: false }} />
            <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
              <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
              <RequiredRule />
            </Column>
            <Column caption={"Size"} dataField={"sizeId"} calculateDisplayValue="size" width={200}>
              <Lookup dataSource={sizeStore} valueExpr={"id"} displayExpr="name" />
              <RequiredRule />
            </Column>
            <Column caption={"Material"} dataField={"materialId"} calculateDisplayValue="material" width={200}>
              <Lookup dataSource={materialStore} valueExpr={"id"} displayExpr="name" />
              <RequiredRule />
            </Column>
            <Column caption={"Manufacturer"} dataField={"manufacturerId"} calculateDisplayValue="manufacturer" width={200}>
              <Lookup dataSource={manufacturerStore} valueExpr={"id"} displayExpr="name" />
              <RequiredRule />
            </Column>
            <Column caption={"Model"} dataField={"model"} width={200} />

            <Column
              caption={"Install Year"}
              dataField={"installYear"}
              dataType={"number"}
              width={100}
              formItem={{ visible: true, editorOptions: { readOnly: false, showClearButton: true } }} // onValueChanged: onInstallYearChanged
            >
              <NumericRule />
            </Column>
            <Column
              caption={"Value"}
              dataField={"value"}
              dataType={"number"}
              width={200}
              format={`${currency()}#,##0.##`}
              formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
            />
            <Column caption={"Count"} dataField={"count"} dataType={"number"} width={100} allowFiltering={false} allowSorting={false}>
              <NumericRule />
              <RangeRule min={1} />
            </Column>
            {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
            <Column
              caption={"Notes"}
              dataField={"notes"}
              dataType={"string"}
              width={400}
              formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
            />
          </Grid>
        </div>
      );
    },
    ["selectedRowsData", "filter", "render"]
  )
);
