import React, { useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./output.module.scss";
import { selectedTabItem } from "helper/global";
import AssetSummary from "./asset-summary/asset-summary";
import Cost from "./cost/cost";
import Activity from "./activity/activity";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { confirm } from "devextreme/ui/dialog";
import JobPanel from "components/jobpanel/jobpanel";
import { notify, dict } from "helper/global";

function Output(props) {
  /// hooks
  const selected = useRef(null);

  // tabs

  const tabItems = [
    {
      text: dict("Summary"),
      icon: "fad fa-chart-line",
      path: "/assetsummary",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-sack",
      path: "/cost",
    },
    // {
    //   text: dict("Condition"),
    //   icon: "fad fa-chart-pie",
    //   path: "/condition",
    // },
    // {
    //   text: dict("Risk"),
    //   icon: "fad fa-th",
    //   path: "/risk",
    // },
    // {
    //   text: dict("Map"),
    //   icon: "fad fa-map",
    //   path: "/map",
    // },
    // {
    //   text: dict("Asset"),
    //   icon: "fad fa-fan",
    //   path: "/asset",
    // },
    {
      text: dict("Activity"),
      icon: "fad fa-chart-network",
      path: "/activity",
    },
  ];

  // event handlers

  function onSelectionChanged(e) {
    selected.current = e.selectedRowsData;
  }

  async function onRun() {
    var result = await confirm("<i>Do you really want to run the entire hierarchy?</i>", "Confirm");
    if (result) {
      props.clearHome();
      try {
        props.run({ name: "RUN", url: `${url}/forecasting` });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onNodeRun() {
    var result = await confirm("<i>Do you really want to run the selected hierarchy?</i>", "Confirm");
    if (result) {
      props.clearHome();
      try {
        props.run({
          name: "RUN",
          url: `${url}/forecasting/hierarchy`,
          params: { ids: selected.current.map((i) => i.id) },
        });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  async function onClear() {
    var result = await confirm("<i>Do you really want to delete the results?</i>", "Confirm");
    if (result) props.run({ name: "RUN", url: `${url}/forecasting/clear` });
  }

  // functions

  function changeView(e) {
    props.history.push(`/klient/plan/output${e.path}`);
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Run",
          icon: "fas fa-play",
          onClick: onRun,
        },
      },
      {
        location: "before",
        widget: "dxButton",

        options: {
          hint: "Delete Results",
          icon: "fas fa-times",
          onClick: onClear,
        },
      }
    );
  }

  function onContextMenuPreparing(e) {
    e.items.push({
      text: "Run",
      icon: "fas fa-play",
      onItemClick: onNodeRun,
    });
  }

  function routerRender() {
    return (
      <Switch>
        <Route exact path={["/klient/plan", "/klient/plan/output"]} render={assetSummaryRender} />
        <Route exact path="/klient/plan/output/assetsummary" render={assetSummaryRender} />
        0<Route exact path="/klient/plan/output/cost" render={costRender} />
        <Route exact path="/klient/plan/output/activity" render={activityRender} />
        {/* <Route exact path="/klient/plan/output/risk" render={riskRender} />
        <Route exact path="/klient/plan/output/condition" render={conditionRender} />
        <Route exact path="/klient/plan/output/asset" render={nodeRender} />
        <Route exact path="/klient/plan/output/map" render={mapRender} /> */}
        {/* <Route exact path="/klient/plan/output/installation" render={installationRender} /> */}
        <Redirect to="/" />
      </Switch>
    );
  }

  function assetSummaryRender() {
    return <AssetSummary></AssetSummary>;
  }

  function costRender() {
    return <Cost type={2}></Cost>;
  }

  function activityRender() {
    return <Activity></Activity>;
  }

  function tabRender() {
    return <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView}></Tabs>;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>{tabRender()}</div>
      <div className={styles.progress}>
        <Hierarchy
          addButton={false}
          allowEditing={false}
          allowDragging={false}
          multiSelect={true}
          onSelectionChanged={onSelectionChanged}
          onToolbarPreparing={onToolbarPreparing}
          onContextMenuPreparing={onContextMenuPreparing}
          showScenarioName={true}
          countUrl={`${url}/engine/output/node-count`}
          showFilter={true}
          showCount={true}
          filterFields={[
            "Class",
            "Type",
            "Size",
            "Material",
            "Manufacturer",
            "Status",
            "Ownership",
            "Group",
            "Tag",
            "Risk",
            "Condition",
            "CoF",
            "Install Year",
          ]}
          filterType={1}
        >
          <JobPanel name={"RUN"}>{routerRender()}</JobPanel>
        </Hierarchy>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
    clearHome: () => dispatch(actions.clearHome()),
  };
};

export default connect(null, mapDispatchToProps)(Output);
