import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./app.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component(props) {
  // hooks
  const [state, setState] = useState(null);
  const location = useLocation();

  useEffect(() => {
    var path = location.pathname.split("/")[3];
    var app = props.app.find((i) => i.name === path);
    if (app) {
      (async () => {
        var jwt = await request({ url: `${url}/app/jwt/${app.id}` });
        var win = window.open(`${app.url}?id=${app.id}&jwt=${jwt}`, "_blank");
        win && win.focus();
        setState(app);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // render

  return state && <h1 className={styles.main}>{state.description}</h1>;
}

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

export default connect(mapStateToProps, null)(Component);
