import React, { useState, useEffect, useRef } from "react";
import styles from "./gantt.module.scss";
import Gantt, { Tasks, Column, Editing, Validation, ScaleTypeRange, Toolbar, Item } from "devextreme-react/gantt";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { url } from "config.js";
import { currencyFormat, withMemo } from "helper/global";
import ProjectSnapshot from "../../project-snapshot/project-snapshot";
import CustomStore from "devextreme/data/custom_store";
import { connect } from "react-redux";

function Component(props) {
  //
  // hooks

  const [state, setState] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (props.projectGroupId === null) {
      setState(null);
      return;
    }
    (async () => {
      setState(null);
      setProject(null);
      const result = await request({
        url: `${url}/projectoutput/gantt`,
        params: { projectGroupId: props.projectGroupId },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectGroupId]);

  // functions

  function getTimeEstimate(task) {
    return Math.round(Math.abs(new Date(task.startDate) - new Date(task.endDate)) / 36e5 / 24);
  }

  function onTaskClick(e) {
    if (e.data.id > 0) {
      setProject(e.data);
    }
  }

  function onBackButtonClick() {
    setProject(null);
  }

  // render

  function toolTipRender(e) {
    var task = state.find((i) => i.id === e.id);
    return (
      <div className={styles.tooltip}>
        <div className={styles.tooltip_title}>{task.name}</div>
        <div className={styles.tooltip_row}>
          <span> Start Date: </span>
          {task.startDate.substring(0, 10)}
        </div>
        <div className={styles.tooltip_row}>
          <span> End Date: </span>
          {task.endDate.substring(0, 10)}
        </div>
        <div className={styles.tooltip_row}>
          <span> Duration: </span>
          {getTimeEstimate(task)}
          <span> days </span>
        </div>
        {/* <div className={styles.tooltip_row}>
          <span> Cost: </span>
          {currencyFormat(task.cost)}
        </div> */}
        <div className={styles.tooltip_row}>
          <span> Amount Funded: </span>
          {currencyFormat(task.amountFunded)}
        </div>
        <div className={styles.tooltip_row}>
          <span> Amount Spent: </span>
          {currencyFormat(task.amountSpent)}
        </div>
        <div className={styles.tooltip_row}>
          <span> Progress: </span>
          {Math.round(task.progress, 2)}
          <span> % </span>
        </div>
      </div>
    );
  }

  function gridRender() {
    return <ProjectSnapshot projectId={project.id} onBackButtonClick={onBackButtonClick} />;
  }

  function loadingRender() {
    return (
      <div className={styles.loading}>
        <LoadIndicator />
      </div>
    );
  }

  function ganttRender() {
    return (
      <div className={styles.main}>
        <Gantt
          allowSelection={false}
          scaleType="quarters"
          taskListWidth={0}
          showRowLines={false}
          taskTooltipContentRender={toolTipRender}
          onTaskClick={onTaskClick}
          startDateRange={new Date(props.firstYear, 1, 1)}
          endDateRange={new Date(props.lastYear, 1, 1)}
        >
          <ScaleTypeRange min="quarters" max="years" />
          <Tasks dataSource={state} titleExpr="name" startExpr="startDate" endExpr="endDate" />
          <Column dataField="title" caption="Subject" width={300} visible={false} />
          <Validation autoUpdateParentTasks={true} />
          <Editing enabled={false} />
          <Toolbar>
            <Item name="zoomIn" />
            <Item name="zoomOut" />
          </Toolbar>
        </Gantt>
      </div>
    );
  }

  return state ? (project ? gridRender() : ganttRender()) : loadingRender();
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
