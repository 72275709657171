import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styles from "./user-setting.module.scss";
import Tabs from "components/tabs/tabs";
import { selectedTabItem } from "helper/global";
import Profile from "./profile/profile";
import { dict } from "helper/global";

function Component(props) {
  // tabItems

  const tabItems = [
    {
      text: dict("Profile"),
      icon: "fad fa-city",
      path: "/profile",
    },
    // {
    //   text: "Dictionary",
    //   icon: "fad fa-spell-check",
    //   path: "/dictionary",
    // },
    // {
    //   text: dict("Preference"),
    //   icon: "fad fa-asterisk",
    //   path: "/preference",
    // },
  ];

  // event handlers

  function changeView(e) {
    props.history.push(`/klient/user/setting${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/user/setting", "/klient/user/setting/profile"]} component={Profile} />
          {/* <Route exact path="/klient/user/setting/preference" component={Preference} /> */}
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
