import React, { useRef } from "react";
import styles from "./cost-component.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { addFilter } from "helper/global";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { RequiredRule } from "devextreme-react/validator";
import { currency, withMemo } from "helper/global";

function Component(props) {
  //
  // useRef

  const exporting = useRef(false);

  // store

  const costStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.costId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({
              url: `${url}/costcomponent/costgroup/${props.costGroupId}/download`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/costcomponent`,
              loadOptions: addFilter(loadOptions, "and", ["costId", "=", props.costId]),
            });
      },
      insert: (data) => {
        data.costId = props.costId;
        return request({ url: `${url}/costcomponent`, method: "Post", data: data });
      },
      update: (_, data) => {
        data.activityId = props.activityId;
        return request({ url: `${url}/costcomponent`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/costcomponent/${id}`, method: "Delete" });
      },
    }),
  };

  const salaryStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/salary`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/salary/${id}` });
      },
    }),
  };

  const costTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/costType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/costType/${id}` });
      },
    }),
  };

  // const fundStore = {
  //   store: new CustomStore({
  //     key: "id",
  //     useDefaultSearch: true,
  //     load: (loadOptions) => {
  //       return request({ url: `${url}/fund`, loadOptions: loadOptions });
  //     },
  //     byKey: function (id) {
  //       return request({ url: `${url}/fund/${id}` });
  //     },
  //   }),
  // };

  const costCenterStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/costcenter`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/costcenter/${id}` });
      },
    }),
  };

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_COSTCOMPONENT", url: `${url}/costcomponent/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("cost", "visible", true);
    e.component.columnOption("basis", "visible", false);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("cost", "visible", false);
    e.component.columnOption("basis", "visible", true);
    exporting.current = false;
  }

  // render

  function costComponentRender() {
    return (
      <div className={styles.main}>
        <Grid
          title={"Components"}
          dataSource={costStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowExpanding={true}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Cost"} dataField={"cost"} width={200} visible={false} formItem={{ visible: false }} />
          <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
            <Lookup dataSource={costTypeStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column caption={"Salary"} dataField={"salaryId"} calculateDisplayValue="salary" width={200}>
            <Lookup dataSource={salaryStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"Basis"}
            dataField={"basis"}
            allowEditing={false}
            allowFiltering={false}
            width={100}
            formItem={{ visible: false }}
          />
          <Column
            caption={"Value"}
            dataField={"value"}
            dataType="number"
            width={100}
            format={`${currency()}#,##0.##`}
            formItem={{ editorOptions: { format: `${currency()}#,##0.##` }, showClearButton: true }}
          >
            <RequiredRule />
          </Column>
          <Column caption={"Cost Center"} dataField={"costCenterId"} calculateDisplayValue="costCenter" width={200}>
            <Lookup dataSource={costCenterStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column visible={true} formItem={{ itemType: "empty" }} />
        </Grid>
      </div>
    );
  }

  return <ProgressPanel name={"UPLOAD_COSTCOMPONENT"}>{costComponentRender()}</ProgressPanel>;
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["costId", "costGroupId"]));
