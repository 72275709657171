import React, { useEffect, useMemo } from "react";
import { LogLevel } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import Loading from "components/loading/loading";
import Login from "../login/login";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { status } from "redux/enums/auth";
import { getAuthInfo } from "helper/global";
import LogoutForm from "./logoutform/logoutform";
import Error from "../error/error";
import Loginform from "./loginform/loginform";

function Component(props) {
  //
  // hooks
  useEffect(() => {
    if (props.status === status.UnAuthenticated) props.session();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  const authInfo = getAuthInfo();

  const msalConfig = {
    auth: {
      clientId: authInfo.validAudience,
      authority: `https://login.microsoftonline.com/${authInfo.tenantId}`,
      redirectUri: "https://iris.kayugasolution.com",
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };
  const instance = useMemo(() => {
    return new PublicClientApplication(msalConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  function render() {
    switch (props.status) {
      case status.UnAuthenticated:
        return <Loading />;
      case status.InSession:
      case status.IsAuthenticated:
        return <Login />;
      case status.SessionDenied:
        return (
          <MsalProvider instance={instance}>
            <Loginform />
          </MsalProvider>
        );
      case status.LoggedOut:
        return (
          <MsalProvider instance={instance}>
            <LogoutForm />
          </MsalProvider>
        );
      case status.LoginDenied:
        return <Error text={"UnAuthorized Access..!"} />;
      default:
        return <Error />;
    }
  }

  return render();
}

function mapStateToProps(state) {
  return {
    status: state.auth.status,
  };
}

function mapsDispatchToProps(dispatch) {
  return {
    session: (state) => dispatch(actions.session(state)),
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
