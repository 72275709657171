import React from "react";
import styles from "./jsonviewer.module.scss";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";
import { Popup } from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";

function Component(props) {
  return (
    <div>
      <Popup
        visible={props.visible}
        onHiding={props.onHiding}
        showCloseButton={true}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showTitle={true}
        title={"Audit Log"}
        width={700}
        height={700}
        deferRendering={true}
      >
        <ScrollView>
          <div className={styles.main}>
            <div className={styles.container1}>
              <div className={styles.caption}>Raw</div>
              <JSONPretty id="json-pretty" data={props.read}></JSONPretty>
            </div>
            <div className={styles.container2}>
              <div className={styles.caption}>Mapped</div>
              <JSONPretty id="json-pretty" data={props.mapped}></JSONPretty>
            </div>
          </div>
        </ScrollView>
      </Popup>
    </div>
  );
}

export default Component;
