import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./lookup.module.scss";
import { selectedTabItem } from "helper/global";
import Project from "./project-group/project-group";
import ProjectStatus from "./project-status/project-status";
import { dict } from "helper/global";

function Lookup(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Project"),
      icon: "fad fa-user-hard-hat",
      path: "/project",
    },
    {
      text: dict("Project Status"),
      icon: "fad fa-bullhorn",
      path: "/projectstatus",
    },
  ];

  // functions

  function changeView(e) {
    props.history.push(`/klient/plan/lookup${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs
          tabItems={tabItems}
          showNavButtons={true}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route
            exact
            path={[
              "/klient/plan",
              "/klient/plan/lookup",
              "/klient/plan/lookup/project",
            ]}
            component={Project}
          />
          <Route
            exact
            path="/klient/plan/lookup/projectstatus"
            component={ProjectStatus}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Lookup;
