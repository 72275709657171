import React, { useState, useEffect } from "react";
import styles from "./logout.module.scss";
import { faRedo } from "@fortawesome/pro-duotone-svg-icons";
import { faSmile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'devextreme-react/button';
import Loading from "components/loading/loading";

function Component() {
  //
  // hooks
  const [state, setState] = useState(false);
  useEffect(() => {
    setState(true);
  }, []);

  // render

  return !state ? (
    <Loading />
  ) : (
    <div className={styles.main}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faSmile} />
      </div>
      <div className={styles.title}>Logged out..!</div>
      <div className={styles.text}>See you again...</div>
      <Button
        text={"Home"}
        type={"normal"}
        stylingMode="outlined"
        width={100}
        onClick={() => {
          window.location.reload(false);
        }}
      >
        <div className={styles.button}>
          <div className={styles.button_text}>Refresh</div>
          <div className={styles.button_icon}>
            <FontAwesomeIcon icon={faRedo} />
          </div>
        </div>
      </Button>
    </div>
  );
}

export default Component;
