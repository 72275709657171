import React from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  // stores

  const aliasStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/alias/node/${props.data.id}`, loadOptions: loadOptions });
      },
      insert: (data) => {
        data.nodeId = props.data.id;
        return request({ url: `${url}/alias`, method: "Post", data: data });
      },
      update: (key, data) => {
        return request({ url: `${url}/alias`, method: "Put", data: data });
      },
      remove: (key) => {
        return request({ url: `${url}/alias/${key}`, method: "Delete" });
      },
    }),
  };

  const aliasTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/aliasType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/aliasType/${id}` });
      },
    }),
  };

  return (
    <Grid title={"Aliases"} showTitle={false} dataSource={aliasStore} allowDownloading={false} allowExpanding={false}>
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" allowFiltering={true}>
        <Lookup dataSource={aliasTypeStore} valueExpr={"id"} displayExpr="name" />
        <RequiredRule />
      </Column>
      <Column caption={"Value"} dataField={"value"} allowFiltering={true} width={150}></Column>
    </Grid>
  );
}

export default Component;
