import React, { useEffect, useState } from "react";
import styles from "./summary.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { dict } from "helper/global";
import ImageBox from "components/image-box/image-box";

function Component(props) {
  //
  // hooks

  const [state, setState] = useState(false);

  useEffect(
    () => {
      if (!props.assetId) return;
      (async () => {
        try {
          var result = await request({ url: `${url}/node/summary/${props.nodeId}` });
          // var p2 = request({ url: `${url}/assetimage/asset/${props.assetId}` });
          setState(result);
        } catch (error) {
          console.log(error);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.assetId]
  );

  // render

  const editorOptions = {
    stylingMode: "filled",
    height: "40px",
    readOnly: true,
  };

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function summaryRender() {
    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <Form formData={state} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
            <GroupItem colCount={4} cssClass={"theme-form"}>
              <GroupItem>
                <SimpleItem dataField="name" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("AssetId")} />
                </SimpleItem>
                <SimpleItem dataField="description" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Description")} />
                </SimpleItem>
                <SimpleItem dataField="notes" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Notes")} />
                </SimpleItem>
                <SimpleItem dataField="parent" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Parent")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField={dict("location")} colSpan={1} editorOptions={editorOptions}>
                  <Label text="Location" />
                </SimpleItem>
                <SimpleItem dataField="status" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Status")} />
                </SimpleItem>
                <SimpleItem dataField="ownership" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Ownership")} />
                </SimpleItem>
                <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Install Year")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="class" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Class")} />
                </SimpleItem>
                <SimpleItem dataField="type" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Type")} />
                </SimpleItem>
                <SimpleItem dataField="size" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Size")} />
                </SimpleItem>
                <SimpleItem dataField="material" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Material")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem>
                <SimpleItem dataField="manufacturer" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Manufacturer")} />
                </SimpleItem>
                <SimpleItem dataField="model" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Model")} />
                </SimpleItem>
                <SimpleItem dataField="count" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Count")} />
                </SimpleItem>
                <SimpleItem dataField="value" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Value")} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
        <div className={styles.gallery}>
          <ImageBox url={`${url}/assetimage/asset/${props.assetId}/all`} />
        </div>
      </div>
    );
  }
  return state ? summaryRender() : loadingRender();
}

export default Component;
