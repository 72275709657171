import React, { useEffect, useRef, useContext, useMemo } from "react";
import styles from "./activity-map.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import * as gis from "helper/gis";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { useImmerReducer } from "use-immer";
import Summary from "../summary/summary";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
import { dict } from "helper/global";

function reducer(draft, action) {
  switch (action.type) {
    case "loaded":
      draft.loading = false;
      return;
    case "loading":
      draft.loading = true;
      return;
    case "render":
      draft.render = !draft.render;
      break;
    case "showSummary":
      draft.activitySnapshotId = action.payload.activitySnapshotId;
      draft.assetId = action.payload.assetId;
      return;
    case "hideSummary":
      draft.activitySnapshotId = null;
      draft.assetId = null;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // constants
  const fields = [
    {
      name: "id",
      alias: "Id",
      type: "integer",
    },
    {
      name: "name",
      alias: "Name",
      type: "string",
    },
    {
      name: "riskRating",
      alias: "Risk Rating",
      type: "string",
    },
    {
      name: "conditionRating",
      alias: "Condition Rating",
      type: "string",
    },
    {
      name: "activitySnapshotId",
      alias: "ActivitySnapshotId",
      type: "integer",
    },
    {
      name: "assetId",
      alias: "AssetId",
      type: "integer",
    },
    {
      name: "activity",
      alias: "Activity",
      type: "string",
    },
    {
      name: "selected",
      alias: "Selected",
      type: "integer",
    },
    {
      name: "visible",
      alias: "Visible",
      type: "integer",
    },
  ];

  // hooks

  const initialState = {
    data: [],
    loading: false,
    activitySnapshotId: null,
    assetId: null,
    render: false,
  };

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  const mapRef = useRef();
  const firstRender = useRef(true);

  // useEffect

  useEffect(
    function () {
      if (firstRender.current) return;
      (async () => {
        gis.clear();
        await getData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.projectId]
  );

  useEffect(
    function () {
      (async () => {
        let p1 = request({ url: `${url}/conditionrating` });
        let p2 = request({ url: `${url}/riskrating` });
        let p3 = request({ url: `${url}/activity` });
        let [conditionRatings, breRatings, activities] = await Promise.all([p1, p2, p3]);

        let ratings = [
          {
            field: "conditionRating",
            ratings: conditionRatings.data,
            selected: false,
          },
          {
            field: "riskRating",
            ratings: breRatings.data,
            selected: true,
          },
          {
            field: "activity",
            ratings: activities.data,
            selected: true,
          },
        ];
        await gis.initialize(mapRef, fields, ratings, "riskRating", null, onClearSelection, onClick, true, true);
        await getData();
        firstRender.current = false;
      })();

      return function () {
        gis.dispose();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // functions

  async function getData() {
    try {
      dispatch({ type: "loading" });
      gis.indicator(true);
      let layers = await request({
        url: `${url}/projectsnapshot/gislayers`,
        params: { projectSnapshotId: props.projectId },
      });
      var assets = null;
      if (!layers.every((i) => i.isRef)) {
        assets = await request({
          url: `${url}/projectactivitysnapshot/gis`,
          params: {
            projectSnapshotId: props.projectId,
          },
        });
      }
      await gis.setLayers(layers, assets);
    } catch (error) {
      console.log("getData: ", error);
    } finally {
      dispatch({ type: "loaded" });
    }
  }

  // event handler

  async function onClick(e, attr) {
    dispatch({ type: "showSummary", payload: attr });
  }

  function onClearSelection() {
    dispatch({ type: "hideSummary" });
  }

  // render

  const mapRender = useMemo(() => {
    return (
      <div className={styles.map} ref={mapRef}>
        <div id={"gis_indicator"} className={styles.loading} hidden>
          <LoadIndicator />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function summaryRender() {
    return <Summary activityId={state.activitySnapshotId} assetId={state.assetId} />;
  }

  return (
    props.projectId && (
      <div className={styles.main}>
        {mapRender}
        {state.activitySnapshotId && (
          <div className={styles.summary}>
            <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
              <TabItem title={dict("Summary")} render={summaryRender} />
            </TabPanel>
          </div>
        )}
      </div>
    )
  );
}

export default Component;
