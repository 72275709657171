import React, { useEffect } from "react";
import { connect } from "react-redux";
import Login from "../login/login";
import Logout from "../logout/logout";
import { status } from "redux/enums/auth";
import LoginForm from "./loginform/loginform";
import * as actions from "redux/actions/index";
import Error from "../error/error";
import Loading from "components/loading/loading";

function Component(props) {
  //
  // hooks
  useEffect(() => {
    if (props.status === status.UnAuthenticated) props.session();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status]);

  function render() {
    switch (props.status) {
      case status.UnAuthenticated:
        return <Loading />;
      case status.InSession:
      case status.IsAuthenticated:
        return <Login />;
      case status.LoginDenied:
      case status.SessionDenied:
        return <LoginForm />;
      case status.LoggedOut:
        return <Logout />;
      default:
        return <Error />;
    }
  }

  return render();
}

function mapStateToProps(state) {
  return {
    status: state.auth.status,
  };
}

function mapsDispatchToProps(dispatch) {
  return {
    session: (state) => dispatch(actions.session(state)),
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
