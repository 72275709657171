import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";
// import { notify } from "helper/global";

export const _loadOrg = (result) => {
  return {
    type: actionTypes.ORG_LOAD,
    ...result,
  };
};

export const loadOrg = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/departmentsetting` });
      dispatch(_loadOrg(result));
    } catch (error) {
      throw Error("Unable to load organization settings");
    }

    return "done";
  };
};

// export function saveOrg(data) {
//   return async (dispatch) => {
//     try {
//       await request({ url: `${url}/organizationsetting`, method: "Put", data: data });
//       dispatch(loadOrg(data));
//     } catch (error) {
//       notify("Unable to save organization settings", "error");
//     }
//   };
// }
