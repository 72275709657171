import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import styles from "./classification.module.scss";
import Class from "./class/class.js";
import Type from "./type/type.js";
import Size from "./size/size.js";
import Material from "./material/material.js";
import Manufacturer from "./manufacturer/manufacturer";
import { selectedTabItem } from "helper/global";
import { dict } from "helper/global";

function Classification(props) {
  //constants
  const tabItems = [
    {
      text: dict("Class"),
      icon: "fad fa-users-class",
      path: "/class",
    },
    {
      text: dict("Type"),
      icon: "fad fa-shapes",
      path: "/type",
    },
    {
      text: dict("Size"),
      icon: "fad fa-ruler-horizontal",
      path: "/size",
    },
    {
      text: dict("Material"),
      icon: "fad fa-atom-alt",
      path: "/material",
    },
    {
      text: dict("Manufacturer"),
      icon: "fad fa-industry",
      path: "/manufacturer",
    },
  ];

  //functions

  function changeView(e) {
    props.history.push(`/klient/catalog/classification${e.path}`);
  }

  //render

  return (
    <div className={styles.main}>
      <div className={`${styles.tabs} `}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={changeView}
          className={"dx-theme-border-color"}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/klient/catalog", "/klient/catalog/classification"]} component={Class} />
          <Route exact path="/klient/catalog/classification/class" component={Class} />
          <Route exact path="/klient/catalog/classification/type" component={Type} />
          <Route exact path="/klient/catalog/classification/size" component={Size} />
          <Route exact path="/klient/catalog/classification/material" component={Material} />
          <Route exact path="/klient/catalog/classification/manufacturer" component={Manufacturer} />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Classification;
