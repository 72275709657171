import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { addFilter, withMemo, currency } from "helper/global";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // useRef
  const exporting = useRef(false);

  // stores

  const activityOverrideStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (exporting.current) {
          if (!props.selectedRowsData.length === 0) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/activityOverride/download`,
            loadOptions: loadOptions,
            params: { ids: props.selectedRowsData.map((i) => i.id) },
          });
        } else {
          if (!props.scenarioNodeId) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/activityOverride`,
            loadOptions: addFilter(loadOptions, "and", ["scenarioNodeId", "=", props.scenarioNodeId]),
          });
        }
      },
      insert: (data) => {
        data.scenarioNodeId = props.scenarioNodeId;
        return request({ url: `${url}/activityOverride`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/activityOverride`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/activityOverride/${id}`, method: "Delete" });
      },
    }),
  };

  const activityStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/activity/scenarionode/${props.scenarioNodeId}`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activity/${id}` });
      },
    }),
  };

  const activityOverrideType = [
    { id: 0, name: "Add" },
    { id: 1, name: "Remove" },
  ];

  // event Handler

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITYOVERRIDE", url: `${url}/activityoverride/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("node", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("node", "visible", false);
    e.component.columnOption("strategy", "visible", false);
    exporting.current = false;
  }

  // render

  // function dropDownRender(data) {
  //   return (
  //     <DropDown data={data} showColumnHeaders={true} height={250} width={500}>
  //       <Column dataField="name"> </Column>
  //       <Column dataField="type"> </Column>
  //     </DropDown>
  //   );
  // }

  const activityOverrideRender = () => {
    return (
      <Grid
        title={"Activity Override"}
        showTitle={false}
        dataSource={activityOverrideStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={true}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} sortOrder={"desc"} width={100} formItem={{ visible: false }} />
        <Column caption={"Operation"} dataField={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
        <Column caption={"AssetId"} dataField={"node"} visible={false} width={200} formItem={{ visible: false }} />
        <Column caption={"Strategy"} dataField={"strategy"} visible={false} width={200} formItem={{ visible: false }} />
        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
        <Column caption={"Activity Name"} dataField={"activityName"} width={200} allowEditing={false} formItem={{ visible: false }} />
        <Column caption={"Activity Type"} dataField={"activityId"} calculateDisplayValue="activityType" width={200}>
          <Lookup dataSource={activityStore} valueExpr={"id"} displayExpr="type" />
          <RequiredRule />
        </Column>
        <Column caption={"Type"} dataField={"typeId"} width={100}>
          <Lookup dataSource={activityOverrideType} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Year"} dataField={"year"} dataType={"number"} width={100}>
          <RequiredRule />
        </Column>
        <Column
          caption={"Cost"}
          dataField={"cost"}
          dataType={"number"}
          width={100}
          format={`${currency()}#,##0.##`}
          formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
        />

        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  };

  // return <ProgressPanel processId={UPLOAD_ACTIVITYOVERRIDE}>{activityOverrideRender()}</ProgressPanel>;
  return <ProgressPanel name={"UPLOAD_ACTIVITYOVERRIDE"}>{activityOverrideRender()}</ProgressPanel>;
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["scenarioNodeId", "render"]));
