import React from "react";
import styles from "./summary.module.scss";
import { url } from "config.js";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { dict, currency } from "helper/global";
import ImageBox from "./image-box/image-box";

function Component(props) {
  // render

  const editorOptions = { stylingMode: "filled", height: "45px", readOnly: true };

  return (
    <div className={styles.main}>
      <div className={styles.upper}>
        <Form formData={props.data} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
          <GroupItem colCount={3} cssClass={"theme-form"}>
            <SimpleItem dataField="name" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("AssetId")} />
            </SimpleItem>
            <SimpleItem dataField="description" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("Description")} />
            </SimpleItem>
            <SimpleItem dataField={"status"} colSpan={1} editorOptions={editorOptions}>
              <Label text="Status" />
            </SimpleItem>
            <SimpleItem dataField={"class"} colSpan={1} editorOptions={editorOptions}>
              <Label text="Class" />
            </SimpleItem>
            <SimpleItem dataField="installYear" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("Install Year")} />
            </SimpleItem>
            <SimpleItem dataField="potentialUsefulLife" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("Potential Useful Life")} />
            </SimpleItem>
            <SimpleItem dataField="riskRating" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("Risk Rating")} />
            </SimpleItem>
            <SimpleItem dataField="conditionRating" colSpan={1} editorOptions={editorOptions}>
              <Label text={dict("ConditionRating")} />
            </SimpleItem>
            <SimpleItem
              dataField={dict("replacementCost")}
              colSpan={1}
              editorType={"dxNumberBox"}
              editorOptions={{ ...editorOptions, format: `${currency()}#,###` }}
            >
              <Label text="Replacement Cost" />
            </SimpleItem>
          </GroupItem>
        </Form>
      </div>
      {/* <div className={styles.lower}>
        <div className={styles.form}>
          <Form formData={props.data} labelLocation={"top"} minColWidth={200} showColonAfterLabel={true} showRequiredMark={false}>
            <GroupItem colCount={1} cssClass={"theme-form"}>
              <GroupItem>
                <SimpleItem dataField="riskRating" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("Risk Rating")} />
                </SimpleItem>
                <SimpleItem dataField="conditionRating" colSpan={1} editorOptions={editorOptions}>
                  <Label text={dict("ConditionRating")} />
                </SimpleItem>
                <SimpleItem
                  dataField={dict("replacementCost")}
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{ ...editorOptions, format: `${currency()}#,###` }}
                >
                  <Label text="Replacement Cost" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </Form>
        </div>
        <div className={styles.gallery}>
          <ImageBox url={`${url}/assetimage/asset/${props.data.assetId}/all`} />
        </div>
      </div> */}
      <div className={styles.lower}>
        <ImageBox url={`${url}/assetimage/asset/${props.data.assetId}/all`} />
      </div>
    </div>
  );
}

export default Component;
