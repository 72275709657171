import * as actionTypes from "../actions/actionTypes";

export const reducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.APP_LOAD:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
