import React from "react";
import styles from "./error.module.scss";
import { faRedo } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'devextreme-react/button';
import { connect } from "react-redux";

function Component(props) {
  return (
    <div className={styles.main}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </div>
      <div className={styles.title}>Oops..!</div>
      <div className={styles.text}>{props.text || "Something went wrong..."}</div>
      <div className={styles.error}>{`${props.error || "None"}`  }</div>
      <Button
        text={"Home"}
        type={"normal"}
        stylingMode="outlined"
        width={100}
        onClick={() => {
          window.location.reload(false);
        }}
      >
        <div className={styles.button}>
          <div className={styles.button_text}>Refresh</div>
          <div className={styles.button_icon}>
            <FontAwesomeIcon icon={faRedo} />
          </div>
        </div>
      </Button>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    error: state.auth.error,
  };
}

export default connect(mapStateToProps, null)(Component);
