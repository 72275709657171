import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadPreference = (result) => {
  return {
    type: actionTypes.PREFERENCE_LOAD,
    ...result,
  };
};

export const loadPreference = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/preference` });
      dispatch(_loadPreference(result));
    } catch (error) {
      throw Error("Unable to load preferences");
    }
    return "done";
  };
};
