import React from "react";
import styles from "./loading.module.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";

function Component() {
  return (
    <div className={styles.main}>
      <div className={styles.logo}>
        <img src={require("assets/icons/logogray.png")} width="75" height="48" alt="" />
      </div>
      <div className={styles.logo}>
        <LoadIndicator indicatorSrc={require("assets/gifs/5.gif")} height={15} width={120} />
      </div>
    </div>
  );
}

export default Component;
