import React, { useState, useMemo, useEffect } from "react";
import styles from "./datalog.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { Column } from "devextreme-react/data-grid";
import JsonViewer from "components/jsonviewer/jsonviewer";
import { withMemo, notify, useRefState } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // Hooks

  const [state, setState] = useState({
    visible: false,
    mapped: {},
    read: {},
  });

  // const initialState = { id: null };
  // const [selection, setSelection] = useRefState(initialState);

  // event Handlers

  function onViewChanges(e) {
    setState({
      mapped: e.row.data.mapped,
      read: e.row.data.read,
      visible: true,
    });
  }

  async function onRestore(e) {
    var result = await confirm("<i>Do you want to restore?</i>", "Confirm");
    if (result) {
      try {
        props.run({
          name: "KONNECT",
          url: `konnect/restore/datalog/${e.row.data.id}`,
        });
      } catch (error) {
        notify("error", error);
      }
    }
  }

  // functions

  return (
    <div className={styles.main}>
      {state.visible && (
        <JsonViewer visible={state.visible} mapped={state.mapped} read={state.read} onHiding={() => setState({ visible: false })} />
      )}
      <DataLog readLogId={props.readLogId} onViewChanges={onViewChanges} onRestore={onRestore} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const DataLog = withMemo(
  (props) => {
    //
    // store

    const dataLogStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.readLogId) return Promise.resolve({ data: [] });
          return request({
            url: `konnect/datalog/readlog/${props.readLogId}`,
            loadOptions: loadOptions,
          });
        },
      }),
    };

    // event Handlers

    function onCellPrepared(e) {
      if (e.rowType === "data" && e.column.dataField && e.column.dataField === "status") {
        if (e.data.statusId === 0) e.cellElement.style.color = "#98c21f";
        else e.cellElement.style.color = "orange";
      }
    }

    // render

    return (
      <Grid
        title={"Data Log"}
        dataSource={dataLogStore}
        // selection={{ mode: "single" }}
        allowExpanding={true}
        showButtons={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        onCellPrepared={onCellPrepared}
        // onSelectionChanged={props.onSelectionChanged}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} />
        <Column caption={"AssetId"} dataField={"name"} width={300} />
        <Column caption={"Read"} dataField={"read"} visible={false} width={200} />
        <Column caption={"Mapped"} dataField={"mapped"} visible={false} width={200} />
        <Column caption={"Archived"} dataField={"archived"} visible={false} width={200} />
        <Column caption={"Status"} dataField={"status"} fixed={true} fixedPosition={"right"} />
        <Column
          caption={"View Changes"}
          type={"buttons"}
          buttons={[
            {
              hint: "Detail",
              icon: "detailslayout",
              visible: true,
              onClick: props.onViewChanges,
            },
            {
              hint: "Restore",
              icon: "revert",
              onClick: props.onRestore,
            },
          ]}
          width={125}
          fixed={true}
        />
      </Grid>
    );
  },
  ["readLogId"]
);
