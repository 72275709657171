import React, { useState, useRef } from "react";
import styles from "./project.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import { url } from "config.js";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { addFilter, withMemo, currency } from "helper/global";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  //
  // stores

  const projectStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.projectGroupId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({
              url: `${url}/project/download`,
              loadOptions: loadOptions,
              params: { projectGroupId: props.projectGroupId },
            })
          : request({
              url: `${url}/project/byprojectgroup`,
              loadOptions: loadOptions,
              params: { projectGroupId: props.projectGroupId },
            });
      },
      insert: (data) => {
        return request({
          url: `${url}/project`,
          method: "Post",
          data: { ...data, groupId: props.projectGroupId },
        });
      },
      update: (_, data) => {
        return request({
          url: `${url}/project`,
          method: "Put",
          data: data,
        });
      },
      remove: (id) => {
        return request({ url: `${url}/project/${id}`, method: "Delete" });
      },
    }),
  };

  const projectGroupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({
          url: `${url}/projectgroup`,
          loadOptions: addFilter(loadOptions, "and", ["internal", "=", false]),
        });
      },
      byKey: function (id) {
        return request({ url: `${url}/projectgroup/${id}` });
      },
    }),
  };

  const projectStatusStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({
          url: `${url}/projectstatus`,
          loadOptions: loadOptions,
        });
      },
      byKey: function (id) {
        return request({ url: `${url}/projectstatus/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({
      name: "UPLOAD_PROJECT",
      url: `${url}/project/upload/file/${fileId}`,
    });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("groupId", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("groupId", "visible", false);
    exporting.current = false;
  }

  return (
    <ProgressPanel name={"UPLOAD_PROJECT"}>
      <Grid
        title={"Project"}
        dataSource={projectStore}
        selection={{ mode: "single" }}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={true}
        onInitNewRow={(e) => {
          e.data.projectGroupId = props.projectGroupId || null;
        }}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} width={100} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          visible={false}
          formItem={{ visible: false }}
          width={100}
        />
        <Column caption={"Name"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"ProjectId"} dataField={"projectId"} width={150} />
        <Column
          caption={"Project Group"}
          dataField={"groupId"}
          visible={false}
          calculateDisplayValue="group"
          width={150}
          formItem={{ visible: false }}
        >
          <Lookup dataSource={projectGroupStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Project Status"} dataField={"statusId"} calculateDisplayValue="status" width={150}>
          <Lookup dataSource={projectStatusStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column
          caption={"Start Date"}
          dataField={"startDate"}
          dataType={"date"}
          format="MM/dd/yyyy"
          width={150}
          formItem={{ editorOptions: { showClearButton: true } }}
        />
        <Column
          caption={"End Date"}
          dataField={"endDate"}
          dataType={"date"}
          format="MM/dd/yyyy"
          width={150}
          formItem={{ editorOptions: { showClearButton: true } }}
        />
        {/* <Column
                caption={"Cost"}
                dataType="number"
                dataField={"cost"}
                format={`${currency()}#,###`}
                formItem={{ editorOptions: { format: `${currency()}#,###`, showClearButton: true } }}
                width={150}
              /> */}
        <Column
          caption={"Amount Funded"}
          dataField={"amountFunded"}
          dataType="number"
          format={`${currency()}#,###`}
          formItem={{
            editorOptions: {
              format: `${currency()}#,###`,
              showClearButton: true,
            },
          }}
          width={150}
        />
        <Column
          caption={"Amount Spent"}
          dataField={"amountSpent"}
          dataType="number"
          format={`${currency()}#,###`}
          formItem={{
            editorOptions: {
              format: `${currency()}#,###`,
              showClearButton: true,
            },
          }}
          width={150}
        />
        {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
        <Column
          caption={"Notes"}
          dataField={"notes"}
          // width={400}
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { height: 75 },
          }}
        />
      </Grid>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["projectGroupId"]));
