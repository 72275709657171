import { dict } from "helper/global";

export const navigation = [
  {
    text: dict("Home"),
    icon: "fad fa-home",
    path: "/home",
    id: "abcd",
  },
  {
    text: dict("Catalog"),
    icon: "fad fa-book-open",
    items: [
      {
        text: "Classification",
        path: "/catalog/classification",
        icon: "fad fa-mortar-pestle",
      },
    ],
  },
  {
    text: "Data",
    icon: "fad fa-database",
    items: [
      {
        text: "Lookup",
        path: "/data/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Asset",
        path: "/data/asset",
        icon: "fad fa-truck-pickup",
      },
      {
        text: "File",
        path: "/data/file",
        icon: "fad fa-file",
      },
    ],
  },
  {
    text: "Manage",
    icon: "fad fa-digging",
    visible: true,
    items: [
      {
        text: "Lookup",
        path: "/manage/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        path: "/manage/input",
        icon: "fad fa-keyboard",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/manage/output",
      },
    ],
  },
  {
    text: "Plan",
    icon: "fad fa-analytics",
    items: [
      {
        text: "Lookup",
        path: "/plan/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        icon: "fad fa-keyboard",
        path: "/plan/input",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/plan/output",
      },
      {
        text: "Project",
        icon: "fad fa-user-hard-hat",
        path: "/plan/project",
      },
    ],
  },
  {
    text: "Finance",
    icon: "fad fa-cash-register",
    items: [
      {
        text: "Lookup",
        path: "/finance/lookup",
        icon: "fad fa-caret-square-down",
      },
      {
        text: "Input",
        icon: "fad fa-keyboard",
        path: "/finance/input",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/finance/output",
      },
      {
        text: "Tools",
        icon: "fad fa-tools",
        path: "/finance/tools",
      },
    ],
  },
  {
    text: "Report",
    icon: "fad fa-book-open",
    visible: true,
    items: [
      {
        text: "Query",
        path: "/report/query",
        icon: "fad fa-table",
      },
    ],
  },
  {
    text: "Connect",
    icon: "fad fa-link",
    visible: true,
    items: [
      {
        text: "Input",
        icon: "fad fa-keyboard",
        path: "/connect/input",
      },
      {
        text: "Output",
        icon: "fad fa-chart-bar",
        path: "/connect/output",
      },
    ],
  },
  {
    text: "Admin",
    icon: "fad fa-users-crown",
    items: [
      {
        text: "Access",
        icon: "fad fa-user-lock",
        path: "/admin/access",
      },
      {
        text: "Settings",
        icon: "fad fa-cog",
        path: "/admin/setting",
      },

      {
        text: "Audit Logs",
        icon: "fad fa-book-open",
        path: "/admin/audit",
      },
    ],
  },
];
