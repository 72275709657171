import React from "react";
import styles from "./organization.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { Column } from "devextreme-react/data-grid";

function Organization(props) {
  // stores

  const organizationStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/organization`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/organization`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/auth/switch`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/organization/${id}`, method: "Delete" });
      },
      onModified: onLoaded,
    }),
  };

  // event handlers

  function onLoaded() {
    // props.loadOrg();
    // props.loadScenario();
    // props.clearHome();
    window.location.reload(false);
  }

  // render

  return (
    <div className={styles.main}>
      <Grid
        dataSource={organizationStore}
        title={"Organizations"}
        allowAdding={false}
        allowDeleting={false}
        allowDownloading={false}
        editingMode={"row"}
      >
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} width={200} allowEditing={false} sortOrder={"asc"} formItem={{ visible: false }} />
        <Column caption={"Description"} dataField={"description"} allowEditing={true} width={400} />
        <Column caption={"Active"} dataField={"active"} allowFiltering={false} width={100} />
        {/* <Column caption={"Address"} dataField={"address"} width={250} />
        <Column caption={"City"} dataField={"city"} width={150} />
        <Column caption={"Zip"} dataField={"zip"} width={100} />
        <Column caption={"State"} dataField={"state"} width={150}>
          <Lookup dataSource={states} valueExpr={"abbreviation"} displayExpr="name" />
        </Column>
        <Column caption={"Country"} dataField={"country"} width={200}>
          <Lookup dataSource={countries} valueExpr={"code"} displayExpr="name" />
        </Column>
        <Column caption={"Phone"} dataField={"phone"} width={150} />
        {/* <Column caption={'Fax'} dataField={'fax'} width={150} /> */}
        {/* <Column caption={"Website"} dataField={"websiteUrl"} width={200} /> */} */}
      </Grid>
    </div>
  );
}

function mapsDispatchToProps(dispatch) {
  return {
    loadOrg: () => dispatch(actions.loadOrg()),
    loadScenario: () => dispatch(actions.loadScenario()),
    clearHome: () => dispatch(actions.clearHome()),
  };
}

export default connect(null, mapsDispatchToProps)(Organization);
