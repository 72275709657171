import React, { useState, useRef } from "react";
import styles from "./output.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { Column } from "devextreme-react/data-grid";
import { withMemo, useRefState, notify } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
import ReadLog from "./readlog/readlog";
import Jobpanel from "components/jobpanel/jobpanel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import FileUploader from "components/csv-uploader/csv-uploader";

function Component(props) {
  // hooks

  const initialState = { id: null, typeId: null };
  const [state, setState] = useRefState(initialState);
  const [fileLoader, setFileLoader] = useState(false);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  async function onRun() {
    if (state.current.typeId === null) return;

    if (state.current.typeId == 2) {
      setFileLoader(true);
    } else {
      var result = await confirm("<i>Do you want to read from the selected source?</i>", "Confirm");
      if (result) {
        try {
          props.run({
            name: "KONNECT",
            url: `konnect/read/source/${state.current.id}`,
          });
        } catch (error) {
          notify("error", error);
        }
      }
    }
  }

  async function onUploaded(fileId) {
    setFileLoader(false);
    try {
      props.run({
        name: "KONNECT",
        url: `konnect/upload/source/${state.current.id}/file/${fileId}`,
      });
    } catch (error) {
      notify("error", error);
    }
  }

  function hideFileLoader() {
    setFileLoader(false);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.source}>
        <Source onSelectionChanged={onSelectionChanged} onRun={onRun} />
      </div>
      <div className={styles.container}>
        <Jobpanel name={"KONNECT"}>
          <FileUploader fileLoaderVisible={fileLoader} hideFileLoader={hideFileLoader} onUploaded={onUploaded} />
          {state && <ReadLog sourceId={state.current.id} />}
        </Jobpanel>
      </div>
    </div>
  );

  // return (
  //   <div className={`${styles.main} theme-datagrid`}>
  //     <FileUploader fileLoaderVisible={fileLoader} hideFileLoader={hideFileLoader} onUploaded={onUploaded} />
  //     <div className={styles.row}>
  //       <div className={`${styles.col} theme-datagrid`}>{gridRender()}</div>
  //     </div>
  //   </div>
  // );
}

const Source = withMemo((props) => {
  //
  // stores

  const sourceStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `konnect/source`, loadOptions: loadOptions });
      },
    }),
  };

  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
    props.onInitialized && props.onInitialized(e);
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Run",
        icon: "fas fa-play",
        onClick: props.onRun,
      },
    });
  }

  return (
    <List
      title={"Source"}
      allowDownloading={false}
      dataSource={sourceStore}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      showButtons={false}
      onInitialized={onInitialized}
      onToolbarPreparing={onToolbarPreparing}
      onSelectionChanged={onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} />
    </List>
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const mapDispatchToProps = (dispatch) => {
  return {
    run: (options) => dispatch(actions.startJob(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
