import React, { useState } from "react";
import styles from "./ticket.module.scss";
import TicketList from "./ticket-list/ticket-list";
import Message from "./message/message";

function Component() {
  //
  // hooks

  const initialState = { id: null };
  const [ticket, setTicket] = useState(initialState);
  const [render, setRender] = useState(0);

  // event handlers

  function onSelectionChanged(e) {
    setTicket(e.selectedRowsData[0] || initialState);
  }

  function onRefresh() {
    setRender((state) => state + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tickets}>
        <TicketList allowUpdating={true} allowDeleting={false} onSelectionChanged={onSelectionChanged} onRefresh={onRefresh} />
      </div>
      <div className={styles.messages}>{ticket.id && <Message ticket={ticket} render={render} />}</div>
    </div>
  );
}

export default Component;
