import React, { useRef } from "react";
import "./chart.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import Chart from "devextreme-react/chart";
import { Animation } from "devextreme-react/chart";
import { connect } from "react-redux";

function Component(props) {
  //
  // store

  const dataSource = {
    store: new CustomStore({
      load: () => {
        return request({ url: `${props.url}` });
      },
    }),
  };

  // hooks

  const chart = useRef();

  // render

  return (
    <Chart ref={chart} dataSource={dataSource} id="components-chart" {...props} palette={"Office"}>
      <Animation enabled={true} duration={1000} />
      {props.children}
    </Chart>
  );
}

function mapStateToProps(state) {
  return {
    menuOpened: state.ui.menuOpened,
  };
}

export default connect(mapStateToProps, null)(Component);
