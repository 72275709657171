import React from "react";
import ReactDOM from "react-dom";
import App from "./app.js";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./redux/reducers/auth";
import orgReducer from "./redux/reducers/org";
import userReducer from "./redux/reducers/user";
import progressReducer from "./redux/reducers/progress";
import uiReducer from "./redux/reducers/ui";
import scenarioReducer from "./redux/reducers/scenario";
import { BrowserRouter as Router } from "react-router-dom";
import homeReducer from "./redux/reducers/home";
import dictionaryReducer from "./redux/reducers/dictionary";
import appReducer from "./redux/reducers/app";
import jobReducer from "./redux/reducers/job";
import preferenceReducer from "./redux/reducers/preference";

window.dojoConfig = {
  async: true,
  packages: [
    {
      name: "react",
      location: "https://unpkg.com/react@16/umd/",
      main: "react.production.min",
    },
    {
      name: "react-dom",
      location: "https://unpkg.com/react-dom@16/umd/",
      main: "react-dom.production.min",
    },
  ],
};

// this is required for devextreme file-uploader to work.
let open = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
  open.apply(this, arguments);
  this.withCredentials = true;
};

const _combineReducers = combineReducers({
  auth: authReducer,
  org: orgReducer,
  user: userReducer,
  progress: progressReducer,
  scenario: scenarioReducer,
  ui: uiReducer,
  home: homeReducer,
  dictionary: dictionaryReducer,
  app: appReducer,
  job: jobReducer,
  preference: preferenceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return _combineReducers(state, action);
};

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);
