import React, { useState, useEffect } from "react";
import styles from "./fund-profile.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart/chart";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { currencyFormat, colorLuminance, withMemo } from "helper/global";
import { CommonSeriesSettings, Series, ValueAxis, ArgumentAxis, Legend, Label, MinorTick, Format, Tooltip } from "devextreme-react/chart";

function Component(props) {
  // hooks

  const [state, setState] = useState(null);

  useEffect(() => {
    response();
    async function response() {
      try {
        setState(null);
        var result = await request({
          url: `${url}/financing/funding/byfund`,
          params: { financeScenarioId: props.scenarioId, fundId: props.fundId },
        });
        setState(result);
      } catch {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scenarioId, props.fundId]);

  function customizeText(e) {
    return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
  }

  function customizeLegendText(e) {
    //return currencyFormat(parseInt(e.seriesName));
  }

  return state ? (
    <div className={styles.main}>
      <Chart dataSource={state.profile}>
        <CommonSeriesSettings argumentField="year" type="stackedBar" />
        <Legend position="inside" horizontalAlignment="left" customizeText={customizeLegendText} visible={false} />
        <Tooltip enabled={true} customizeTooltip={customizeText} />
        <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
          <MinorTick visible={true} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis name="amount" title={""} inverted={false} />
        <Series key={"used"} valueField={"Used"} name={"used"} color={state.color} />
        <Series key={"notUsed"} valueField={"NotUsed"} name={"notUsed"} color={colorLuminance(state.color, 0.3)} />
      </Chart>
    </div>
  ) : (
    <div className={styles.indicator}>
      <LoadIndicator />
    </div>
  );
}

export default withMemo(Component, ["scenarioId", "fundId"]);
