import React, { useRef } from "react";
import styles from "./project-status.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function CostType(props) {
  //
  // stores

  const costTypeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return exporting.current
          ? request({
              url: `${url}/projectstatus/download`,
              loadOptions: loadOptions,
            })
          : request({ url: `${url}/projectstatus`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({
          url: `${url}/projectstatus`,
          method: "Post",
          data: data,
        });
      },
      update: (_, data) => {
        return request({
          url: `${url}/projectstatus`,
          method: "Put",
          data: data,
        });
      },
      remove: (id) => {
        return request({ url: `${url}/projectstatus/${id}`, method: "Delete" });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({
      name: "UPLOAD_PROJECTSTATUS",
      url: `${url}/projectstatus/upload/file/${fileId}`,
    });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    exporting.current = false;
  }

  // render

  function cellRender(data) {
    return (
      <svg width={18} height={18}>
        <rect width={18} height={18} fill={data.value}></rect>
      </svg>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_PROJECTSTATUS"}>
      <div className={styles.main}>
        <Grid
          title={"Project Status"}
          showTitle={false}
          dataSource={costTypeStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
        >
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            visible={false}
            formItem={{ visible: false }}
          />
          <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
          <Column
            caption={"Color"}
            dataField={"color"}
            width={200}
            cellRender={cellRender}
            allowFiltering={false}
            formItem={{
              editorType: "dxColorBox",
              editorOptions: { showClearButton: true },
            }}
          />
          <Column visible={false} formItem={{ itemType: "empty" }} />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            allowFiltering={true}
            formItem={{
              colSpan: 2,
              editorType: "dxTextArea",
              editorOptions: { height: 75 },
            }}
          />
        </Grid>
      </div>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(CostType);
