import React, { useState } from "react";
import styles from "./strategy-group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import Strategy from "./strategy/strategy";

function Component() {
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.group}>
        <StrategyGroup onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.strategy}>{state && <Strategy strategyGroupId={state.id} />}</div>
    </div>
  );
}

const StrategyGroup = React.memo(
  (props) => {
    const strategyGroupStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/strategygroup`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/strategygroup`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/strategygroup`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/strategygroup/${id}`, method: "Delete" });
        },
      }),
    };

    function onSelectionChanged(e) {
      props.onSelectionChanged && props.onSelectionChanged(e);
    }

    return (
      <List title={"Category"} allowDownloading={false} dataSource={strategyGroupStore} onSelectionChanged={onSelectionChanged}>
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} sortOrder={"asc"} sortIndex={1} />
        <Column caption={"Description"} dataField={"description"} visible={false} />
        <Column caption={"Internal"} dataField={"internal"} sortOrder={"desc"} sortIndex={0} visible={false} formItem={{ visible: false }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </List>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
  () => {
    return true;
  }
);

export default Component;
