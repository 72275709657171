import React from "react";
import DataGrid, { Paging, Scrolling, Selection, FilterRow, LoadPanel } from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";

export default class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.data.value,
    };
    this.dropDownBoxRef = React.createRef();
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.contentRender = this.contentRender.bind(this);
    this.onValueChanged = this.onValueChanged.bind(this);
  }

  componentDidMount() {}

  contentRender() {
    return (
      <div className={`theme-datagrid`}>
        <DataGrid
          ref={this.dataGrid}
          dataSource={this.props.data.column.lookup.dataSource}
          remoteOperations={true}
          keyExpr="id"
          selectedRowKeys={[this.state.currentValue]}
          hoverStateEnabled={true}
          onSelectionChanged={this.onSelectionChanged}
          focusedRowEnabled={true}
          defaultFocusedRowKey={this.state.currentValue}
          {...this.props}
        >
          <LoadPanel showIndicator={false}></LoadPanel>
          {this.props.children}
          <FilterRow visible={true} />
          <Paging enabled={true} pageSize={10} />
          <Scrolling mode={"infinite"} />
          <Selection mode="single" />
        </DataGrid>
      </div>
    );
  }

  onSelectionChanged(selectionChangedArgs) {
    if (selectionChangedArgs.selectedRowKeys.length > 0) {
      this.setState({ currentValue: selectionChangedArgs.selectedRowKeys[0] });
      this.props.data.setValue(this.state.currentValue);
      this.dropDownBoxRef.current.instance.close();
    }
  }

  onValueChanged(e) {
    if (e.value == null) {
      this.setState({ currentValue: null });
      this.props.data.setValue(null);
    }
  }

  render() {
    return (
      <DropDownBox
        ref={this.dropDownBoxRef}
        dropDownOptions={{ width: this.props.width }}
        dataSource={this.props.data.column.lookup.dataSource}
        value={this.state.currentValue}
        displayExpr={this.props.data.column.lookup.displayExpr}
        valueExpr="id"
        contentRender={this.contentRender}
        showClearButton={this.props.showClearButton}
        onValueChanged={this.onValueChanged}
        readOnly={this.props.readOnly}
      ></DropDownBox>
    );
  }
}
