import React from "react";
import { url } from "config.js";
import styles from "./matrix.module.scss";
import { currency, toMillion } from "helper/global";
import { dict } from "helper/global";

export default function Component(props) {
  //
  // constants
  const cofRatings = props.header.cofRatings;
  const pofRatings = props.header.pofRatings;

  const rows = pofRatings.length;
  const cols = cofRatings.length;

  const gridGap = "10px";

  // styles

  const main = {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50px 50px 1fr",
    gridTemplateRows: "1fr 25px 50px",
    padding: "0px 10px 0px 0px",
  };

  const matrix_container = {
    gridColumnStart: "3",
    gridColumnEnd: "4",
    gridRowStart: "1",
    gridRowEnd: `2`,
  };

  const matrix = {
    height: "100%",
    display: "grid",
    gridGap: gridGap,
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridTemplateRows: `repeat(${rows}, 1fr)`,
  };

  const xaxis = {
    gridColumnStart: "3",
    gridColumnEnd: `4`,
    gridRowStart: "2",
    gridRowEnd: `3`,
    display: "grid",
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    gridGap: gridGap,
  };

  const xaxis_text = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const xaxis_title = {
    gridColumnStart: "3",
    gridColumnEnd: `4`,
    gridRowStart: "3",
    gridRowEnd: `4`,
    alignSelf: `center`,
  };

  const xaxis_title_text = {
    fontSize: "18px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis = {
    gridColumnStart: "2",
    gridColumnEnd: `3`,
    gridRowStart: "1",
    gridRowEnd: `2`,
    display: "grid",
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    gridGap: gridGap,
  };

  const yaxis_text = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis_title = {
    gridColumnStart: "1",
    gridColumnEnd: "2",
    gridRowStart: "1",
    gridRowEnd: `2`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const yaxis_title_text = {
    transform: "rotate(-90deg)",
    fontSize: "18px",
    whiteSpace: "nowrap",
  };

  const box = (color) => ({
    backgroundColor: `${color}`,
    //border: none
  });

  // event handlers

  function onBoxClick(x, y) {
    props.onBoxClick &&
      props.onBoxClick({
        url: `${url}/risk/matrix/nodes`,
        multiEditUrl: `${url}/multinode/byriskmatrix`,
        params: {
          pof: y,
          cof: x,
        },
        title: `Assets with COF Rating: ${x} and POF Rating: ${y}`,
      });
  }

  // functions

  function getCellCost(cof, pof) {
    if (props.data == null || props.type.includes("Count")) return;

    var value = props.data.find((i) => i.cof === cof && i.pof === pof);

    return value || false ? `${currency()}${toMillion(value.cost)}` : `${currency()}0`;
  }

  function getCellCount(cof, pof) {
    if (props.data == null || props.type.includes("Cost")) return;

    var value = props.data.find((i) => i.cof === cof && i.pof === pof);

    return value || false ? value.count : "0";
  }

  function getCellColor(cof, pof) {
    if (props.data == null) return;

    var value = props.data.find((i) => i.cof === cof && i.pof === pof);

    return !!value ? value.color : "#4a4a4a";
  }

  // render

  function Box(n) {
    let i = (n % cols === 0 ? cols : n % cols) - 1;
    let j = rows - Math.ceil(n / cols);
    let cof = cofRatings[i];
    let pof = pofRatings[j];
    return (
      <div
        className={styles.box}
        style={box(getCellColor(cof, pof))}
        key={n}
        onClick={() => {
          onBoxClick(cof, pof);
        }}
      >
        {getCellCost(cof, pof)}
        <br />
        {getCellCount(cof, pof)}
      </div>
    );
  }

  function Matrix() {
    var n = rows * cols;

    var boxes = new Array(n);

    for (var i = 0; i < n; i++) {
      boxes[i] = Box(i + 1);
    }
    return boxes;
  }

  function XAxis() {
    let boxes = new Array(cols);
    for (var i = 0; i < cols; i++) {
      boxes[i] = (
        <div key={i} style={xaxis_text}>
          {cofRatings[i]}
        </div>
      );
    }
    return boxes;
  }

  function YAxis() {
    let array = pofRatings.slice().reverse();
    let boxes = new Array(rows);
    for (var i = 0; i < rows; i++) {
      boxes[i] = (
        <div key={i} style={yaxis_text}>
          {array[i]}
        </div>
      );
    }
    return boxes;
  }

  return (
    <div style={main}>
      <div style={yaxis_title}>
        <div style={yaxis_title_text}>{`${dict("Probability of Failure")}`}</div>
      </div>
      <div style={yaxis}>
        <YAxis />
      </div>
      <div style={matrix_container}>
        <div style={matrix}>
          <Matrix />
        </div>
      </div>
      <div style={xaxis_title}>
        <div style={xaxis_title_text}>{`${dict("Consequence of Failure")}`}</div>
      </div>
      <div style={xaxis}>
        <XAxis />
      </div>
    </div>
  );
}
