import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Klient from "klient/klient";

function Component() {
  return (
    <Switch>
      <Route path={"/klient"} component={Klient}></Route>
      <Redirect to="/klient" />
    </Switch>
  );
}

export default Component;
