import React, { useEffect, useState } from "react";
import IrisAuth from "./irisAuth/irisAuth";
import AzureAuth from "./azureAuth/azureAuth";
import OktaAuth from "./oktaAuth/oktaAuth";
import Loading from "components/loading/loading";
import { useLocation } from "react-router-dom";
import { request } from "helper/http-client";
import { url } from "config.js";
import Error from "./error/error";
import { setAuthInfo, getAuthInfo } from "helper/global";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // hooks

  const queryParams = new URLSearchParams(useLocation().search);
  const [state, setState] = useState(false);

  useEffect(() => {
    var orgId = (getAuthInfo() && getAuthInfo().organizationId) || queryParams.get("orgId") || 0;
    (async () => {
      try {
        var authInfo = await request({ url: `${url}/authinfo`, params: { organizationId: orgId } });
        setAuthInfo(authInfo);

        setState(true);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // render

  function render() {
    var authInfo = getAuthInfo();
    switch (authInfo.type) {
      case 0:
        return <IrisAuth />;
      case 1:
        return <AzureAuth />;
      case 2:
        return <OktaAuth />;
      default:
        return <Error />;
    }
  }
  return props.error ? <Error /> : state ? render() : <Loading />;
}

function mapStateToProps(state) {
  return {
    error: state.auth.error,
  };
}

function mapsDispatchToProps(dispatch) {
  return {
    throwError: (error) => dispatch(actions.error(error)),
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
