import React, { useState, useEffect, useRef } from "react";
import styles from "./tag.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import TagBox from "components/tag-box/tag-box";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import Grid from "components/data-grid/data-grid.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { RequiredRule } from "devextreme-react/validator";
import * as actions from "redux/actions/index";
import { connect } from "react-redux";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks
  const [tagBox, setTagBox] = useState(0);
  const [tagGrid, setTagGrid] = useState(0);

  // render

  function tagBoxRender() {
    return (
      <TagBox1
        nodeId={props.nodeId}
        render={tagBox}
        onUpdated={() => {
          setTagGrid((state) => state + 1);
        }}
      />
    );
  }

  function gridRender() {
    return (
      <TagGrid
        nodeId={props.nodeId}
        selectedHierarchyNodes={props.selectedHierarchyNodes}
        render={tagGrid}
        onUpdated={() => setTagBox((state) => state + 1)}
      />
    );
  }

  return (
    <div className={styles.main}>
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title="Tiles" render={tagBoxRender} />
        <Item title="Grid" render={gridRender} />
      </TabPanel>
    </div>
  );
}

export default withMemo(Component, ["selectedHierarchyNodes", "nodeId"]);

///

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

const TagGrid = connect(
  null,
  mapDispatchToProps
)(
  withMemo(
    (props) => {
      // stores

      const nodeTagStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            if (exporting.current) {
              return request({
                url: `${url}/nodetag/download`,
                loadOptions: loadOptions,
                params: { ids: props.selectedHierarchyNodes.map((i) => i.id) },
              });
            } else {
              if (!props.nodeId) return Promise.resolve({ data: [] });
              return request({
                url: `${url}/nodetag/node/${props.nodeId}`,
                loadOptions: loadOptions,
              });
            }
          },
          insert: (data) => {
            return request({
              url: `${url}/nodetag`,
              method: "Post",
              data: { ...data, nodeId: props.nodeId },
            });
          },
          remove: (key) => {
            return request({
              url: `${url}/nodetag/${key}`,
              method: "Delete",
            });
          },
          onModified: props.onUpdated,
        }),
      };

      const tagStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/tag`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/tag/${id}` });
          },
        }),
      };

      // hooks
      const exporting = useRef(false);

      // event handlers

      // function onRefresh() {
      //   props.onUpdated();
      // }

      async function onUploaded(fileId) {
        props.upload({
          name: "UPLOAD_NODE_TAG",
          url: `${url}/nodetag/upload/file/${fileId}`,
        });
      }

      function onExporting(e) {
        // e.component.columnOption("id", "visible", true);
        // e.component.columnOption("id", "sortOrder", "asc");
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("node", "visible", true);
        exporting.current = true;
      }

      function onExported(e) {
        // e.component.columnOption("id", "visible", false);
        // e.component.columnOption("id", "sortOrder", "desc");
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("node", "visible", false);
        exporting.current = false;
      }

      // render

      return (
        <div className={styles.main}>
          <ProgressPanel name={"UPLOAD_NODE_TAG"} onComplete={props.onUpdated}>
            <Grid
              title={"Tags"}
              showTitle={false}
              dataSource={nodeTagStore}
              onUploaded={onUploaded}
              onExported={onExported}
              onExporting={onExporting}
              allowUpdating={false}
              allowExpanding={true}
            >
              <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
              <Column
                caption={"Operation"}
                dataField={"operation"}
                calculateCellValue={() => "Update"}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column
                caption={"Name"}
                dataField={"tagId"}
                calculateDisplayValue="name"
                allowFiltering={true}
                width={400}
                formItem={{ colSpan: 2 }}
              >
                <Lookup dataSource={tagStore} valueExpr={"id"} displayExpr={"name"} calculateDisplayValue={"name"} />
                <RequiredRule />
              </Column>
              <Column visible={false} formItem={{ itemType: "empty" }} />
              <Column visible={false} dataField={"node"} caption={"AssetId"} formItem={{ visible: false }} />
            </Grid>
          </ProgressPanel>
        </div>
      );
    },
    ["nodeId", "render"]
  )
);

const TagBox1 = withMemo(
  (props) => {
    //
    // hooks
    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
      (async () => {
        var result = await request({ url: `${url}/tag` });
        setTags(result.data);
      })();
    }, []);

    useEffect(() => {
      (async () => {
        var result = await request({
          url: `${url}/nodetag/node/${props.nodeId}`,
        });
        setSelected(result.tagIds);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nodeId, props.render]);

    // event handlers
    async function onValueChanged(e) {
      setSelected(e);
      await request({
        url: `${url}/nodetag`,
        method: "Put",
        data: { nodeId: props.nodeId, tagIds: e },
      });

      props.onUpdated();
    }
    // render

    return (
      <div>
        <TagBox
          dataSource={tags}
          selectedTags={[...selected]}
          displayExpr="name"
          valueExpr="id"
          placeholder={"Click to select tags..."}
          onValueChanged={onValueChanged}
        />
      </div>
    );
  },
  ["nodeId", "render"]
);
