import React from "react";
import ContextMenu from "devextreme-react/context-menu";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { useHistory } from "react-router-dom";
import "./user-panel.scss";
import { confirm } from "devextreme/ui/dialog";
import { dict } from "helper/global";

function UserPanel(props) {
  //
  // const

  const avatarUrl = props.avatarUrl || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`;

  // useHistory

  const history = useHistory();

  const menuItems = [
    {
      text: dict("Setting"),
      icon: "fad fa-user-cog",
      onClick: onSettings,
    },
    {
      text: dict("Ticket"),
      icon: "fad fa-bug",
      onClick: onTicket,
    },
    {
      text: dict("Logout"),
      icon: "fad fa-sign-out-alt",
      onClick: onLogout,
    },
  ];

  // event handlers

  async function onLogout() {
    var result = await confirm("<i>Do you really want to logout?</i>", "Confirm");
    if (result) props.onLogout();
  }

  function onSettings() {
    history.push(`/klient/user/setting`);
  }

  function onTicket() {
    history.push(`/klient/ticket`);
  }

  return (
    <div className="components-user-panel">
      <div className="user-name">{props.name}</div>
      <div className="image-container">
        <img className="image" alt="/" src={avatarUrl} />
      </div>
      <ContextMenu
        items={menuItems}
        target={".user-button"}
        showEvent={"dxclick"}
        //width={170}
        position={{ my: "top center", at: "bottom center" }}
        cssClass={"components-user-panel-context-menu"}
      />
    </div>
  );
}

function mapsDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(actions.logout()),
  };
}

function mapStateToProps(state) {
  return {
    name: state.user.name || "",
    avatarUrl: state.user.avatarUrl,
    storageKey: state.org.storageSasKey,
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(UserPanel);
