import React, { useState, useRef, useEffect } from "react";
import styles from "./expenditure.module.scss";
import List from "components/list/list";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { addFilter, currency, withMemo, currencyFormat } from "helper/global";
import FinanceScenario from "../../common/finance-scenario/finance-scenario";
import Chart from "components/chart/chart";
import { CommonSeriesSettings, Series, ValueAxis, ArgumentAxis, Legend, Label, MinorTick, Format, Tooltip } from "devextreme-react/chart";
import { LoadIndicator } from "devextreme-react/load-indicator";

const fundStore = {
  store: new CustomStore({
    key: "id",
    useDefaultSearch: true,
    load: (loadOptions) => {
      return request({ url: `${url}/fund`, loadOptions: loadOptions });
    },
    byKey: function (id) {
      return request({ url: `${url}/fund/${id}` });
    },
  }),
};

function Component(props) {
  //
  // stores

  const expenditureStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!fund.id || !scenario.id) return Promise.resolve([]);
        return exporting.current
          ? request({
              url: `${url}/expenditure/download`,
              loadOptions: loadOptions,
              params: { financeScenarioId: scenario.id, fundId: fund.id },
            })
          : request({
              url: `${url}/expenditure`,
              loadOptions: loadOptions,
              params: { financeScenarioId: scenario.id, fundId: fund.id },
            });
      },
      insert: (data) => {
        return request({ url: `${url}/expenditure`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/expenditure`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/expenditure/${id}`, method: "Delete" });
      },
      onModified: () => {
        setRender((render) => render + 1);
      },
    }),
  };

  const expenditureTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/expenditureType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/expenditureType/${id}` });
      },
    }),
  };

  // hooks
  const initialState = { id: null };
  const [fund, setFund] = useState(initialState);
  const [scenario, setScenario] = useState(initialState);
  const [render, setRender] = useState(0);
  const exporting = useRef(false);

  // event handlers

  function onFundSelectionChanged(e) {
    setFund(e.selectedRowsData[0] || initialState);
  }

  function onFinanceScenarioSelectionChanged(e) {
    setScenario(e.selectedRowsData[0] || initialState);
  }

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_EXPENDITURE", url: `${url}/expenditure/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("classId", "visible", true);
    e.component.columnOption("fund", "visible", true);
    e.component.columnOption("financeScenario", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("classId", "visible", false);
    e.component.columnOption("fund", "visible", false);
    e.component.columnOption("financeScenario", "visible", false);
    exporting.current = false;
  }

  // render

  function expenditureRender() {
    return (
      <Grid
        title={"Expenditure"}
        dataSource={expenditureStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={true}
        onInitNewRow={(e) => {
          e.data.fundId = fund.id;
          e.data.financeScenarioId = scenario.id;
        }}
        allowAdding={fund.id && scenario.id}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          allowEditing={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} width={200}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Year"} dataField={"year"} width={150} dataType="number">
          <RequiredRule />
        </Column>
        <Column
          caption={"Value"}
          dataField={"value"}
          width={150}
          dataType="number"
          format={`${currency()}#,###.##`}
          formItem={{ editorOptions: { format: `${currency()}#,###.##`, showClearButton: true } }}
        >
          <RequiredRule />
        </Column>
        <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={200}>
          <Lookup dataSource={expenditureTypeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Fund"} dataField={"fund"} width={200} visible={false} formItem={{ visible: false }}></Column>
        <Column
          caption={"Finance Scenario"}
          dataField={"financeScenario"}
          width={200}
          visible={false}
          formItem={{ visible: false }}
        ></Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          width={400}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
        <Column visible={true} formItem={{ visible: false, itemType: "empty" }} />
      </Grid>
    );
  }

  return (
    <ProgressPanel name={"UPLOAD_EXPENDITURE"}>
      <div className={styles.main}>
        <div className={styles.scenario}>
          <FinanceScenario onSelectionChanged={onFinanceScenarioSelectionChanged} />
        </div>
        <div className={styles.fund}>
          <Fund onSelectionChanged={onFundSelectionChanged} />
        </div>

        <div className={styles.container}>
          <div className={styles.expenditure}>{expenditureRender()} </div>
          <div className={styles.profile}>
            <Profile scenarioId={scenario.id} fundId={fund.id} render={render} />
          </div>
        </div>
      </div>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Fund = withMemo((props) => {
  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  // render

  return (
    <List
      title={"Fund"}
      dataSource={fundStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onSelectionChanged={onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true}></Column>
    </List>
  );
}, []);

const Profile = withMemo(
  (props) => {
    // hooks

    const [state, setState] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (props.scenarioId && props.fundId) {
        setLoading(true);
        response();
        async function response() {
          try {
            setState(null);
            var result = await request({
              url: `${url}/expenditure/summary`,
              params: { financeScenarioId: props.scenarioId, fundId: props.fundId },
            });
            setLoading(false);
            setState(result);
          } catch (error) {
            console(error);
          }
        }
      } else {
        setState(null);
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scenarioId, props.fundId, props.render]);

    // event handlers

    function customizeText(e) {
      return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
    }

    if (loading) {
      return (
        <div className={styles.indicator}>
          <LoadIndicator />
        </div>
      );
    }

    if (state && !loading) {
      return (
        <div className={styles.main}>
          <Chart dataSource={state.profile}>
            <CommonSeriesSettings argumentField="year" type="stackedBar" />
            <Legend visible={false} />
            <Tooltip enabled={true} customizeTooltip={customizeText} />

            <ArgumentAxis
              name=""
              allowDecimals={false}
              minorTickCount={4}
              visualRange={[state.header.firstYear, state.header.lastYear - 1]}
            >
              <MinorTick visible={true} />
              <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
                <Format type="decimal" />
              </Label>
            </ArgumentAxis>
            <ValueAxis name="amount" title={""} inverted={false} />
            <Series key={"value"} valueField={"value"} name={"value"} color={state.color} />
          </Chart>
        </div>
      );
    }

    return null;
  },
  ["scenarioId", "fundId", "render"]
);
