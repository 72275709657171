import React, { useState, useEffect, useRef } from "react";
import styles from "./department.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Form, { GroupItem, SimpleItem, EmptyItem, Label } from "devextreme-react/form";
import { url } from "config.js";
import { withMemo } from "helper/global";
import { request } from "helper/http-client";
import Grid from "components/list/list";
import { Column } from "devextreme-react/data-grid";
import { dict, notify } from "helper/global";
import { Button } from "devextreme-react/button";

function Component() {
  //
  // store

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    console.log(e.selectedRowsData[0] || initialState);
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  const ignoreNodes = () => {
    return state.id == null ? null : <Properties data={state} />;
  };

  return (
    <div className={styles.main}>
      <div className={styles.department}>
        <Department onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.ignorenodes}>{ignoreNodes()}</div>
    </div>
  );
}

export default Component;

const Department = withMemo((props) => {
  //
  // stores

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
    }),
  };

  return (
    <Grid
      title={"Department"}
      dataSource={departmentStore}
      resetRowSelectionOnLoad={true}
      onSelectionChanged={props.onSelectionChanged}
      rowNumbering={true}
      allowDownloading={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
    >
      <Column dataField="name" caption="Name" />
    </Grid>
  );
}, []);

const Properties = withMemo(
  (props) => {
    // event handlers

    async function onSave() {
      try {
        await request({ url: `${url}/departmentsetting`, method: "Put", data: props.data });
        window.location.reload(false);
        notify(dict("Department updated successfully"), "success");
      } catch (error) {
        console.log("error");
      }
    }

    // render

    function buttonRender() {
      return (
        <div>
          <Button text={"Save"} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
          {/* <Button className={styles.cancelbutton} text={"Cancel"} type={"normal"} stylingMode="outlined" width={100} onClick={onCancel} /> */}
        </div>
      );
    }

    return (
      <div className={styles.main}>
        <div className={styles.form}>
          <div className={styles.form_fields}>
            <Form id={"form"} formData={props.data} width={1200} showColonAfterLabel={true} showRequiredMark={false} labelLocation={"top"}>
              <GroupItem colCount={4}>
                <GroupItem te colSpan={2}>
                  <SimpleItem dataField="displayName">
                    <Label text={dict("Header Title")} />
                  </SimpleItem>
                  <SimpleItem dataField="headerLogoUrl">
                    <Label text={`${dict("Header Logo")} Url`} />
                  </SimpleItem>
                  <SimpleItem dataField="headerLogoHeight">
                    <Label text={dict("Header Logo Height")} />
                  </SimpleItem>
                  <SimpleItem dataField="bannerName">
                    <Label text={dict("Banner Title")} />
                  </SimpleItem>
                  <SimpleItem dataField="bannerUrl">
                    <Label text={`${dict("Banner")} Url`} />
                  </SimpleItem>
                  <SimpleItem dataField="bannerLogoUrl">
                    <Label text={`${dict("Banner Logo")} Url`} />
                  </SimpleItem>
                  <SimpleItem dataField="bannerLogoHeight">
                    <Label text={dict("Banner Logo Height")} />
                  </SimpleItem>
                  <EmptyItem />
                  <SimpleItem render={buttonRender} />
                </GroupItem>
                <GroupItem colSpan={2}>
                  <SimpleItem dataField="gisCenterLatitude" editorOptions={{ dataType: "number" }}>
                    <Label text={dict("GIS Center Latitude")} />
                  </SimpleItem>
                  <SimpleItem dataField="gisCenterLongitude">
                    <Label text={dict("GIS Center Longitude")} editorOptions={{ dataType: "number" }} />
                  </SimpleItem>
                  <SimpleItem dataField="gisZoom">
                    <Label text={dict("GIS Zoom")} />
                  </SimpleItem>
                  <SimpleItem dataField="gisBasemap">
                    <Label text={`${dict("GIS Basemap")}`} />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
            </Form>
          </div>
        </div>
      </div>
    );
  },
  ["data"]
);
