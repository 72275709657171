import React, { useState } from "react";
import styles from "./role.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import ResourceGroup from "./resource-group/resource-group";
import Grid from "components/list/list";
import { Column } from "devextreme-react/data-grid";

function Component() {
  //
  // store

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  const resourceGroupRender = () => {
    return state.id == null ? null : <ResourceGroup roleId={state.id} />;
  };

  return (
    <div className={styles.main}>
      <div className={styles.role}>
        <Role onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.policy}>{resourceGroupRender()}</div>
    </div>
  );
}

export default Component;

const Role = React.memo(
  (props) => {
    const roleStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/role`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/role`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/role`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/role/${id}`, method: "Delete" });
        },
      }),
    };

    // const groupStore = {
    //   store: new CustomStore({
    //     key: "id",
    //     useDefaultSearch: true,
    //     load: (loadOptions) => {
    //       return request({ url: `${url}/group`, loadOptions: loadOptions });
    //     },
    //     byKey: function (id) {
    //       return request({ url: `${url}/group/${id}` });
    //     },
    //   }),
    // };

    return (
      <Grid
        title={"Role"}
        dataSource={roleStore}
        editingMode="popup"
        resetRowSelectionOnLoad={true}
        onSelectionChanged={props.onSelectionChanged}
        rowNumbering={true}
        allowDownloading={false}
      >
        <Column dataField="name" caption="Name" />
        <Column dataField="description" caption="Description" visible={false} />
        {/* <Column caption={"Group"} dataField={"groupId"} width={200} visible={false}>
          <Lookup dataSource={groupStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column> */}
        {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  },
  () => {
    return true;
  }
);
