//import themes from "devextreme/ui/themes";
import React from "react";
import styles from "./app.module.scss";
// import "devextreme/dist/css/dx.common.css";
import "theme/material-dark2.css";
import "theme/typography.scss";
import "theme/components/datagrid.scss";
import "theme/components/treelist.scss";
import "theme/components/treeview.scss";
import "theme/components/form.scss";
import "theme/components/toolbar.scss";
import Auth from "auth/auth";

function App(props) {
  ///
  // hooks

  // Don't Delete
  // props.isAuthenticated &&
  //   (() => {
  //     let dict1 = {};
  //     let dict2 = {};
  //     dict1[props.dictionary] = getLanguage(props.dictionary);
  //     dict2[props.dictionary] = props.dictionaryKeyValue;
  //     loadMessages(dict1);
  //     loadMessages(dict2);
  //     locale(props.dictionary);
  //   })();

  // render

  return (
    <div className={styles.main}>
      <Auth />
    </div>
  );
}

export default App;
