import React, { useState, useEffect } from "react";
import { request } from "helper/http-client";
import { url } from "config.js";
import TagBox from "components/tag-box/tag-box";

function Component(props) {
  //
  // hooks
  const [group, setGroup] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    (async () => {
      var result = await request({ url: `${url}/group` });
      setGroup(result.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      var result = await request({ url: `${url}/nodegroup/node/${props.data.id}` });
      setSelected(result.groupIds);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.id]);

  // event handlers
  async function onValueChanged(e) {
    await request({ url: `${url}/nodegroup`, method: "Put", data: { nodeId: props.data.id, groupIds: e } });
    setSelected(e);
  }
  // render

  return (
    <div>
      <TagBox
        dataSource={group}
        selectedTags={[...selected]}
        displayExpr="name"
        valueExpr="id"
        placeholder={"Click to select groups..."}
        onValueChanged={onValueChanged}
      />
    </div>
  );
}

export default Component;
