import * as actionTypes from "../actions/actionTypes";

const initialState = {
  name: null,
  id: null,
  onCancel: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROGRESS_START:
      return {
        name: action.name,
        id: action.id,
        onCancel: action.onCancel,
      };
    case actionTypes.PROGRESS_END:
      return {
        name: null,
        id: null,
        onCancel: null,
      };
    case actionTypes.PROGRESS_CANCEL:
      return {
        name: null,
        id: null,
        onCancel: null,
      };
    default:
      return state;
  }
};

export default reducer;
