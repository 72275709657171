import React, { useState } from "react";
import styles from "./image.module.scss";
import TileView from "components/tileview/tileview";
import { url } from "config.js";

function Component(props) {
  // const

  const [render, setRender] = useState(0);

  // event handlers

  function onRefresh() {
    setRender((render) => render + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <TileView
        urls={{
          read: `${url}/assetimage/asset/${props.data.assetId}`,
          delete: `${url}/assetimage`,
          upload: `${url}/assetimage/blob/asset/${props.data.assetId}`,
        }}
        title={props.data.description}
        render={render}
        refresh={onRefresh}
      ></TileView>
    </div>
  );
}

export default Component;
