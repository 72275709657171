import React, { useRef } from "react";
import styles from "./activity.module.scss";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { addFilter } from "helper/global";
import { Column, Lookup } from "devextreme-react/data-grid";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { RequiredRule, CustomRule } from "devextreme-react/validator";
import { currency, withMemo } from "helper/global";

function Component(props) {
  // stores

  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.strategyId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({
              url: `${url}/activity/strategygroup/${props.strategyGroupId}/download`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/activity`,
              loadOptions: addFilter(loadOptions, "and", ["strategyId", "=", props.strategyId]),
            });
      },
      insert: (data) => {
        data.strategyId = props.strategyId;
        return request({ url: `${url}/activity`, method: "Post", data: data });
      },
      update: (_, data) => {
        data.strategyId = props.strategyId;
        return request({ url: `${url}/activity`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/activity/${id}`, method: "Delete" });
      },
    }),
  };

  const costStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cost`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cost/${id}` });
      },
    }),
  };

  const activityTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/activityType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activityType/${id}` });
      },
    }),
  };

  const fundStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/fund`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/fund/${id}` });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/conditionRating`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/conditionRating/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_ACTIVITY", url: `${url}/activity/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("strategy", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("strategy", "visible", false);
    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_ACTIVITY"}>
      <div className={styles.main}>
        <Grid
          title={"Activity"}
          dataSource={activityStore}
          onUploaded={onUploaded}
          onExported={onExported}
          onExporting={onExporting}
          allowExpanding={true}
          onInitNewRow={(e) => {
            e.data.standalone = false;
          }}
        >
          <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
          <Column
            caption={"Operation"}
            dataField={"operation"}
            calculateCellValue={() => "Update"}
            width={100}
            visible={false}
            formItem={{ visible: false }}
          />

          <Column caption={"Strategy"} dataField={"strategy"} visible={false} width={200} formItem={{ visible: false }} />
          <Column caption={"Name"} dataField={"name"} width={200} />

          <Column caption={"Description"} dataField={"description"} width={400} />
          <Column caption={"Type"} dataField={"typeId"} calculateDisplayValue="type" width={150}>
            <Lookup dataSource={activityTypeStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column
            caption={"First Year"}
            dataField={"firstYear"}
            dataType={"number"}
            width={100}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column
            caption={"Last Year"}
            dataField={"lastYear"}
            dataType={"number"}
            width={100}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column
            caption={"Frequency"}
            dataField={"frequency"}
            dataType={"number"}
            width={100}
            formItem={{ editorOptions: { showClearButton: true } }}
          >
            {/* <RequiredRule /> */}
          </Column>
          <Column
            caption={"PreCondition"}
            dataField={"preConditionRatingId"}
            calculateDisplayValue="preConditionRating"
            width={150}
            allowClearing={true}
          >
            <Lookup dataSource={conditionRatingStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"PostCondition"}
            dataField={"postConditionRatingId"}
            calculateDisplayValue="postConditionRating"
            width={150}
            allowClearing={true}
          >
            <Lookup dataSource={conditionRatingStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column caption={"Cost"} dataField={"costId"} calculateDisplayValue="cost" width={150} allowClearing={true}>
            <Lookup dataSource={costStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column caption={"Fund"} dataField={"fundId"} calculateDisplayValue="fund" width={200}>
            <Lookup dataSource={fundStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
          <Column
            caption={"Cost Override"}
            dataField={"costOverride"}
            dataType={"number"}
            width={100}
            format={`${currency()}#,##0.##`}
            formItem={{ editorOptions: { format: `${currency()}#,##0.##`, showClearButton: true } }}
          />
          <Column
            caption={"Cost Fraction"}
            dataField={"costFraction"}
            dataType={"number"}
            width={100}
            formItem={{ editorOptions: { showClearButton: true } }}
          >
            {/* <RequiredRule /> */}
          </Column>
          <Column
            caption={"Deadband"}
            dataField={"deadband"}
            dataType={"number"}
            width={100}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column
            caption={"Priority"}
            dataField={"priority"}
            dataType={"number"}
            width={100}
            sortOrder={"asc"}
            formItem={{ editorOptions: { showClearButton: true } }}
          />
          <Column caption={"Standalone"} dataField={"standalone"} dataType={"boolean"} width={100}>
            <CustomRule validationCallback={(e) => e.value !== undefined && e.value != null} />
          </Column>
          <Column visible={false} formItem={{ itemType: "empty" }} />
          <Column
            caption={"Notes"}
            dataField={"notes"}
            width={400}
            formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          />
        </Grid>
      </div>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(withMemo(Component, ["strategyId", "strategyGroupId"]));
