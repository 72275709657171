import React from "react";
import styles from "./resource.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";

function Resource(props) {
  // store

  const resourceStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/resource`, loadOptions: loadOptions });
      },
    }),
  };

  return (
    <div className={styles.main}>
      <Grid
        title={"Resources"}
        dataSource={resourceStore}
        allowDownloading={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        rowNumbering={true}
      >
        <Column caption="Name" dataField="name" sortOrder={"asc"} width={400} />
        <Column caption="Description" dataField="description" />
        <Column caption="Type" dataField="type" width={200} />
      </Grid>
    </div>
  );
}

export default Resource;
