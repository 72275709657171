import React, { useRef, useState, useEffect } from "react";
import { DataGrid as DxGrid } from "devextreme-react/data-grid";
import { Template } from "devextreme-react/core/template";
import FileUploader from "components/csv-uploader/csv-uploader";
import "./list.scss";
import { Scrolling, Paging, Column, Editing, Popup, Position, Export, FilterRow } from "devextreme-react/data-grid";
import { confirm } from "devextreme/ui/dialog";
import styles from "./list.module.scss";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { now } from "helper/global";
import { dict } from "helper/global";

function Component(props) {
  //
  // useState

  const [fileLoader, setFileLoader] = useState(false);

  // hooks
  const dataGrid = useRef();
  const columns = useRef();
  const rendered = useRef(false);

  useEffect(() => {
    let colCount = dataGrid.current.option().columns.length;
    dataGrid.current.columnOption(colCount - 1, "visible", false);
  }, []);

  useEffect(() => {
    rendered.current = true;
  });

  // consts

  const allowAdding = props.allowAdding === undefined || props.allowAdding;
  const allowUpdating = props.allowUpdating === undefined || props.allowUpdating;
  const allowDeleting = props.allowDeleting === undefined || props.allowDeleting;
  const allowEditing = allowUpdating || allowDeleting;
  const allowDownloading = props.allowDownloading === undefined || props.allowDownloading;
  const allowFiltering = props.allowFiltering === undefined || props.allowFiltering;
  const rowNumbering = props.rowNumbering === undefined || props.rowNumbering;
  const showTitle = props.showTitle === undefined || props.showTitle;

  // event handlers

  function hideFileLoader() {
    setFileLoader(false);
  }

  function onUploadClick() {
    setFileLoader(true);
  }

  function onUploaded(e) {
    setFileLoader(false);
    props.onUploaded && props.onUploaded(e);
  }

  async function onDownloadClick() {
    var result = await confirm("<i>Do you really want to download the data?</i>", "Confirm");
    if (result) dataGrid.current.exportToExcel(false);
  }
  function onSelectionChanged(e) {
    //props.onSelectionChanged && props.onSelectionChanged(e);
    rendered.current && props.onSelectionChanged && props.onSelectionChanged(e);
  }

  rendered.current = false;
  dataGrid.current && dataGrid.current.clearSelection();

  function onContentReady(e) {
    var selectionMode = e.component.option("selection").mode;
    let selectedRowKeys = e.component.option("selectedRowKeys");

    if (selectionMode === "single" && selectedRowKeys.length === 0) {
      if (e.component.totalCount() > 0) {
        e.component.selectRowsByIndexes([0]);
      } else {
        props.onSelectionChanged && props.onSelectionChanged({ selectedRowsData: [] });
      }
    }
  }

  function onRowUpdating(e) {
    e.newData = { ...e.oldData, ...e.newData };
  }

  function onAddRow() {
    dataGrid.current.addRow();
  }

  function onRowInserted(e) {
    //e.component.selectRows([e.key], false);
  }

  function onRowRemoving(e) {
    let selectedRowKeys = e.component.option("selectedRowKeys");
    if (selectedRowKeys.length > 0 && selectedRowKeys[0] === e.data.id) dataGrid.current && dataGrid.current.clearSelection();
    props.onRowRemoving && props.onRowRemoving();
  }

  function onKeyDown(e) {
    var selKey = e.component.getSelectedRowKeys();
    if (selKey.length) {
      var currentKey = selKey[0];
      var index = e.component.getRowIndexByKey(currentKey);
      if (e.event.keyCode === 38) {
        index--;
        if (index >= 0) {
          e.component.selectRowsByIndexes([index]);
          e.event.stopPropagation();
        }
      } else if (e.event.keyCode === 40) {
        index++;
        e.component.selectRowsByIndexes([index]);
        e.event.stopPropagation();
      }
    }
  }

  function onExporting(e) {
    e.component.beginUpdate();
    setDefaultColumns();
    e.component.columnOption("#", "visible", false);
    props.onExporting && props.onExporting(e);
    var workbook = new ExcelJS.Workbook();
    var worksheet = workbook.addWorksheet("Main sheet");

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), `IRIS ${props.title.toUpperCase()} ${now()}.csv`);
        onExported(e);
      });
    });
    e.cancel = true;
  }

  function onExported(e) {
    setDictColumns();
    props.onExported && props.onExported(e);
    e.component.columnOption("#", "visible", true);
    e.component.endUpdate();
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
    columns.current = {};
    e.component.getVisibleColumns().forEach((col) => {
      columns.current[col.index] = col.caption;
    });
    setDictColumns();
    props.onInitialized && props.onInitialized(e);
  }

  // functions

  function setDictColumns() {
    for (const prop in columns.current) {
      dataGrid.current.columnOption(Number(prop), "caption", dict(columns.current[prop]));
    }
  }

  function setDefaultColumns() {
    for (const prop in columns.current) {
      dataGrid.current.columnOption(Number(prop), "caption", columns.current[prop]);
    }
  }

  function toggleEditing() {
    let colCount = dataGrid.current.option().columns.length;
    let optionValue = !dataGrid.current.columnOption(colCount - 1, "visible");
    dataGrid.current.columnOption(colCount - 1, "visible", optionValue);
  }

  // render

  function titleRender() {
    return props.title ? (
      <div className="theme-grid-title">
        <div>{dict(props.title)}</div>
      </div>
    ) : null;
  }

  function onContextMenuPreparing(e) {
    if (!e.items) e.items = [];

    e.items.push({
      text: "Allow Editing",
      selected: dataGrid.current.columnOption(dataGrid.current.option().columns.length - 1, "visible"),
      icon: "fas fa-edit",
      visible: allowEditing,
      onItemClick: toggleEditing,
    });

    props.onContextMenuPreparing && props.onContextMenuPreparing(e);
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      template: "titleRender",
      visible: showTitle,
    });
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",
        visible: allowAdding,
        options: {
          hint: "Add a row",
          icon: "fas fa-plus",
          onClick: onAddRow,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.onUploaded,
        options: {
          hint: "Upload",
          icon: "fas fa-cloud-upload",
          onClick: onUploadClick,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        visible: !!props.allowDownload || !!allowDownloading,
        options: {
          hint: "Download",
          icon: "fas fa-cloud-download",
          onClick: onDownloadClick,
        },
      }
    );
    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  function gridRender() {
    return (
      <DxGrid
        keyExpr={"id"}
        height={"100%"}
        selection={{ mode: "single" }}
        remoteOperations={props.remoteOperations || true}
        showRowLines={true}
        showColumnLines={true}
        showBorders={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        //selectedRowKey={selectedRows.current}
        onRowUpdating={onRowUpdating}
        onContentReady={onContentReady}
        //customizeColumns={customizeColumns}
        wordWrapEnabled={true}
        onRowInserted={onRowInserted}
        {...props}
        onToolbarPreparing={onToolbarPreparing}
        onSelectionChanged={onSelectionChanged}
        onKeyDown={onKeyDown}
        onExported={onExported}
        onExporting={onExporting}
        onContextMenuPreparing={onContextMenuPreparing}
        onInitialized={onInitialized}
        onRowRemoving={onRowRemoving}
      >
        {allowEditing && (
          <Editing mode={props.editingMode || "popup"} allowUpdating={allowUpdating} allowDeleting={allowDeleting}>
            <Popup title={dict(props.title)} showTitle={true} width={700} height={700}>
              <Position my="center" at="center" of={window} />
            </Popup>
          </Editing>
        )}
        {allowFiltering && <FilterRow visible={true} />}
        <Export enabled={false} fileName="iris_export" allowExportSelectedData={false} customizeExcelCell={props.customizeExcelCell} />
        <Paging pageSize={20} />
        <Scrolling mode={"infinite"} />
        <Template name="titleRender" render={titleRender} />
        {rowNumbering ? (
          <Column
            key={"#"}
            caption={"#"}
            width={75}
            allowEditing={false}
            formItem={{ visible: false }}
            cellRender={(e) => {
              return <div>{e.row.loadIndex + 1}</div>;
            }}
          />
        ) : null}
        {props.children}
        <Column type={"buttons"} width={85} fixed={true} visible={false} />
      </DxGrid>
    );
  }

  return (
    <div className={`${styles.main} theme-datagrid`}>
      <FileUploader fileLoaderVisible={fileLoader} hideFileLoader={hideFileLoader} onUploaded={onUploaded} />
      <div className={styles.row}>
        <div className={`${styles.col} theme-datagrid`}>{gridRender()}</div>
      </div>
    </div>
  );
}

export default Component;
