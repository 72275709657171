import React, { useEffect, useReducer, useRef } from "react";
import TileView from "devextreme-react/tile-view";
import Toolbar, { Item } from "devextreme-react/toolbar";
import styles from "./doc-tileview.module.scss";
import { LoadIndicator } from "devextreme-react/load-indicator";
import "./doc-tileview.scss";
import FileUploader from "components/blob-uploader/blob-uploader";
import { request } from "helper/http-client";
import { notify } from "helper/global";
import { confirm } from "devextreme/ui/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon } from "helper/global";
import { useImmerReducer } from "use-immer";

function reducer(draft, action) {
  switch (action.type) {
    case "showAdd":
      draft.add = true;
      return;
    case "hideAdd":
      draft.data = null;
      draft.add = false;
      draft.render = draft.render + 1;
      return;
    case "delete":
      draft.data = null;
      draft.render = draft.render + 1;
      return;
    case "data":
      draft.data = action.data;
      return;
    case "render":
      draft.render = draft.render + 1;
      return;
    default:
      return;
  }
}

const initialState = {
  data: null,
  render: 0,
  upload: false,
  add: false,
};

function Component(props) {
  //
  // useReducer

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  // hooks
  const selectedId = useRef(null);
  const selectedUrl = useRef(null);

  useEffect(() => {
    (async () => {
      try {
        var result = await request({ url: props.urls.read });
        dispatch({ type: "data", data: result.data });
      } catch (error) {
        notify(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.urls, props.render, state.render]);

  // event handlers

  function onAdd() {
    dispatch({ type: "showAdd" });
  }

  function hideAdd() {
    dispatch({ type: "hideAdd" });
  }

  function onItemClick(e) {
    selectedId.current = e.itemData.id;
    selectedUrl.current = e.itemData.url;
  }

  async function onDelete() {
    if (selectedId.current) {
      var result = await confirm("<i>Do you really want to delete the selected document?</i>", "Confirm");
      if (result) {
        try {
          await request({ url: `${props.urls.delete}/${selectedId.current}`, method: "Delete" });
          dispatch({ type: "delete" });
          props.refresh && props.refresh();
        } catch (error) {
          notify(error, "error");
        }
      }
    }
  }

  function onDoubleClick() {
    if (selectedUrl.current) {
      var win = window.open(selectedUrl.current, "_blank");
      win && win.focus();
    }
  }

  function onUploaded() {
    hideAdd();
    props.refresh && props.refresh();
  }

  // render

  function tileRender(data) {
    var filename = data.name;

    return (
      <div className={styles.image} onDoubleClick={onDoubleClick}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={getIcon(filename)} />
        </div>
        <div className={styles.text}>{filename}</div>
      </div>
    );
  }

  function loadIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function toolbarRender() {
    return (
      <div className={`components_doctileview`}>
        <Toolbar>
          <Item location="before" widget="dxButton" options={{ icon: "fas fa-plus", onClick: onAdd }} />
          <Item location="before" widget="dxButton" options={{ icon: "fas fa-minus", onClick: onDelete }} />
        </Toolbar>
      </div>
    );
  }

  function tileViewRender() {
    return (
      <div className={`components_doctileview`}>
        <TileView
          height={"100%"}
          showScrollbar={true}
          direction="vertical"
          items={state.data}
          itemRender={tileRender}
          onItemClick={onItemClick}
        ></TileView>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <FileUploader
        multiple={true}
        allowedFileExtensions={[]}
        fileLoaderVisible={state.add}
        hideFileLoader={hideAdd}
        uploadUrl={props.urls.upload}
        onUploaded={onUploaded}
      />
      {props.allowEditing && toolbarRender()}
      {state.data ? tileViewRender() : loadIndicatorRender()}
    </div>
  );
}

export default Component;
