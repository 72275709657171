import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

const _loadHome = (result) => {
  return {
    type: actionTypes.HOME_LOAD,
    ...result,
  };
};

const _clearHome = () => {
  return {
    type: actionTypes.HOME_CLEAR,
  };
};

export const loadHome = () => {
  return async (dispatch) => {
    try {
      let p1 = request({ url: `${url}/home/conditiondistribution` });
      let p2 = request({ url: `${url}/home/riskdistribution` });
      let p3 = request({ url: `${url}/home/consumptiondistribution` });
      let p4 = request({ url: `${url}/home/activitycostprofile` });
      let p5 = request({ url: `${url}/home/installationprofile` });
      let p6 = request({ url: `${url}/home/count` });
      let [condition, risk, consumption, cost, installation, count, project] = await Promise.all([p1, p2, p3, p4, p5, p6]);
      let result = {
        condition,
        risk,
        consumption,
        cost,
        installation,
        count,
      };
      var highRisk = risk.length && risk.find((i) => i.rating === "High");
      result.header = { ...risk.header, highRiskCost: (highRisk && highRisk.cost) || 0 };

      dispatch(_loadHome(result));
    } catch (error) {}

    return "done";
  };
};

export const clearHome = () => {
  return function (dispatch) {
    dispatch(_clearHome(null));
  };
};
