import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "helper/global";

const reducer = (state = null, action) => {
  switch (action.type) {
    case actionTypes.DICTIONARY_LOAD:
      return updateObject(state, {
        ...action,
        loading: false,
        error: null,
      });
    case actionTypes.DICTIONARY_UPDATE:
      return updateObject(state, {
        ...action,
        loading: false,
        error: null,
      });
    default:
      return state;
  }
};

export default reducer;
