import React, { useState, useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import styles from "./type.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { withMemo } from "helper/global";

const classStore = {
  store: new CustomStore({
    key: "id",
    useDefaultSearch: true,
    load: (loadOptions) => {
      return request({ url: `${url}/class`, loadOptions: loadOptions });
    },
    byKey: function (id) {
      return request({ url: `${url}/class/${id}` });
    },
  }),
};

function Component(props) {
  //
  // stores

  const typeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!state.id) return Promise.resolve([]);
        return exporting.current
          ? request({
              url: `${url}/type`,
              loadOptions: loadOptions,
            })
          : request({
              url: `${url}/type/byclass/${state.id}`,
              loadOptions: loadOptions,
            });
      },
      insert: (data) => {
        return request({ url: `${url}/type`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/type`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/type/${id}`, method: "Delete" });
      },
    }),
  };

  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const exporting = useRef(false);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_TYPE", url: `${url}/type/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("classId", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("classId", "visible", false);
    e.component.endUpdate();
    exporting.current = false;
  }

  // render

  function typeRender() {
    return (
      <Grid
        title={"Type"}
        dataSource={typeStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        onInitNewRow={(e) => {
          e.data.classId = state.id;
        }}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} allowEditing={false} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          allowEditing={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={400}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
        <Column caption={"Class"} dataField={"classId"} calculateDisplayValue="class" width={200} visible={false}>
          <Lookup dataSource={classStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.class}>
        <Class onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.type}>
        <ProgressPanel name={"UPLOAD_TYPE"}>{typeRender()}</ProgressPanel>
      </div>
    </div>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);

const Class = withMemo((props) => {
  return (
    <Grid
      title={"Class"}
      dataSource={classStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true}></Column>
    </Grid>
  );
}, []);
