import React, { useEffect, useRef, useState } from "react";
import "./messagebox.scss";
import styles from "./messagebox.module.scss";
import HtmlEditor from "devextreme-react/html-editor";
import CommentBox from "../commentbox/commentbox";
import { Button } from "devextreme-react/button";
import moment from "moment";
import { connect } from "react-redux";
import Editor from "../../editor/editor";
import { request } from "helper/http-client";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { notify } from "helper/global";

function Component(props) {
  //
  // const

  const message = props.dataSource.find((i) => i.typeId === 1 || i.typeId === 2);
  const comments = props.dataSource.filter((i) => i.typeId === 3);

  return (
    <div className={styles.main}>
      <Header dataSource={message} onRefresh={props.onRefresh} showEmailButton={props.accessTicket} />
      <Body value={message.text} />
      {comments.length > 0 && <Comments dataSource={comments} onRefresh={props.onRefresh} />}
      <Footer dataSource={message} onRefresh={props.onRefresh} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    accessTicket: !!state.user.accessTicket,
  };
}

export default connect(mapStateToProps, null)(Component);

function Header(props) {
  //
  // consts

  const subject = props.dataSource.subject && `${props.dataSource.subject.substr(0, 55) + (props.dataSource.subject.length > 55 ? "..." : "")}`;

  // hooks

  const [editor, setEditor] = useState(false);

  // event handlers

  function showEditor() {
    setEditor(true);
  }

  function hideEditor(e) {
    if (e) props.onRefresh();
    setEditor(false);
  }

  async function onEmail() {
    var result = await confirm("<i>Do you really want to send an email to the user?</i>", "Confirm");

    if (result)
      if (result)
        try {
          await request({ url: `${url}/message/${props.dataSource.id}/email`, method: "Post", data: props.dataSource });
        } catch (error) {
          notify(`Operation failed. Error: ${error}`, "error", 5000);
        }
  }

  // render
  return (
    <div className={styles.header}>
      <Editor dataSource={props.dataSource} type="message" operation="update" showEditor={editor} hideEditor={hideEditor} />
      <div>
        <img
          className={styles.header_avatar}
          alt="/"
          src={props.dataSource.avatar || `https://stirisprod001.blob.core.windows.net/avatars/Avatar.jpg`}
        />
      </div>
      <div className={styles.header_subject}>
        <div className={styles.header_subject_title}>{subject}</div>
        <div className={styles.header_subject_createdby}>
          <div className={styles.header_subject_createdby_name}>{props.dataSource.user}</div>
          <div className={styles.header_subject_createdby_org}>{`(${props.dataSource.organization})`}</div>
          <div className={styles.header_subject_createdby_org}>{`${props.dataSource.createdOn}`}</div>
        </div>
      </div>
      <div className={styles.header_createdon}>{`${moment(props.dataSource.modifiedOn).fromNow()}`}</div>
      <div>
        <Button
          text={"Email"}
          icon={"email"}
          type={"success"}
          stylingMode="text"
          visible={props.showEmailButton}
          disabled={props.dataSource.editable === "No"}
          onClick={onEmail}
        />
      </div>
      <div>
        <Button text={"Edit"} icon={"edit"} type={"success"} stylingMode="text" disabled={props.dataSource.editable === "No"} onClick={showEditor} />
      </div>
    </div>
  );
}

function Body(props) {
  const htmlEditor = useRef(null);

  useEffect(() => {});

  return (
    <div className={`${styles.body} messagebox_htmleditor`}>
      <HtmlEditor ref={htmlEditor} value={props.value} readOnly={true} />
    </div>
  );
}

function Comments(props) {
  return (
    <div className={styles.comments}>
      <div className={styles.comments_header}>Comments</div>
      <div className={styles.comments_body}>
        {props.dataSource.map((i) => {
          return <CommentBox key={i.id} dataSource={i} onRefresh={props.onRefresh} />;
        })}
      </div>
    </div>
  );
}

function Footer(props) {
  //
  // const

  const dataSource = {
    subject: "Comment by IRIS Support Team",
    text: "",
    parentId: props.dataSource.id,
    typeId: 3,
  };

  // hooks

  const [editor, setEditor] = useState(false);

  // event handlers

  function showEditor() {
    setEditor(true);
  }

  function hideEditor() {
    props.onRefresh();
    setEditor(false);
  }

  return (
    <div className={styles.footer}>
      <Editor dataSource={dataSource} type="message" operation="create" showEditor={editor} hideEditor={hideEditor} />
      <Button text={"Comment"} icon={"comment"} type={"success"} stylingMode="text" onClick={showEditor} />
    </div>
  );
}
