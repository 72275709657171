import React, { useState, useRef } from "react";
// import styles from "./fieldmap.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid";
import { request } from "helper/http-client";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // stores

  const fieldMapStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.sourceId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({
              url: `konnect/fieldmap/source/${props.sourceId}`,
              loadOptions: loadOptions,
            })
          : request({
              url: `konnect/fieldmap/source/${props.sourceId}`,
              loadOptions: loadOptions,
            });
      },
      insert: (data) => {
        console.log(data);
        return request({ url: `konnect/fieldmap`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `konnect/fieldmap`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `konnect/fieldmap/${id}`, method: "Delete" });
      },
    }),
  };

  const fieldStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (_) => {
        return request({ url: `konnect/field/source/${props.sourceId}` });
      },
      byKey: function (id) {
        return request({ url: `konnect/field/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({
      name: "UPLOAD_FIELDMAP",
      url: `konnect/fieldmap/upload/file/${fileId}`,
    });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("source", "visible", true);
    e.component.columnOption("dataType", "visible", false);

    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("source", "visible", false);
    e.component.columnOption("source", "dataType", true);
    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_FIELDMAP"}>
      <Grid
        title={"Field Map"}
        dataSource={fieldMapStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={true}
        onInitNewRow={(e) => {
          e.data.sourceId = props.sourceId;
        }}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column visible={false} dataField={"source"} sortOrder={"Source"} formItem={{ visible: false }} />
        <Column caption={"Field"} dataField={"fieldId"} allowSorting={false} calculateDisplayValue="description" width={300}>
          <Lookup dataSource={fieldStore} valueExpr={"id"} displayExpr="description" />
        </Column>
        <Column caption={"Data Type"} dataField={"dataType"} allowFiltering={false} allowEditing={false} allowSorting={false} width={200} />
        <Column
          caption={"Expression"}
          dataField={"expression"}
          allowFiltering={true}
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { height: 75 },
          }}
        />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          width={400}
          allowFiltering={true}
          formItem={{
            colSpan: 2,
            editorType: "dxTextArea",
            editorOptions: { height: 75 },
          }}
        />
      </Grid>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
