import React, { useState } from "react";
import styles from "./project-group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import Project from "./project/project";
import { connect } from "react-redux";
import { withMemo } from "helper/global";

function Component(props) {
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.group}>
        <ProjectGroup departmentId={props.departmentId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.project}>{state.id && <Project projectGroupId={state.id} />}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentId: state.user.departmentId,
  };
};

export default connect(mapStateToProps, null)(Component);

const ProjectGroup = withMemo((props) => {
  const strategyGroupStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/projectgroup`,
          loadOptions: loadOptions,
        });
      },
      insert: (data) => {
        return request({
          url: `${url}/projectgroup`,
          method: "Post",
          data: data,
        });
      },
      update: (_, data) => {
        return request({
          url: `${url}/projectgroup`,
          method: "Put",
          data: data,
        });
      },
      remove: (id) => {
        return request({
          url: `${url}/projectgroup/${id}`,
          method: "Delete",
        });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/department/${id}` });
      },
    }),
  };

  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function cellRender(data) {
    return (
      <svg width={18} height={18}>
        <rect width={18} height={18} fill={data.value}></rect>
      </svg>
    );
  }

  return (
    <List title={"Category"} allowDownloading={false} dataSource={strategyGroupStore} onSelectionChanged={onSelectionChanged}>
      <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} sortOrder={"asc"} sortIndex={1} />
      <Column caption={"Description"} dataField={"description"} visible={false} />
      <Column caption={"Internal"} dataField={"internal"} sortOrder={"desc"} sortIndex={0} visible={false} formItem={{ visible: false }} />
      <Column
        caption={"Color"}
        dataField={"color"}
        width={200}
        visible={false}
        cellRender={cellRender}
        formItem={{
          editorType: "dxColorBox",
          editorOptions: { showClearButton: true },
        }}
      />
      <Column caption={"Department"} dataField={"departmentId"} visible={false} formItem={{ visible: props.departmentId === null }}>
        <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      <Column visible={false} formItem={{ itemType: "empty", visible: props.departmentId !== null }} />{" "}
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        allowFiltering={true}
        formItem={{
          colSpan: 2,
          editorType: "dxTextArea",
          editorOptions: { height: 75 },
        }}
      />
    </List>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
