import React, { useState } from "react";
import styles from "./department.module.scss";
import "./department.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { withMemo, dict } from "helper/global";
import { request } from "helper/http-client";
import Grid from "components/list/list";
import { Column } from "devextreme-react/data-grid";
import TreeList, { Column as TreeColumn, Editing, Popup, Position, FilterRow } from "devextreme-react/tree-list";
import { Template } from "devextreme-react/core/template";

function Component() {
  //
  // store

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  const ignoreNodes = () => {
    return state.id == null ? null : (
      <div className={`${styles.ignorenode} theme-treelist theme-ignorenode-treelist`}>
        <IgnoreNode departmentId={state.id} />
      </div>
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.department}>
        <Department onSelectionChanged={onSelectionChanged} />
      </div>
      {ignoreNodes()}
    </div>
  );
}

export default Component;

const Department = withMemo((props) => {
  //
  // stores

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/department`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/department`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/department/${id}`, method: "Delete" });
      },
    }),
  };

  return (
    <Grid
      title={"Department"}
      dataSource={departmentStore}
      resetRowSelectionOnLoad={true}
      onSelectionChanged={props.onSelectionChanged}
      rowNumbering={true}
      allowDownloading={false}
    >
      <Column dataField="name" caption="Name" />
      <Column dataField="description" caption="Description" visible={false} />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </Grid>
  );
}, []);

const IgnoreNode = withMemo(
  (props) => {
    //
    // stores

    const ignoreNodeStore = {
      store: new CustomStore({
        key: "id",
        useDefaultSearch: true,
        load: (loadOptions) => {
          return request({ url: `${url}/ignorenode`, loadOptions: loadOptions, params: { departmentId: props.departmentId } });
        },
        update: (_, data) => {
          return request({ url: `${url}/ignorenode`, method: "Put", data: data });
        },
        byKey: function (key) {
          return request({ url: `${url}/ignorenode/${key}` });
        },
      }),
    };

    // event handlers

    function onRowUpdating(e) {
      e.newData = { ...e.oldData, ...e.newData };
    }

    // render

    function titleRender() {
      return (
        <div className={`theme-grid-title`}>
          <div>{"Hierarchy"}</div>
        </div>
      );
    }

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.push({
        location: "before",
        template: "titleRender",
      });
    }

    return (
      <TreeList
        title={"Ignore"}
        dataSource={ignoreNodeStore}
        rootValue={null}
        showBorders={true}
        columnAutoWidth={true}
        keyExpr="id"
        parentIdExpr="parentId"
        height={"100%"}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode="widget"
        remoteOperations={{ filtering: true }}
        onToolbarPreparing={onToolbarPreparing}
        onRowUpdating={onRowUpdating}
      >
        <Editing mode={"batch"} allowUpdating={true} allowDeleting={false}>
          <Popup title={dict("Hierarchy")} showTitle={true} width={700} height={700}>
            <Position my="center" at="center" of={window} />
          </Popup>
        </Editing>
        <FilterRow visible={true} />
        <Template name="titleRender" render={titleRender} />
        <TreeColumn caption={"Name"} dataField="description" allowEditing={false} />
        <TreeColumn caption={"Ignore"} dataField="ignore" dataType="boolean" width={100} />
      </TreeList>
    );
  },
  ["departmentId"]
);
