import React, { useEffect, useRef, useContext } from "react";
import Chart from "components/chart/chart";
import { url } from "config.js";
import { currencyFormat, dict } from "helper/global";
import styles from "./profile.module.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { connect } from "react-redux";
import {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
  ConstantLine,
  Font,
} from "devextreme-react/chart";
import ProjectActivity from "../project-activity/project-activity";
import Card from "./card/card";
import { useImmerReducer } from "use-immer";
import HierarchyContext from "components/hierarchy/context";

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.data = action.payload;
      draft.loading = false;
      return;
    case "year":
      draft.year = action.payload.year;
      if (action.payload.render) {
        draft.data = null;
        draft.render++;
      }
      return;
    case "escalate":
      draft.escalate = !draft.escalate;
      draft.render++;
      return;
    case "loading":
      draft.loading = !draft.loading;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // vars

  const intialState = {
    data: null,
    year: null,
    escalate: false,
    render: 0,
    loading: false,
  };

  // hooks

  const [state, dispatch] = useImmerReducer(reducer, intialState);
  const chartRef = useRef(null);
  const renderChart = useRef(false);
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;

  useEffect(() => {
    if (state.year) {
      renderChart.current = true;
      return;
    }

    (async () => {
      try {
        dispatch({ type: "loading" });
        var result = await request({
          url: `${url}/activitycostprofile`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            escalate: state.escalate,
            filterExpression: filter,
          },
        });
        dispatch({ type: "data", payload: result });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter, state.render]);

  // stores

  // event handlers

  function onPointClick(e) {
    var year = e.target.originalArgument;
    dispatch({ type: "year", payload: { year: year } });
  }

  function onEscalateChanged(e) {
    dispatch({ type: "escalate" });
  }

  function onBackButtonClick() {
    dispatch({
      type: "year",
      payload: { year: null, render: renderChart.current },
    });
    renderChart.current = false;
  }

  function onInitialized(e) {
    chartRef.current = e.component;
  }

  //render

  function cardRender(title, value) {
    return <Card title={title} value={value}></Card>;
  }

  function customizeText(e) {
    return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
  }

  function toolbarRender() {
    return (
      <div className={`${styles.toolbar} theme-toolbar`}>
        <Toolbar>
          <Item location="before" render={() => cardRender(`${dict("Total Cost")}:`, currencyFormat(state.data.header.totalCost))} />
          <Item location="before" render={() => cardRender(`${dict("Annual Cost")}:`, currencyFormat(state.data.header.annualCost))} />
          <Item
            location="before"
            render={() => cardRender(`${dict("Replacement Cost")}:`, currencyFormat(state.data.header.replacementCost))}
          />
          <Item location="before" render={() => cardRender(`${dict("Program Cost")}:`, currencyFormat(state.data.header.programCost))} />
          <Item location="after" locateInMenu="never">
            <div className={styles.toolbar_item}>{`${dict("Escalate")}:`}</div>
          </Item>
          <Item
            location="after"
            locateInMenu="auto"
            widget="dxCheckBox"
            options={{
              value: state.escalate,
              onValueChanged: onEscalateChanged,
            }}
          />
        </Toolbar>
      </div>
    );
  }

  function projectActivityRender() {
    return (
      <div className={styles.grid}>
        <ProjectActivity year={state.year} onBackButtonClick={onBackButtonClick} />
      </div>
    );
  }

  const profileRender = () => {
    return (
      <div className={styles.chart}>
        <Chart dataSource={state.data.profile} onPointClick={onPointClick} onInitialized={onInitialized}>
          <CommonSeriesSettings argumentField="year" />
          <Legend visible={false} />
          <Tooltip enabled={true} customizeTooltip={customizeText} />
          <ArgumentAxis allowDecimals={false} minorTickCount={4} visualRange={[props.firstYear + 1, props.lastYear - 1]}>
            <MinorTick visible={false} />
            <Label>
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <ValueAxis minValueMargin={0} maxValueMargin={0} title="">
            <ConstantLine width={2} value={state.data.header.annualCost} color={"#bb86fc"} dashStyle={"longDash"}>
              <Label visible={false} horizontalAlignment={"center"}>
                <Font size={14} color={"#fff"} weight={500}></Font>
              </Label>
            </ConstantLine>
          </ValueAxis>
          <Series valueField="value" type="bar">
            <Point visible={false} />
          </Series>
        </Chart>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function indicatorRender() {
    return (
      <div className={styles.loading}>
        <LoadIndicator />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {state.year ? (
        projectActivityRender()
      ) : (
        <React.Fragment>
          {state.data && toolbarRender()}
          {state.data && profileRender()}
          {state.loading && indicatorRender()}
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
