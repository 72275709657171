import React, { useState } from "react";
import styles from "./activity.module.scss";
import Grid from "components/list/list";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { addFilter } from "helper/global";
import { Column } from "devextreme-react/data-grid";
import Phase from "../phase/phase";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.activity}>
        <Activity strategyId={props.strategyId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.phase}>
        <Phase activityId={state.id} />
      </div>
    </div>
  );
}

export default Component;

const Activity = React.memo(
  (props) => {
    //
    // stores

    const activityStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.strategyId) return Promise.resolve({ data: [] });
          return request({
            url: `${url}/activity`,
            loadOptions: addFilter(loadOptions, "and", ["strategyId", "=", props.strategyId]),
          });
        },
      }),
    };

    // hooks

    return (
      <Grid
        title={"Activity"}
        dataSource={activityStore}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        allowExpanding={true}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Column visible={false} dataField={"id"} />
        <Column caption={"Name"} dataField={"name"} />
        <Column caption={"Type"} dataField={"type"} width={150} />
        <Column caption={"Frequency"} dataField={"frequency"} dataType={"number"} width={100} />
      </Grid>
    );
  },
  (prev, next) => {
    return prev.strategyId === next.strategyId;
  }
);
