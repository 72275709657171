import React from "react";
import styles from "./input.module.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { selectedTabItem } from "helper/global";
import Tabs from "components/tabs/tabs";
import Register from "./register/register";
import Strategy from "./strategy/strategy-group";
import Cost from "./cost/cost-group";
import Project from "./project/project-group";
import { dict } from "helper/global";

function Input(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Asset"),
      icon: "fad fa-fan",
      path: "/register",
    },
    {
      text: dict("Strategy"),
      icon: "fad fa-chess-knight",
      path: "/strategy",
    },
    {
      text: dict("Cost"),
      icon: "fad fa-dollar-sign",
      path: "/cost",
    },
  ];

  // event handlers

  function onChangeView(e) {
    props.history.push(`/klient/manage/input${e.path}`);
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs
          tabItems={tabItems}
          selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)}
          changeView={onChangeView}
        />
      </div>
      <div className={styles.container}>
        <Switch>
          <Route
            exact
            path={[
              "/klient/manage",
              "/klient/manage/input",
              "/klient/manage/input/register",
            ]}
            component={Register}
          />
          <Route
            exact
            path="/klient/manage/input/strategy"
            component={Strategy}
          />
          <Route exact path="/klient/manage/input/cost" component={Cost} />
          <Route
            exact
            path="/klient/manage/input/project"
            component={Project}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
}

export default Input;
