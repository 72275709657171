import React, { useRef } from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // stores

  const nodeStatusStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return exporting.current
          ? request({ url: `${url}/facility/download`, loadOptions: loadOptions })
          : request({ url: `${url}/facility`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/facility`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/facility`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/facility/${id}`, method: "Delete" });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_FACILITY", url: `${url}/facility/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    exporting.current = false;
  }

  // render

  return (
    <ProgressPanel name={"UPLOAD_FACILITY"}>
      <Grid
        title={"Facility"}
        showTitle={false}
        dataSource={nodeStatusStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          width={100}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={200}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} allowFiltering={true} width={400} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    </ProgressPanel>
  );
}

// redux

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
