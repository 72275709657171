import React, { useState, useRef, useMemo } from "react";
import styles from "./ticket-list.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faBug, faCode, faBullhorn, faQuestion, faSirenOn, faReply, faCheckCircle } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import Editor from "../editor/editor";
import { withMemo } from "helper/global";
import { RequiredRule } from "devextreme-react/validator";
import { connect } from "react-redux";

function Component(props) {
  //
  // const

  const statuses = [
    { id: 0, name: "Submitted", icon1: faQuestion, color: "red" },
    { id: 1, name: "InProgress", icon1: faSearch, color: "magenta" },
    { id: 2, name: "Answered", icon1: faReply, color: "orange" },
    { id: 3, name: "Completed", icon1: faCheckCircle, color: "green" },
  ];

  const priorities = [
    { id: 0, name: "None", color: "gray" },
    { id: 1, name: "Low", color: "green" },
    { id: 2, name: "Med", color: "yellow" },
    { id: 3, name: "High", color: "red" },
  ];

  const types = [
    { id: 0, name: "None", icon1: faSirenOn },
    { id: 1, name: "Bug Report", icon1: faBug },
    { id: 2, name: "Feature Request", icon1: faCode },
    { id: 3, name: "Suggestion", icon1: faBullhorn },
  ];

  // stores

  const ticketStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/ticket`, loadOptions: loadOptions, params: { organization: true } });
      },
      update: (_, data) => {
        return request({ url: `${url}/ticket`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/ticket/${id}`, method: "Delete" });
      },
      onModified: () => {
        props.onRefresh();
      },
    }),
  };

  const userStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/user`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/user/${id}` });
      },
    }),
  };

  // hooks

  const [editor, setEditor] = useState(false);
  const selected = useRef(null);
  const dataGrid = useRef(null);
  const editorOptions = useRef({
    type: "",
    operation: "",
    dataSource: {
      name: "",
      text: "",
    },
  });

  // event handlers

  function showEditor(e) {
    if (e === "ticket") {
      editorOptions.current.operation = "create";
      editorOptions.current.type = "ticket";
      editorOptions.current.dataSource = {
        subject: "Subject",
        text: "",
        parentId: null,
        typeId: 1,
      };
    } else {
      editorOptions.current.operation = "create";
      editorOptions.current.type = "message";
      editorOptions.current.dataSource = {
        subject: "Answer by IRIS Support Team",
        text: "",
        parentId: selected.current.id,
        typeId: 2,
      };
    }

    setEditor(true);
  }

  function hideEditor(e) {
    if (e) {
      dataGrid.current.refresh();
      props.onRefresh();
    }
    setEditor(false);
  }

  function onSelectionChanged(e) {
    selected.current = e.selectedRowsData[0];
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push(
      {
        location: "before",
        widget: "dxButton",
        options: {
          hint: "Add a ticket",
          icon: "fas fa-plus",
          onClick: () => {
            showEditor("ticket");
          },
        },
      },
      {
        location: "before",
        widget: "dxButton",
        options: {
          hint: "Reply a ticket",
          icon: "fas fa-reply",
          onClick: () => {
            showEditor("message");
          },
        },
      }
    );
  }

  function statusRender(e) {
    return (
      <div className={styles.status}>
        <div className={styles.status_icon}>
          <FontAwesomeIcon icon={statuses[e.data.statusId].icon1} color={statuses[e.data.statusId].color} />
        </div>
        <div className={styles.status_text}>{e.data.status}</div>
      </div>
    );
  }

  function priorityRender(e) {
    return (
      <div className={styles.priority}>
        <div className={styles.priority_icon}>
          <FontAwesomeIcon icon={faCircle} color={priorities.find((i) => i.id === e.data.priorityId).color} />
        </div>
        <div className={styles.priority_text}>{e.data.priority}</div>
      </div>
    );
  }

  function titleRender(e) {
    return (
      <div className={styles.text}>
        <div className={styles.title}>
          <div className={styles.title_icon}>
            <FontAwesomeIcon icon={types.find((i) => i.id === e.data.typeId).icon1} />
          </div>
          <div className={styles.title_text}>{e.data.subject}</div>
        </div>
        <div className={styles.title_description}>
          <div className={styles.title_description_name}>{`${e.data.type} by ${e.data.user}`}</div>
          <div className={styles.title_description_org}>{`(${e.data.organization})`}</div>
        </div>
      </div>
    );
  }

  const gridRender = useMemo(() => {
    return (
      <List
        title={"Tickets"}
        dataSource={ticketStore}
        showColumnLines={false}
        showBorders={false}
        showColumnHeaders={true}
        rowNumbering={false}
        allowDownloading={false}
        allowAdding={false}
        allowUpdating={props.allowUpdating && props.accessTicket}
        allowDeleting={props.allowDeleting && props.accessTicket}
        onSelectionChanged={onSelectionChanged}
        onToolbarPreparing={onToolbarPreparing}
        onInitialized={onInitialized}
      >
        <Column dataField={"id"} width={60} formItem={{ visible: false }} />
        <Column caption="Type" dataField={"typeId"} visible={false}>
          <Lookup dataSource={types} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Status"} dataField={"statusId"} width={135} cellRender={statusRender}>
          <Lookup dataSource={statuses} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption="Subject" dataField={"subject"} cellRender={titleRender} formItem={{ visible: false }} />
        <Column caption="Priority" dataField={"priorityId"} cellRender={priorityRender} width={100} visible={props.accessTicket}>
          <Lookup dataSource={priorities} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"User"} dataField={"userId"} calculateDisplayValue="userName" width={200} visible={false}>
          <Lookup dataSource={userStore} valueExpr={"id"} displayExpr="userName" />
          <RequiredRule />
        </Column>
        <Column caption="Created On" dataField={"createdOn"} width={125} formItem={{ visible: false }} />
      </List>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      <Editor
        dataSource={editorOptions.current.dataSource}
        type={editorOptions.current.type}
        operation={editorOptions.current.operation}
        showEditor={editor}
        hideEditor={hideEditor}
      />
      {gridRender}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    accessTicket: !!state.user.accessTicket,
  };
}

export default connect(mapStateToProps, null)(withMemo(Component, []));
