import React, { useRef } from "react";
import styles from "./form.module.scss";
import Form, { GroupItem, SimpleItem, Label } from "devextreme-react/form";
import { dict } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import { addFilter } from "helper/global";

function Component(props) {
  //
  // stores
  const nodeStatusStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/nodeStatus`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/nodeStatus/${id}` });
      },
    }),
  };

  const ownershipStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/ownership`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/ownership/${id}` });
      },
    }),
  };

  // const classStore = {
  //   store: new CustomStore({
  //     key: "id",
  //     useDefaultSearch: true,
  //     load: (loadOptions) => {
  //       return request({ url: `${url}/class`, loadOptions: loadOptions });
  //     },
  //     byKey: function (id) {
  //       return request({ url: `${url}/class/${id}` });
  //     },
  //   }),
  // };

  const typeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOption) => {
        let options_ = props.data ? addFilter(loadOption, "and", ["classId", "=", props.data.classId]) : loadOption;
        return request({ url: `${url}/type`, loadOptions: options_ });
      },
      byKey: function (id) {
        return request({ url: `${url}/type/${id}` });
      },
    }),
  };

  const sizeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOption) => {
        let options_ = props.data ? addFilter(loadOption, "and", ["classId", "=", props.data.classId]) : loadOption;
        return request({ url: `${url}/size`, loadOptions: options_ });
      },
      byKey: function (id) {
        return request({ url: `${url}/size/${id}` });
      },
    }),
  };

  const materialStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/material`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/material/${id}` });
      },
    }),
  };

  const manufacturerStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/manufacturer/${id}` });
      },
    }),
  };

  const strategyStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/strategy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/strategy/${id}` });
      },
    }),
  };

  const costStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cost`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cost/${id}` });
      },
    }),
  };

  // hooks

  const form = useRef(null);

  function onFieldDataChanged(e) {
    props.onFieldDataChanged && props.onFieldDataChanged();
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <Form
          id={"form"}
          ref={form}
          formData={props.data}
          scrollingEnabled={true}
          showColonAfterLabel={false}
          showRequiredMark={false}
          labelLocation={"top"}
          onFieldDataChanged={onFieldDataChanged}
        >
          <GroupItem colCount={1}>
            <GroupItem colCount={2} colSpan={1}>
              <SimpleItem dataField="name">
                <Label text={dict("AssetId")} />
              </SimpleItem>
              <SimpleItem dataField="description">
                <Label text={dict("Description")} />
              </SimpleItem>

              <SimpleItem dataField="location">
                <Label text={dict("Location")} />
              </SimpleItem>
              <SimpleItem
                dataField="ownershipId"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: ownershipStore,
                  valueExpr: "id",
                  displayExpr: "name",
                  showClearButton: true,
                  searchEnabled: true,
                }}
              >
                <Label text={dict("Ownership")} />
              </SimpleItem>
              <SimpleItem
                dataField="statusId"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: nodeStatusStore,
                  valueExpr: "id",
                  displayExpr: "name",
                  showClearButton: true,
                  searchEnabled: true,
                }}
              >
                <Label text={dict("Status")} />
              </SimpleItem>
              <SimpleItem
                dataField="class"
                //editorType="dxSelectBox"
                editorOptions={{ readOnly: true }}
                //editorOptions={{ dataSource: classStore, valueExpr: "id", displayExpr: "name", calculateDisplayValue: "class", searchEnabled: true }}
              >
                <Label text={dict("Class")} />
              </SimpleItem>
              <SimpleItem
                dataField="typeId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: typeStore, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
              >
                <Label text={dict("Type")} />
              </SimpleItem>
              <SimpleItem
                dataField="sizeId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: sizeStore, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
              >
                <Label text={dict("Size")} />
              </SimpleItem>
              <SimpleItem
                dataField="materialId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: materialStore, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
              >
                <Label text={dict("Material")} />
              </SimpleItem>
              <SimpleItem
                dataField="manufacturerId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: manufacturerStore, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
              >
                <Label text={dict("Manufacturer")} />
              </SimpleItem>
              <SimpleItem dataField="model">
                <Label text={dict("Model")} />
              </SimpleItem>
              <SimpleItem dataField="count" editorType={"dxNumberBox"}>
                <Label text={dict("Count")} />
              </SimpleItem>
              <SimpleItem dataField="installYear" editorType={"dxNumberBox"}>
                <Label text={dict("Install Year")} />
              </SimpleItem>
              <SimpleItem dataField="usefulLife" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Useful Life")} />
              </SimpleItem>
              <SimpleItem
                dataField="strategyId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: strategyStore, valueExpr: "id", displayExpr: "name", searchEnabled: true }}
              >
                <Label text={dict("Strategy")} />
              </SimpleItem>
              <SimpleItem
                dataField="costId"
                editorType="dxSelectBox"
                editorOptions={{ dataSource: costStore, valueExpr: "id", displayExpr: "name", showClearButton: true, searchEnabled: true }}
              >
                <Label text={dict("Cost")} />
              </SimpleItem>
              <SimpleItem dataField="costOverride" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Cost Override")} />
              </SimpleItem>
              {/* <SimpleItem dataField="pofOverride" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Pof Override")} />
              </SimpleItem> */}
              <SimpleItem dataField="cof" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Cof")} />
              </SimpleItem>
              <SimpleItem dataField="standby" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Standby")} />
              </SimpleItem>
              <SimpleItem dataField="r" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("R")} />
              </SimpleItem>
              <SimpleItem dataField="lastYear" editorType={"dxNumberBox"} editorOptions={{ showClearButton: true }}>
                <Label text={dict("Last Year")} />
              </SimpleItem>
              <SimpleItem dataField="overrideActivityCost">
                <Label text={dict("Override Activity Cost")} />
              </SimpleItem>
              {/* <SimpleItem /> */}
              <SimpleItem dataField="notes" editorType="dxTextArea" colSpan={2} editorOptions={{ height: 75 }}>
                <Label text={dict("Notes")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>
          <SimpleItem />
        </Form>
      </div>
    </div>
  );
}

export default Component;
