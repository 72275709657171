import React from "react";
import Asset from "./asset/asset";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";

function Component(props) {
  return (
    <Hierarchy
      allowEditing={false}
      allowDragging={false}
      showCount={true}
      showFilter={true}
      filterFields={[
        "Class",
        "Type",
        "Size",
        "Material",
        "Manufacturer",
        "Status",
        "Ownership",
        "Group",
        "Tag",
        "Risk",
        "Condition",
        "CoF",
        "Install Year",
      ]}
      filterType={1}
      countUrl={`${url}/scenarionode/count`}
    >
      <Asset />
    </Hierarchy>
  );
}

export default Component;
