import React, { useRef } from "react";
import Tabs from "devextreme-react/tabs";
import "./tabs.scss";

function Component(props) {
  const tabs = useRef(null);
  //
  return (
    <div className={"components-tabs "} style={{ width: `${props.tabItems.length * (props.tabLength || 200)}px` }}>
      <Tabs
        dataSource={props.tabItems}
        width={"100%"}
        keyExpr={"path"}
        onInitialized={(e) => {
          tabs.current = e.component;
        }}
        onItemClick={(e) => {
          props.changeView(e.itemData);
        }}
        onContentReady={(e) => {
          tabs.current.option("selectedItemKeys", [props.selectedItem]);
        }}
      ></Tabs>
    </div>
  );
}

export default Component;
