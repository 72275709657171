import React, { useState, useMemo } from "react";
import styles from "./style.module.scss";
import { Popup } from "devextreme-react/popup";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { dict } from "helper/global";
import ImgTileView from "components/tileview/tileview";
import DocTileView from "components/doc-tileview/doc-tileview";
import { Button } from "devextreme-react/button";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const [expand, setExpand] = useState(props.showExpanded || false);

  // event handler

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  //render

  function imageRender() {
    return (
      <ImgTileView
        title={state.name}
        urls={{
          read: `${props.urls.image.read}/${state.id}`,
          delete: `${props.urls.image.delete}`,
          upload: `${props.urls.image.upload}/${state.id}`,
        }}
      />
    );
  }

  function documentRender() {
    return (
      <DocTileView
        title={state.name}
        allowEditing={true}
        urls={{
          read: `${props.urls.document.read}/${state.id}`,
          delete: `${props.urls.document.delete}`,
          upload: `${props.urls.document.upload}/${state.id}`,
        }}
      />
    );
  }

  function tabsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
        <Item title={dict("Image")} render={imageRender} />
        <Item title={dict("Document")} render={documentRender} />
      </TabPanel>
    );
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      widget: "dxButton",

      options: {
        hint: "Expand",
        icon: "fas fa-expand-alt",
        onClick: () => {
          setExpand(true);
        },
      },
    });
  }

  function titleRender() {
    return (
      <div className={styles.header}>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.button}>
          <Button
            hint={"Close"}
            icon={"close"}
            onClick={() => {
              setExpand(false);
            }}
          />
        </div>
      </div>
    );
  }

  const popupGridRender = useMemo(() => {
    return React.cloneElement(props.children, {
      onSelectionChanged: onSelectionChanged,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expand]);

  const gridRender = (() => {
    return (
      !expand &&
      React.cloneElement(props.children, {
        onToolbarPreparing: onToolbarPreparing,
      })
    );
  })();

  return (
    <div className={styles.main}>
      {gridRender}
      {expand && (
        <Popup
          visible={expand}
          onHiding={() => {
            setExpand(false);
          }}
          showCloseButton={true}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          width={1080}
          height={900}
          titleRender={titleRender}
        >
          <div className={styles.main}>
            <div className={styles.grid}>{popupGridRender}</div>
            <div className={styles.tabs}>{state.id && tabsRender()}</div>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default Component;
