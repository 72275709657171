import React, { useEffect, useRef, useState } from "react";
import { Circle } from "rc-progress";
import { Button } from "devextreme-react/button";
import { connect } from "react-redux";
import styles from "./progress-panel.module.scss";
import HierarchyContext from "./context";
import { url } from "config.js";
import { request } from "helper/http-client";

function Component(props) {
  //
  // useRef
  const processing = useRef(false);
  const state = useRef(null);

  // useEffect
  useEffect(() => {
    if (!processing.current && props.progressName === props.name) {
      processing.current = true;
      return;
    }

    if (processing.current && !props.progressId) {
      props.onComplete && props.onComplete();
      processing.current = false;
    }
  });

  // functions

  function onStateChanged(e) {
    state.current = e;
  }

  return props.name === props.progressName ? (
    <div className={styles.main}>
      <Progress progressId={props.progressId} onCancel={props.progressOnCancel} />
    </div>
  ) : (
    <HierarchyContext.Provider value={{ state: state.current, onStateChanged: onStateChanged }}>
      <div className={styles.main}>{props.children}</div>
    </HierarchyContext.Provider>
  );
}

const mapStateToProps = (state) => {
  return {
    progressName: state.progress.name,
    progressId: state.progress.id,
    progressOnCancel: state.progress.onCancel,
  };
};

export default connect(mapStateToProps, null)(Component);

const Progress = (props) => {
  //
  //  useState
  const [state, setState] = useState({ progress: 0 });

  // useEffect

  useEffect(() => {
    var interval = setInterval(async () => {
      const result = await request({ url: `${url}/backgroundtask/${props.progressId}` });
      if (result) {
        setState(result);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useRef

  // event handlers

  function onCancel() {
    props.onCancel && props.onCancel();
  }

  return (
    <div className={styles.jobprogress}>
      <div className={styles.jobprogress_container}>
        <div className={styles.jobprogress_circle}>
          <Circle percent={state.progress || 0} strokeWidth="6" strokeLinecap="round" strokeColor={"#98c21f"} />
        </div>
        <div className={styles.jobprogress_circle}>{`${state.progress || 0}%`}</div>
        <div className={styles.jobprogress_button}>
          <Button height={25} width={35} text="Cancel" type="normal" stylingMode="outlined" onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};
