import React, { useState, useEffect } from "react";
import styles from "./rep-cost-distribution.module.scss";
import PieChart from "components/piechart/piechart";
import { currencyFormat } from "helper/global";
import { Series, Label, Connector, AdaptiveLayout, Legend } from "devextreme-react/chart";
import { url } from "config.js";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";

function CostDistribution(props) {
  //
  // event handlers

  function customizeText(arg) {
    return currencyFormat(arg.value);
  }

  // hooks

  const [state, setstate] = useState(null);

  useEffect(() => {
    if (props.selectedRowsData.length === 0) setstate(null);
    (async () => {
      try {
        var result = await request({
          url: `${url}/activitycostprofile/replacementdistribution`,
          params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter },
        });
        setstate(result);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRowsData, props.filter]);

  // render

  function innerDonutRender(pieChart) {
    var cost = pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0);

    if (!cost) return null;
    return (
      <svg>
        <text textAnchor="middle" x="0" y="50" style={{ fontSize: 20, fill: "# #adadad" }}>
          <tspan x="100">{currencyFormat(cost)}</tspan>
        </text>
      </svg>
    );
  }

  function chartRender() {
    return (
      <PieChart dataSource={state} type="donut" innerRadius={0.65} centerRender={innerDonutRender}>
        <Series argumentField="name" valueField="value" hoverMode="none">
          <Label visible={true} position="columns" customizeText={customizeText}>
            <Connector visible={true} width={1} />
          </Label>
        </Series>
        <Legend position="outside" orientation="vertical" verticalAlignment="top" horizontalAlignment="right" />
        <AdaptiveLayout height={500} width={500} />
      </PieChart>
    );
  }

  return (
    <div className={styles.main}>
      {state ? (
        <div className={styles.chart}>{chartRender()}</div>
      ) : (
        <div className={styles.loading}>
          <LoadIndicator />
        </div>
      )}
    </div>
  );
}

export default CostDistribution;
