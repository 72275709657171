import React, { useState, useRef } from "react";
import styles from "./asset.module.scss";
import { currency, withMemo } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/data-grid/data-grid.js";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import MultiEditor from "components/multi-editor/multi-editor";

function Component(props) {
  //
  // hooks

  const [editor, setEditor] = useState(false);
  const loadOptions = useRef(null);

  // event handlers

  function onEditor() {
    setEditor(true);
  }

  function onLoadOptions(e) {
    loadOptions.current = e;
  }

  // render

  return (
    <MultiEditor
      options={{
        url: `${url}/multinode/project/${props.projectId}`,
        loadOptions: loadOptions.current,
      }}
      visible={editor}
      onHiding={() => {
        setEditor(false);
      }}
    >
      <Asset projectId={props.projectId} onLoadOptions={onLoadOptions} onEditor={onEditor} />
    </MultiEditor>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

function mapStateToProps(state) {
  return {
    multiEditNode: !!state.user.accessMultiEditNode,
  };
}

export default connect(null, mapDispatchToProps)(Component);

const Asset = connect(
  mapStateToProps,
  null
)(
  withMemo(
    (props) => {
      //
      // stores

      const assetStore = {
        store: new CustomStore({
          key: "id",
          load: (loadOptions) => {
            props.onLoadOptions(loadOptions);
            if (!props.projectId) return Promise.resolve({ data: [] });
            return exporting.current
              ? request({
                  url: `${url}/scenarionodeproject/project/${props.projectId}`,
                  loadOptions: loadOptions,
                })
              : request({
                  url: `${url}/scenarionodeproject/project/${props.projectId}`,
                  loadOptions: loadOptions,
                });
          },
          insert: (data) => {
            return request({ url: `${url}/scenarionodeproject`, method: "Post", data: { ...data, projectId: props.projectId } });
          },
          update: (_, data) => {
            return request({ url: `${url}/scenarionodeproject`, method: "Put", data: data });
          },
          remove: (id) => {
            return request({ url: `${url}/scenarionodeproject/scenarionode/${id}/project/${props.projectId}`, method: "Delete" });
          },
        }),
      };

      const scenarioNodeStore = {
        store: new CustomStore({
          key: "id",
          useDefaultSearch: true,
          load: (loadOptions) => {
            return request({ url: `${url}/scenarioNode/lookup`, loadOptions: loadOptions });
          },
          byKey: function (id) {
            return request({ url: `${url}/scenarioNode/${id}` });
          },
        }),
      };

      const activityStore = (options) => {
        return {
          store: new CustomStore({
            key: "id",
            useDefaultSearch: true,
            load: (loadOption) => {
              return request({ url: `${url}/activity/scenarionode/${options.data.scenarioNodeId}`, loadOptions: loadOption });
            },
            byKey: function (id) {
              return request({ url: `${url}/activity/${id}` });
            },
          }),
        };
      };

      // hooks

      const exporting = useRef(false);

      // event handlers

      function onExporting(e) {
        e.component.columnOption("id", "visible", true);
        e.component.columnOption("parent", "visible", true);
        e.component.columnOption("operation", "visible", true);
        e.component.columnOption("project", "visible", true);
        e.component.columnOption("description", "visible", false);
        e.component.columnOption("location", "visible", false);
        e.component.columnOption("ownership", "visible", false);
        e.component.columnOption("status", "visible", false);
        e.component.columnOption("class", "visible", false);
        e.component.columnOption("type", "visible", false);
        e.component.columnOption("size", "visible", false);
        e.component.columnOption("material", "visible", false);
        e.component.columnOption("manufacturer", "visible", false);
        e.component.columnOption("model", "visible", false);
        e.component.columnOption("count", "visible", false);
        e.component.columnOption("installYear", "visible", false);
        e.component.columnOption("usefulLife", "visible", false);
        e.component.columnOption("strategy", "visible", false);
        e.component.columnOption("cost", "visible", false);
        e.component.columnOption("costOverride", "visible", false);
        e.component.columnOption("pofOverride", "visible", false);
        e.component.columnOption("cof", "visible", false);
        e.component.columnOption("r", "visible", false);
        e.component.columnOption("standby", "visible", false);
        e.component.columnOption("overrideActivityCost", "visible", false);
        e.component.columnOption("lastYear", "visible", false);
        e.component.columnOption("notes", "visible", false);
        exporting.current = true;
      }

      function onExported(e) {
        e.component.columnOption("id", "visible", false);
        e.component.columnOption("parent", "visible", false);
        e.component.columnOption("operation", "visible", false);
        e.component.columnOption("project", "visible", false);
        e.component.columnOption("description", "visible", false);
        e.component.columnOption("location", "visible", true);
        e.component.columnOption("ownership", "visible", true);
        e.component.columnOption("status", "visible", true);
        e.component.columnOption("class", "visible", true);
        e.component.columnOption("type", "visible", true);
        e.component.columnOption("size", "visible", true);
        e.component.columnOption("material", "visible", true);
        e.component.columnOption("manufacturer", "visible", true);
        e.component.columnOption("model", "visible", true);
        e.component.columnOption("count", "visible", true);
        e.component.columnOption("installYear", "visible", true);
        e.component.columnOption("usefulLife", "visible", true);
        e.component.columnOption("strategy", "visible", true);
        e.component.columnOption("cost", "visible", true);
        e.component.columnOption("costOverride", "visible", true);
        e.component.columnOption("pofOverride", "visible", true);
        e.component.columnOption("cof", "visible", true);
        e.component.columnOption("r", "visible", true);
        e.component.columnOption("standby", "visible", true);
        e.component.columnOption("overrideActivityCost", "visible", true);
        e.component.columnOption("lastYear", "visible", true);
        e.component.columnOption("notes", "visible", true);
        exporting.current = false;
      }

      function onSelectionChanged(e) {
        props.onSelectionChanged && props.onSelectionChanged(e);
      }

      async function onUploaded(fileId) {
        props.upload({ name: "UPLOAD_SCENARIONODEPROJECT", url: `${url}/scenarionodeproject/upload/file/${fileId}` });
      }

      // render

      function onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          visible: props.multiEditNode,
          options: {
            hint: "MultiEdit",
            icon: "fas fa-edit",
            onClick: props.onEditor,
          },
        });
      }

      return (
        <ProgressPanel name={"UPLOAD_SCENARIONODEPROJECT"}>
          <div className={styles.main}>
            <Grid
              title={"Asset"}
              dataSource={assetStore}
              selection={{ mode: "single" }}
              onSelectionChanged={onSelectionChanged}
              onExported={onExported}
              onExporting={onExporting}
              onUploaded={onUploaded}
              onToolbarPreparing={onToolbarPreparing}
              allowExpanding={true}
            >
              <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
              <Column
                caption={"Operation"}
                dataField={"operation"}
                calculateCellValue={() => "Update"}
                visible={false}
                formItem={{ visible: false }}
              />
              <Column caption={"Project"} dataField={"project"} allowEditing={false} width={200} visible={false} formItem={{ visible: false }} />
              <Column caption={"AssetId"} dataField={"id"} calculateDisplayValue="name" width={200}>
                <Lookup dataSource={scenarioNodeStore} valueExpr={"id"} displayExpr="name" />
              </Column>
              <Column caption={"Description"} dataField={"description"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Activity Name"} dataField={"activityId"} calculateDisplayValue="activityName" width={200}>
                <Lookup dataSource={activityStore} valueExpr={"id"} displayExpr="name" />
              </Column>
              <Column
                caption={"Activity Description"}
                dataField={"activityDescription"}
                allowEditing={false}
                width={200}
                formItem={{ visible: false }}
              />
              <Column caption={"Activity Type"} dataField={"activityType"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Location"} dataField={"location"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Ownership"} dataField={"ownership"} width={200} allowEditing={false} formItem={{ visible: false }} />
              <Column caption={"Status"} dataField={"status"} width={200} allowEditing={false} formItem={{ visible: false }} />
              <Column caption={"Class"} dataField={"class"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Type"} dataField={"type"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Size"} dataField={"size"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Material"} dataField={"material"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Manufacturer"} dataField={"manufacturer"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Model"} dataField={"model"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Count"} dataField={"count"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Install Year"} dataField={"installYear"} allowEditing={false} width={200} formItem={{ visible: false }} />
              <Column caption={"Useful Life"} dataField={"usefulLife"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column caption={"Strategy"} dataField={"strategy"} width={200} formItem={{ visible: false }} />
              <Column caption={"Cost"} dataField={"cost"} width={200} formItem={{ visible: false }} />
              <Column
                caption={"Cost Override"}
                dataField={"costOverride"}
                dataType={"number"}
                width={100}
                format={`${currency()}#,###`}
                formItem={{ visible: false }}
              />
              <Column caption={"Pof Override"} dataField={"pofOverride"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column caption={"Cof"} dataField={"cof"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column caption={"R"} dataField={"r"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column caption={"Standby"} dataField={"standby"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column caption={"Last Year"} dataField={"lastYear"} dataType={"number"} width={100} formItem={{ visible: false }} />
              <Column
                caption={"Override Activity Cost"}
                dataField={"overrideActivityCost"}
                dataType={"boolean"}
                width={100}
                formItem={{ visible: false }}
              />
              <Column caption={"Notes"} dataField={"notes"} width={400} allowEditing={false} formItem={{ visible: false }} />
            </Grid>
          </div>
        </ProgressPanel>
      );
    },
    ["projectId"]
  )
);
