import React, { useState, useRef, useEffect } from "react";
import styles from "./scenario.module.scss";
import { url } from "config.js";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";
import DxForm, { GroupItem, SimpleItem, Label, RequiredRule, NumericRule } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { dict, notify } from "helper/global";

function Component() {
  //
  // hooks

  const [state, setState] = useState(null);

  useEffect(() => {
    getScenario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const form = useRef();

  // functions

  function getScenario() {
    (async () => {
      let result = await request({ url: `${url}/scenario` });
      setState(result);
    })();
  }

  // event handlers

  async function onSave() {
    try {
      await request({ url: `${url}/scenario`, method: "Put", data: state });
      window.location.reload(false);
      notify(dict("Scenario updated successfully"), "success");
    } catch (error) {
      console.log("error");
    }
  }

  // render

  function buttonRender() {
    return (
      <div>
        <Button text={dict("Save")} type={"success"} stylingMode="outlined" width={100} onClick={onSave} />
      </div>
    );
  }

  function formRender() {
    return (
      <div className={styles.main}>
        <form>
          <DxForm
            // ref={form}
            formData={state || {}}
            labelLocation={"top"}
            minColWidth={250}
            width={1000}
            showColonAfterLabel={true}
            showRequiredMark={false}
          >
            <GroupItem caption="">
              <GroupItem colCount={4}></GroupItem>
            </GroupItem>
            <GroupItem colCount={4}>
              <GroupItem caption={dict("Projection")}>
                <SimpleItem dataField="planningHorizon">
                  <Label text={dict("Planning Horizon")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="currentYear">
                  <Label text={dict("Current Year")} />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("Condition")}>
                <SimpleItem dataField="worstCondition">
                  <Label text={`${dict("Worst")} ${dict("Condition")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="bestCondition">
                  <Label text={`${dict("Best")} ${dict("Condition")}`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="shapeFactor">
                  <Label text={dict("Shape Factor")} />
                  <RequiredRule message="Required field" />
                  <NumericRule message="Must be a real number" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("Risk")}>
                <SimpleItem dataField="standbyFactor">
                  <Label text={`${dict("Standby Factor")}`} />
                  <RequiredRule message="Required field" />
                  <NumericRule message="Must be a real number" />
                </SimpleItem>
              </GroupItem>
              <GroupItem caption={dict("Financial")}>
                <SimpleItem dataField="escalation">
                  <Label text={dict("Escalation")} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
                <SimpleItem dataField="standardDeviation">
                  <Label text={`${dict("Standard Deviation")} (${dict("Smoothing")})`} />
                  <RequiredRule message="Required field" />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
            <SimpleItem render={buttonRender} />
          </DxForm>
        </form>
      </div>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  return state ? formRender() : loadingRender();
}

export default Component;
