import React, { useContext } from "react";
import styles from "./activity.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import { withMemo } from "helper/global";
import HierarchyContext from "components/hierarchy/context";
import ActivityGrid from "components/activity-grid/activity-grid";

function Component(props) {
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;
  return <Activity selectedRowsData={selectedRowsData} filter={filter} onToolbarPreparing={props.onToolbarPreparing} />;
}

export default Component;

const Activity = withMemo(
  (props) => {
    // store

    const assetStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({
            url: `${url}/activitycostprofile/activities`,
            params: {
              ids: props.selectedRowsData.map((i) => i.id),
              filterExpression: props.filter,
            },
            loadOptions: loadOptions,
          });
        },
      }),
    };

    // render

    return (
      props.selectedRowsData.length > 0 && (
        <div className={styles.main}>
          <div className={styles.container}>
            <ActivityGrid
              title={"Activity"}
              showTitle={false}
              dataSource={assetStore}
              allowEditing={false}
              rowNumbering={true}
              resetRowSelectionOnLoad={true}
              onToolbarPreparing={props.onToolbarPreparing}
            ></ActivityGrid>
          </div>
        </div>
      )
    );
  },
  ["selectedRowsData", "filter"]
);
