import React, { useState } from "react";
import styles from "./strategy.module.scss";
import CustomStore from "devextreme/data/custom_store";
import Grid from "components/list/list";
import { request } from "helper/http-client";
import Activity from "../activity/activity";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  return (
    <div className={styles.main}>
      <div className={styles.strategy}>
        <Strategy strategyGroupId={props.strategyGroupId} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>{<Activity strategyGroupId={props.strategyGroupId} strategyId={state.id} />}</div>
    </div>
  );
}

export default Component;

const Strategy = React.memo(
  (props) => {
    // stores

    const strategyStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          if (!props.strategyGroupId) return Promise.resolve({ data: [] });
          return request({ url: `${url}/strategy/strategygroup/${props.strategyGroupId}`, loadOptions: loadOptions });
        },
      }),
    };

    // event handlers

    return (
      <Grid
        title={"Strategy"}
        dataSource={strategyStore}
        selection={{ mode: "single" }}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={false}
        allowExpanding={true}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Operation"} dataField={"operation"} calculateCellValue={() => "Update"} visible={false} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column
          caption={"Useful Life"}
          dataField={"usefulLife"}
          dataType={"number"}
          width={150}
          formItem={{ editorOptions: { showClearButton: true } }}
        ></Column>
        <Column
          caption={"Notes"}
          dataField={"notes"}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  },
  (prev, next) => {
    return prev.strategyGroupId === next.strategyGroupId;
  }
);
