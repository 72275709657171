import React, { useRef, useState, useContext, useMemo } from "react";
import styles from "./activity-grid.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { currency, dict } from "helper/global";
import Grid from "components/data-grid/data-grid.js";
import HierarchyContext from "components/hierarchy/context";
import { Column } from "devextreme-react/data-grid";
import Summary from "../summary/summary";
import { TabPanel, Item } from "devextreme-react/tab-panel";

function Component(props) {
  //
  // stores
  //
  const activityStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.projectId) return Promise.resolve([]);
        return request({
          url: `${url}/projectactivity`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            year: props.year,
            projectId: props.projectId,
            filterExpression: filter,
          },
          loadOptions: loadOptions,
        });
      },
      update: (_, data) => {
        return request({
          url: `${url}/projectactivity`,
          method: "Put",
          data: { ...data, projectId: props.projectId },
        });
      },
    }),
  };

  // hooks
  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;
  //
  // hooks

  const initialState = {
    activityCostProfileId: null,
    assetId: null,
  };

  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }
  // render

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        hint: "Back",
        type: "back",
        onClick: props.onBackButtonClick,
      },
    });
  }

  function summaryRender() {
    return <Summary activityId={state.activityCostProfileId} assetId={state.assetId} />;
  }

  const gridRender = useMemo(() => {
    return (
      <div className={styles.activity}>
        <Grid
          dataSource={activityStore}
          title={`Activity Year: ${props.year}`}
          showTitle={true}
          selection={{ mode: "single" }}
          allowAdding={false}
          allowUpdating={true}
          allowDeleting={false}
          allowDownloading={false}
          showButtons={false}
          editingMode={"batch"}
          onToolbarPreparing={onToolbarPreparing}
          onSelectionChanged={onSelectionChanged}
          onSaved={props.onUpdated}
        >
          <Column visible={false} dataField={"id"} allowEditing={false} />
          <Column visible={false} dataField={"activityId"} allowEditing={false} />
          <Column caption={"AssetId"} dataField={"name"} width={200} allowEditing={false} />
          <Column
            caption={"Asset Description"}
            dataField={"description"}
            allowEditing={false}
            // width={400}
          />
          {/* <Column caption={"Location"} dataField={"location"} width={200} />
  <Column caption={"Year"} dataField={"year"} width={75} /> */}
          <Column caption={"Activity Name"} dataField={"activityName"} width={200} allowEditing={false} />
          {/* <Column
    caption={"Activity Type"}
    dataField={"activityType"}
    width={200}
  /> */}
          <Column caption={"Activity Description"} dataField={"activityDescription"} width={200} allowEditing={false} />
          <Column caption={"Activity Cost"} dataField={"activityCost"} width={200} format={`${currency()}#,###`} allowEditing={false} />
          <Column
            caption={""}
            dataField={"selected"}
            dataType="boolean"
            width={200}
            allowFiltering={false}
            sortOrder={"desc"}
            sortIndex={1}
          />
        </Grid>
      </div>
    );
  }, [selectedRowsData, filter, props.projectId, props.render]);

  return (
    <div className={styles.main}>
      {gridRender}
      <div className={styles.summary}>
        <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
          <Item title={dict("Summary")} render={summaryRender} />
        </TabPanel>
      </div>
    </div>
  );
}

export default Component;
