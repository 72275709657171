import axios from "axios";
import { api } from "config.js";
import { store } from "index.js";
import { error as throwError, logout } from "redux/actions/auth";
import { confirm } from "devextreme/ui/dialog";
import { notify } from "helper/global";

axios.defaults.withCredentials = true;

// axios.interceptors.response.use(
//   // function (e) {
//   //   console.log("%cNO ERROR", "color:green", e.response);
//   //   return e;
//   // }
//   undefined,
//   function (e) {
//     if (e.response) {
//       if (e.response.status === 401) {
//         store.dispatch(logout());
//       } else {
//         return Promise.reject(e);
//       }
//     }
//     // else {
//     //   store.dispatch(error("Network Error"));
//     //   return Promise.reject(e);
//     // }
//   }
// );

axios.interceptors.response.use(
response => {return response},
error => {

  if(error.response) {
    if (error.response.status === 400)
    return Promise.reject(error)

  if (error.response.status === 401) {
    store.dispatch(logout())
  }
  }
  else{
    error.response = "UnExpected Error"
  }

 if (!error.response)
    error.response = "Network Error"


  store.dispatch(throwError(`Request Url: ${error.request.responseURL} ${error}`))
  return Promise.reject(error)
}
)

//

export function requestBlob(options) {
  let { url, params = null, data = null } = options;

  url = `${api}/${url}`;

  if (params) url = url + "?" + getQueryParams(params);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  function onCancel(e) {
    source.cancel(e);
  }

  var promise = axios({
    method: "Post",
    url: url,
    data: data,
    cancelToken: source.token,
    responseType: "blob",
  })
    .then((response) => {
      if (response.data.size > 0) {
        let result = confirm("<i>Operation failed. Do you want to download the error log file?</i>", "Error");
        result.then((dialogResult) => {
          if (dialogResult) {
            url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "errorlog.txt");
            document.body.appendChild(link);
            link.click();
            notify("File downloading", "success", 600);
          }
        });
      }
    })
    .catch((error) => {
      // if (axios.isCancel(error)) {
      //   notify("Operation cancelled by user", "error");
      //   return;
      // }
      if (error.response) {
        throw Error(error.response.data);
      } else if (error.request) {
        throw Error(error.request);
      } else {
        throw Error(error.message);
      }
    });

  return [promise, onCancel];
}

//

export function requestJob(url) {
  url = `${api}/${url}`;

  return axios({
    method: "Post",
    url: url,
  }).catch((error) => {
    if (error.response) {
      throw Error(error.response.data);
    } else if (error.request) {
      throw Error(error.request);
    } else {
      throw Error(error.message);
    }
  });
}

//

export function request(options) {
  if (!options.url) {
    console.log("OPTIONS", options);
  }
  let { url, method = "Get", data = null, loadOptions = null, params = null, token = null } = options;

  url = `${api}/${url}`;
  var dev = process.env.REACT_APP_CUSTOM_NODE_ENV === "Development";

  if (params) url = url.includes("?") ? url + getQueryParams(params) : url + "?" + getQueryParams(params);

  if (loadOptions) {
    let p = "";
    ["skip", "take", "sort", "filter"].forEach(function (i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        p += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    p = p.slice(0, -1);
    url = url + (url.includes("?") ? "&" + p : "?" + p);
  }

  if (dev) console.log(`METHOD: ${method}`, "REQUEST URL: ", `${url}`);

  return axios({
    method: method,
    url: url,
    data: data,
    withCredentials: true,
    headers: token && { Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      if (dev) {
        console.log("REQUEST RESULT", response.data);
    }
      return response.data;
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        throw Error(error.response.data);
      } else if (error.request) {
        throw Error(error.request);
      } else {
        throw Error(error.message);
      }
    });
}

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export function getQueryParams(params) {
  let p = [];
  for (let key in params) {
    if (Array.isArray(params[key])) params[key].map((i) => p.push(`${key}=${i}`));
    else p.push(`${key}=${params[key]}`);
  }
  return p.join("&");
}
