import React, { useState, useRef, useMemo } from "react";
import styles from "./project-snapshot.module.scss";
import ActivityGrid from "./activity-grid/activity-grid";
import ActivityMap from "./activity-map/activity-map";
import { TabPanel, Item as TabItem } from "devextreme-react/tab-panel";
import { dict, withMemo } from "helper/global";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, FilterRow } from "devextreme-react/data-grid";
import List from "components/list/list";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  // hooks
  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const project = useRef(null);

  // event handlers

  function onSelectionChanged(e) {
    if (e.selectedRowsData[0]) {
      var p = e.selectedRowsData[0];
      setState(p);
      project.current = p;
    } else setState(initialState);
  }

  // render

  function activityGridRender() {
    return state.id && <ActivityGrid projectId={state.id} />;
  }

  function activityMapRender() {
    return state.id && <ActivityMap projectId={state.id} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.project}>
          <Project onSelectionChanged={onSelectionChanged} projectId={props.projectId} onBackButtonClick={props.onBackButtonClick} />
        </div>
        <div className={styles.activity}>
          <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
            <TabItem title={dict("Table")} render={activityGridRender} />
            <TabItem title={dict("Map")} render={activityMapRender} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default Component;

const Project = withMemo((props) => {
  //
  // stores

  const projectStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        var path = props.projectId ? `projectsnapshot/project/${props.projectId}` : `projectsnapshot`;
        return request({ url: `${url}/${path}`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return request({ url: `${url}/projectsnapshot`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/projectsnapshot/${id}`, method: "Delete" });
      },
    }),
  };

  function onSelectionChanged(e) {
    props.onSelectionChanged && props.onSelectionChanged(e);
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      visible: !!props.onBackButtonClick,
      options: {
        hint: "Back",
        type: "back",
        onClick: props.onBackButtonClick,
      },
    });
  }

  return (
    <List
      title={"Project Snapshot"}
      dataSource={projectStore}
      selection={{ mode: "single" }}
      resetRowSelectionOnLoad={true}
      allowAdding={false}
      allowUpdating={true}
      allowDeleting={true}
      allowDownloading={false}
      onSelectionChanged={onSelectionChanged}
      onToolbarPreparing={onToolbarPreparing}
    >
      <FilterRow visible={true} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={400}>
        <RequiredRule />
      </Column>
      <Column caption={"Description"} dataField={"description"} visible={false} width={400} />
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </List>
  );
}, []);
