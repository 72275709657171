import React, { useEffect, useState } from "react";
import styles from "./condition-profile.module.scss";
import Chart from "components/chart/chart";
import { url } from "config.js";
import { connect } from "react-redux";
import {
  CommonSeriesSettings,
  Series,
  Pane,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
  Annotation,
} from "devextreme-react/chart";
import { currency, dict } from "helper/global";
import { request } from "helper/http-client";

function Component(props) {
  //
  // hooks
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        var result = await request({
          url: `${url}/activitylogprofile/${props.nodeId}`,
        });
        setData(result);
      } catch (error) {
        setData(null);
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nodeId]);

  // event handlers

  function customizeText(e) {
    return { text: `Year: ${e.argument}, Value: ${e.value.toFixed(1)}` };
  }

  // render
  return (
    data && (
      <div className={styles.main}>
        <Chart dataSource={data}>
          <CommonSeriesSettings argumentField="year" />
          <Pane name="topPane" visible={false} />
          <Pane name="bottomPane" />
          <Legend visible={false} position="inside" />
          <Tooltip enabled={true} customizeTooltip={customizeText} />
          <ArgumentAxis allowDecimals={false} minorTickCount={4}>
            <MinorTick visible={false} />
            <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <ValueAxis
            name="condition"
            inverted={true}
            minValueMargin={0}
            maxValueMargin={0}
            title={dict("Condition")}
            tickInterval={1}
            wholeRange={[props.minCondition, props.maxCondition]}
            visualRange={[props.minCondition, props.maxCondition]}
          />
          <ValueAxis name="cost" minValueMargin={0} maxValueMargin={0} title={`${currency()}`}></ValueAxis>
          <Series valueField="condition" pane="topPane" type="spline" name="condition" axis="condition">
            <Point visible={false} />
          </Series>
          <Series valueField="cost" pane="bottomPane" type="bar" name={"Cost"} axis="cost" showInLegend={false} />
          {data.length > 1 &&
            data
              .filter((i) => i.name !== "")
              .map(function (item) {
                return (
                  <Annotation
                    key={item.year}
                    argument={item.year}
                    series={"condition"}
                    type={"text"}
                    text={`${item.name} (${item.year})`}
                    color={"#bb86fc"}
                    opacity={0.75}
                    arrowWidth={20}
                  ></Annotation>
                );
              })}
        </Chart>
      </div>
    )
  );
}

const mapStateToProps = (state) => {
  return {
    maxCondition: state.scenario.worstCondition,
    minCondition: state.scenario.bestCondition,
  };
};

export default connect(mapStateToProps, null)(Component);
