import React from "react";
import styles from "./user.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Editing, FilterRow, Lookup, Popup, Position } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import ProgressPanel from "components/progress-panel/progress-panel";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  //
  // stores

  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/user`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/user`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/user`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/user/${id}`, method: "Delete" });
      },
    }),
  };

  const roleStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/role`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/role/${id}` });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/department/${id}` });
      },
    }),
  };

  // render

  return (
    <ProgressPanel name={"UPLOAD_USER"}>
      <div className={styles.main}>
        <Grid
          dataSource={userStore}
          title={"User"}
          editingMode={"row"}
          selection={{ mode: "single" }}
          rowNumbering={true}
          allowDownloading={false}
        >
          <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
            <Popup title="User" showTitle={true} width={700} height={700}>
              <Position my="center" at="center" of={window} />
            </Popup>
          </Editing>
          <FilterRow visible={true} />
          <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
          <Column caption={"First Name"} dataField={"firstName"} allowFiltering={true} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"Last Name"} dataField={"lastName"} allowFiltering={true} width={200}>
            <RequiredRule />
          </Column>
          <Column caption={"UserName"} dataField={"userName"} allowFiltering={true} width={200}>
            <RequiredRule />
          </Column>
          <Column
            caption={"Password"}
            dataField={"password"}
            allowFiltering={false}
            width={200}
            visible={false}
            formItem={{
              visible: true,
              editorOptions: {
                mode: "password",
              },
            }}
          >
            {/* <RequiredRule /> */}
          </Column>
          <Column caption={"Email"} dataField={"emailAddress"} allowFiltering={true} width={200} />

          <Column caption={"Role"} dataField={"roleId"} calculateDisplayValue="role" allowFiltering={true} width={200}>
            <Lookup dataSource={roleStore} valueExpr={"id"} displayExpr="name" />
            <RequiredRule />
          </Column>
          <Column caption={"Department"} dataField={"departmentId"} calculateDisplayValue="department" allowFiltering={true}>
            <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
          </Column>
        </Grid>
      </div>
    </ProgressPanel>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
