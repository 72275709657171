import React, { useContext, useEffect, useState } from "react";
import { url } from "config.js";
import Chart from "components/chart/chart";
import Toolbar from "devextreme-react/toolbar";
import { currencyFormat, currency } from "helper/global";
import {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
} from "devextreme-react/chart";
import styles from "./installation.module.scss";
import HierarchyContext from "components/hierarchy/context";
import { dict } from "helper/global";
import { request } from "helper/http-client";
import { LoadIndicator } from "devextreme-react/load-indicator";

function Component() {
  //
  // useContext

  const context = useContext(HierarchyContext);
  const filter = context.filter;
  const selectedRowsData = context.selectedRowsData;
  const [state, setState] = useState(null);

  useEffect(() => {
    (async () => {
      var result = await request({
        url: `${url}/installation`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
  }, [selectedRowsData, filter]);

  // event handlers

  function customizeText(arg) {
    return { text: currencyFormat(arg.value) };
  }

  // render

  function loadingIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <div className={styles.toolbar}>
          <Toolbar>
            {/* <Item location="before" widget="dxButton" options={{ text: "Back", type: "back", stylingMode: "outlined"}} /> */}
          </Toolbar>
        </div>
        {state ? (
          <div className={styles.chart}>
            <Chart dataSource={state.data}>
              <CommonSeriesSettings argumentField="year" barPadding={0.25} />
              <Legend visible={false} />
              <Tooltip enabled={true} customizeTooltip={customizeText} />

              <ArgumentAxis allowDecimals={false} minorTickCount={4} title={dict("Year")}>
                <MinorTick visible={true} />
                <Label>
                  <Format type="decimal" />
                </Label>
              </ArgumentAxis>
              <ValueAxis minValueMargin={0} maxValueMargin={0} title={currency()} />

              <Series valueField="value" type="bar">
                <Point visible={false} />
              </Series>
            </Chart>
          </div>
        ) : (
          loadingIndicatorRender()
        )}
      </div>
    )
  );
}

export default Component;
