import React, { useState, useRef } from "react";
import styles from "./multi-editor.module.scss";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { dict, logError, addFilter, currency } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { CheckBox as DxCheckBox } from "devextreme-react/check-box";
import SelectBox from "devextreme-react/select-box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faPen as faEdit } from "@fortawesome/free-solid-svg-icons";
import ScrollView from "devextreme-react/scroll-view";
import TextBox from "devextreme-react/text-box";
import { NumberBox } from "devextreme-react/number-box";
import { confirm } from "devextreme/ui/dialog";
import DateBox from "devextreme-react/date-box";

function Component(props) {
  //
  // stores

  const classStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/class`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/class/${id}` });
      },
    }),
  };

  const typeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/type`, loadOptions: addFilter(loadOptions, "and", ["classId", "=", data.current.classId]) });
      },
      byKey: function (id) {
        return request({ url: `${url}/type/${id}` });
      },
    }),
  };

  const sizeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/size`, loadOptions: addFilter(loadOptions, "and", ["classId", "=", data.current.classId]) });
      },
      byKey: function (id) {
        return request({ url: `${url}/size/${id}` });
      },
    }),
  };

  const ownershipStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/ownership`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/ownership/${id}` });
      },
    }),
  };

  const statusStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/nodestatus`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/nodestatus/${id}` });
      },
    }),
  };

  const materialStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/material`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/material/${id}` });
      },
    }),
  };

  const manufacturerStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/manufacturer`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/manufacturer/${id}` });
      },
    }),
  };

  const strategyStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/strategy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/strategy/${id}` });
      },
    }),
  };

  const costStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/cost`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/cost/${id}` });
      },
    }),
  };

  const groupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/group`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/group/${id}` });
      },
    }),
  };

  const tagStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/tag`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/tag/${id}` });
      },
    }),
  };

  const activityTypeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/activityType`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/activityType/${id}` });
      },
    }),
  };

  const conditionRatingStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/conditionrating`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/conditionrating/${id}` });
      },
    }),
  };

  // const

  const showGeneralTab = props.options.showGeneralTab === undefined || props.options.showGeneralTab;
  const showGroupTab = props.options.showGroupTab === undefined || props.options.showGroupTab;
  const showActivityTab = props.options.showActivityTab === undefined || props.options.showActivityTab;

  let showOwnership = true;
  let showStatus = true;
  let showClass = true;
  let showType = true;
  let showSize = true;
  let showMaterial = true;
  let showManufacturer = true;
  let showStrategy = true;
  let showCost = true;
  let showProject = true;
  let showGroup = true;
  let showTag = true;
  let showModel = true;
  let showLocation = true;
  let showValue = true;
  let showCount = true;
  let showInstallYear = true;
  let showCof = true;
  let showR = true;
  let showUsefulLife = true;
  let showCostOverride = true;

  if (props.options.fields) {
    showOwnership = props.options.fields.ownersip === undefined || props.options.fields.ownersip;
    showStatus = props.options.fields.status === undefined || props.options.fields.status;
    showClass = props.options.fields.class === undefined || props.options.fields.class;
    showType = props.options.fields.type === undefined || props.options.fields.type;
    showSize = props.options.fields.size === undefined || props.options.fields.size;
    showMaterial = props.options.fields.material === undefined || props.options.fields.material;
    showManufacturer = props.options.fields.manufacturer === undefined || props.options.fields.manufacturer;
    showStrategy = props.options.fields.strategy === undefined || props.options.fields.strategy;
    showCost = props.options.fields.cost === undefined || props.options.fields.cost;
    showGroup = props.options.fields.group === undefined || props.options.fields.group;
    showTag = props.options.fields.tag === undefined || props.options.fields.tag;
    showModel = props.options.fields.model === undefined || props.options.fields.model;
    showLocation = props.options.fields.location === undefined || props.options.fields.location;
    showValue = props.options.fields.value === undefined || props.options.fields.value;
    showCount = props.options.fields.count === undefined || props.options.fields.count;
    showInstallYear = props.options.fields.installYear === undefined || props.options.fields.installYear;
    showCof = props.options.fields.cof === undefined || props.options.fields.cof;
    showR = props.options.fields.r === undefined || props.options.fields.r;
    showUsefulLife = props.options.fields.usefulLife === undefined || props.options.fields.usefulLife;
    showCostOverride = props.options.fields.costOverride === undefined || props.options.fields.costOverride;
  }

  // hooks

  const defaultData = {
    location: null,
    locationOpr: 0,
    model: null,
    modelOpr: 0,
    value: null,
    valueOpr: 0,
    installYear: null,
    installYearOpr: 0,
    cof: null,
    cofOpr: 0,
    r: null,
    rOpr: 0,
    usefulLife: null,
    usefulLifeOpr: 0,
    costOverride: null,
    costOverrideOpr: 0,
    parentId: null,
    parentIdOpr: 0,
    ownershipId: null,
    ownershipIdOpr: 0,
    statusId: null,
    statusIdOpr: 0,
    classId: null,
    classIdOpr: 0,
    typeId: null,
    typeIdOpr: 0,
    sizeId: null,
    sizeIdOpr: 0,
    materialshipId: null,
    materialIdOpr: 0,
    manufacturerId: null,
    manufacturerIdOpr: 0,
    strategyId: null,
    strategyIdOps: 0,
    costId: null,
    costIdOpr: 0,
    groupId: null,
    groupIdOpr: 0,
    tagId: null,
    tagIdOpr: 0,
    activityTypeId: null,
    activityDate: null,
    activityDateOpr: 0,
  };

  const data = useRef(defaultData);

  const [loading, setLoading] = useState(false);
  const [, setRender] = useState(0);
  const editors = useRef([]);
  // event handlers

  async function onSave() {
    // var save = false;
    // for (const key in data.current) {
    //   if (key.includes("Opr") && data.current[key] !== 0) {
    //     save = true;
    //     break;
    //   }
    // }

    // if (!save) {
    //   props.onHiding();
    //   return;
    // }
    var result = await confirm("<i>Do you really want to update the selected assets?</i>", "Confirm");
    if (result) {
      try {
        setLoading(true);
        await request({
          url: `${props.options.url}`,
          method: "Post",
          data: data.current,
          loadOptions: props.options.loadOptions,
          params: props.options.params,
        });
        setLoading(false);
        data.current = defaultData;
      } catch (error) {
        logError(error);
      } finally {
        props.onHiding();
      }
    }
  }

  function onCancel() {
    props.onHiding();
  }

  function onValueChanged(e) {
    data.current[`${e.dataField}`] = e.value;
    if (e.dataField === "classId") {
      editors.current.forEach((i) => {
        i.option("value", null);
      });
      setRender((state) => state + 1);
    }
  }

  function onInitialized(e) {
    editors.current.push(e.component);
  }

  // render

  function generalRender() {
    return (
      <div className={styles.form}>
        <ScrollView height="100%">
          <LookupEditor1
            visible={showOwnership}
            allowNull={true}
            dataField="ownershipId"
            caption="Ownership"
            dataSource={ownershipStore}
            onValueChanged={onValueChanged}
          ></LookupEditor1>
          <LookupEditor1
            visible={showStatus}
            allowNull={true}
            dataField="statusId"
            caption="Status"
            dataSource={statusStore}
            onValueChanged={onValueChanged}
          ></LookupEditor1>
          <LookupEditor1
            visible={showClass}
            dataField="classId"
            caption="Class"
            dataSource={classStore}
            onValueChanged={onValueChanged}
          ></LookupEditor1>
          <LookupEditor1
            visible={showType}
            dataField="typeId"
            caption="Type"
            dataSource={typeStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>
          <LookupEditor1
            visible={showSize}
            dataField="sizeId"
            caption="Size"
            dataSource={sizeStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>
          <LookupEditor1
            visible={showMaterial}
            dataField="materialId"
            caption="Material"
            dataSource={materialStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>
          <LookupEditor1
            visible={showManufacturer}
            dataField="manufacturerId"
            caption="Manufacturer"
            dataSource={manufacturerStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>
          <LookupEditor1
            visible={showStrategy}
            dataField="strategyId"
            caption="Strategy"
            dataSource={strategyStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>
          <LookupEditor1
            visible={showCost}
            dataField="costId"
            caption="Cost"
            dataSource={costStore}
            onValueChanged={onValueChanged}
            onInitialized={onInitialized}
          ></LookupEditor1>

          <TextEditor visible={showModel} allowNull={true} dataField="model" caption="Model" onValueChanged={onValueChanged} />
          <TextEditor visible={showLocation} allowNull={true} dataField="location" caption="Location" onValueChanged={onValueChanged} />
          <NumberEditor
            visible={showInstallYear}
            allowNull={true}
            dataField="installYear"
            caption="InstallYear"
            onValueChanged={onValueChanged}
          />
          <NumberEditor
            visible={showValue}
            format={`${currency()}#,###`}
            allowNull={true}
            dataField="value"
            caption="Value"
            onValueChanged={onValueChanged}
          />
          <NumberEditor visible={showCount} dataField="count" caption="Count" onValueChanged={onValueChanged} />
          <NumberEditor
            visible={showUsefulLife}
            allowNull={true}
            dataField="potentialUsefulLife"
            caption="Potential Useful Life"
            onValueChanged={onValueChanged}
          />
          <NumberEditor
            visible={showCostOverride}
            allowNull={true}
            dataField="costOverride"
            caption="Cost Override"
            onValueChanged={onValueChanged}
          />
          <NumberEditor visible={showCof} allowNull={true} dataField="cof" caption="Cof" onValueChanged={onValueChanged} />
          <NumberEditor visible={showR} allowNull={true} dataField="r" caption="R" onValueChanged={onValueChanged} />
        </ScrollView>
      </div>
    );
  }

  function groupRender() {
    return (
      <ScrollView>
        <LookupEditor2
          visible={showGroup}
          dataField="groupId"
          caption="Group"
          dataSource={groupStore}
          onValueChanged={onValueChanged}
          onInitialized={onInitialized}
        ></LookupEditor2>
        <LookupEditor2
          visible={showTag}
          dataField="tagId"
          caption="Tag"
          dataSource={tagStore}
          onValueChanged={onValueChanged}
          onInitialized={onInitialized}
        ></LookupEditor2>
      </ScrollView>
    );
  }

  function activityRender() {
    return (
      <ScrollView>
        <ActivityDateEditor dataField="activityDate" caption="Date" visible={true} onValueChanged={onValueChanged} />
        <LookupEditor1
          visible={showProject}
          dataField="activityTypeId"
          caption="Activity Type"
          dataSource={activityTypeStore}
          onValueChanged={onValueChanged}
          onInitialized={onInitialized}
          showCheck={false}
        ></LookupEditor1>
        <LookupEditor1
          visible={showProject}
          dataField="conditionRatingId"
          caption="ConditionRating"
          dataSource={conditionRatingStore}
          onValueChanged={onValueChanged}
          onInitialized={onInitialized}
          showCheck={false}
        ></LookupEditor1>
      </ScrollView>
    );
  }

  function loadingRender() {
    return (
      <div className={styles.loading}>
        <LoadIndicator />
      </div>
    );
  }

  function tabsRender() {
    return (
      <div className={styles.main}>
        <div className={styles.tabs}>
          <TabPanel height={"100%"} deferRendering={true} animationEnabled={true}>
            <Item title={dict("General")} render={generalRender} visible={showGeneralTab} />
            <Item title={dict("Group")} render={groupRender} visible={showGroupTab} />
            <Item title={dict("Activity")} render={activityRender} visible={showActivityTab} />
          </TabPanel>
        </div>
        <div className={styles.buttons}>
          <Button text={dict("Save")} type={"success"} stylingMode="text" onClick={onSave} />
          <Button className={styles.cancelbutton} text={dict("Cancel")} type={"success"} stylingMode="text" onClick={onCancel} />
        </div>
        {loading && loadingRender()}
      </div>
    );
  }

  return (
    <React.Fragment>
      {!loading && props.children}
      {props.visible && (
        <Popup
          visible={props.visible}
          onHiding={props.onHiding}
          showCloseButton={true}
          dragEnabled={true}
          hideOnOutsideClick={false}
          showTitle={true}
          title={"Multi-Editor"}
          width={700}
          height={700}
          deferRendering={true}
        >
          {tabsRender()}
        </Popup>
      )}
    </React.Fragment>
  );
}

export default Component;

// functions

function LookupEditor1(props) {
  //
  // hooks
  const [state, setState] = useState(true);
  const check = useRef(null);

  // const

  const showCheck = props.showCheck === undefined || props.showCheck;

  // event handlers

  function onSelectChanged(e) {
    props.onValueChanged({ dataField: props.dataField, value: e.value });
    setState(!e.value);
    if (!e.value && !props.allowNull) check.current.option("value", false);
  }

  function onCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 1 : 0 });
  }

  function onCheckInitialized(e) {
    check.current = e.component;
    props.onInitialized && props.onInitialized(e);
  }

  // render

  if (!props.visible) return null;

  return (
    <div className={styles.customEditor}>
      <div className={styles.customEditor_label}>{`${props.caption}:`}</div>
      <div className={styles.customEditor_selectbox}>
        <SelectBox
          dataSource={props.dataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          showClearButton={true}
          onValueChanged={onSelectChanged}
          onInitialized={props.onInitialized}
        ></SelectBox>
      </div>
      <div className={styles.customEditor_container}></div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>{showCheck && <FontAwesomeIcon icon={faEdit} />}</div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox
            visible={showCheck}
            disabled={state && !props.allowNull}
            onInitialized={onCheckInitialized}
            onValueChanged={onCheckChanged}
          ></DxCheckBox>
        </div>
      </div>
    </div>
  );
}

function LookupEditor2(props) {
  const [state, setState] = useState(true);
  const addCheck = useRef(null);
  const removeCheck = useRef(null);
  //
  // event handlers

  function onSelectChanged(e) {
    props.onValueChanged({ dataField: props.dataField, value: e.value });
    setState(!e.value);
    if (!e.value) {
      addCheck.current.option("value", false);
      removeCheck.current.option("value", false);
    }
  }

  function onAddCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 2 : 0 });
    if (e.value) removeCheck.current.option("value", false);
  }

  function onRemoveCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 3 : 0 });
    if (e.value) addCheck.current.option("value", false);
  }

  function onAddCheckInitialized(e) {
    addCheck.current = e.component;
  }

  function onRemovedCheckInitialized(e) {
    removeCheck.current = e.component;
  }

  // render

  if (!props.visible) return null;

  return (
    <div className={styles.customEditor}>
      <div className={styles.customEditor_label}>{`${props.caption}:`}</div>
      <div className={styles.customEditor_selectbox}>
        <SelectBox
          dataSource={props.dataSource}
          valueExpr="id"
          displayExpr="name"
          searchEnabled={true}
          showClearButton={true}
          onValueChanged={onSelectChanged}
        ></SelectBox>
      </div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state} onInitialized={onAddCheckInitialized} onValueChanged={onAddCheckChanged}></DxCheckBox>
        </div>
      </div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state} onInitialized={onRemovedCheckInitialized} onValueChanged={onRemoveCheckChanged}></DxCheckBox>
        </div>
      </div>
    </div>
  );
}

function TextEditor(props) {
  //
  //
  const [state, setState] = useState(true);
  const check = useRef(null);

  //
  // event handlers

  function onSelectChanged(e) {
    props.onValueChanged({ dataField: props.dataField, value: e.value });
    setState(!e.value);
    if (!e.value && !props.allowNull) check.current.option("value", false);
  }

  function onCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 1 : 0 });
  }

  function onCheckInitialized(e) {
    check.current = e.component;
  }

  // render

  if (!props.visible) return null;

  return (
    <div className={styles.customEditor}>
      <div className={styles.customEditor_label}>{`${props.caption}:`}</div>
      <div className={styles.customEditor_selectbox}>
        <TextBox showClearButton={true} onValueChanged={onSelectChanged}></TextBox>
      </div>
      <div className={styles.customEditor_container}></div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faEdit} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state && !props.allowNull} onInitialized={onCheckInitialized} onValueChanged={onCheckChanged}></DxCheckBox>
        </div>
      </div>
    </div>
  );
}

function NumberEditor(props) {
  //
  //
  const [state, setState] = useState(true);
  const check = useRef(null);
  //
  // event handlers

  function onSelectChanged(e) {
    props.onValueChanged({ dataField: props.dataField, value: e.value });
    setState(!e.value);
    if (!e.value && !props.allowNull) check.current.option("value", false);
  }

  function onCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 1 : 0 });
  }

  function onCheckInitialized(e) {
    check.current = e.component;
  }

  if (!props.visible) return null;

  // render

  return (
    <div className={styles.customEditor}>
      <div className={styles.customEditor_label}>{`${props.caption}:`}</div>
      <div className={styles.customEditor_selectbox}>
        <NumberBox defaultValue={null} format={props.format} showClearButton={true} onValueChanged={onSelectChanged}></NumberBox>
      </div>
      <div className={styles.customEditor_container}></div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faEdit} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state && !props.allowNull} onInitialized={onCheckInitialized} onValueChanged={onCheckChanged}></DxCheckBox>
        </div>
      </div>
    </div>
  );
}

function ActivityDateEditor(props) {
  const [state, setState] = useState(true);
  const addCheck = useRef(null);
  const removeCheck = useRef(null);
  //
  // event handlers

  function onSelectChanged(e) {
    props.onValueChanged({ dataField: props.dataField, value: e.value });
    setState(!e.value);
    if (!e.value) {
      addCheck.current.option("value", false);
      removeCheck.current.option("value", false);
    }
  }

  function onAddCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 2 : 0 });
    if (e.value) removeCheck.current.option("value", false);
  }

  function onRemoveCheckChanged(e) {
    props.onValueChanged({ dataField: `${props.dataField}Opr`, value: e.value ? 3 : 0 });
    if (e.value) addCheck.current.option("value", false);
  }

  function onAddCheckInitialized(e) {
    addCheck.current = e.component;
  }

  function onRemovedCheckInitialized(e) {
    removeCheck.current = e.component;
  }

  // render

  if (!props.visible) return null;

  return (
    <div className={styles.customEditor}>
      <div className={styles.customEditor_label}>{`${props.caption}:`}</div>
      <div className={styles.customEditor_selectbox}>
        <DateBox showClearButton={true} onValueChanged={onSelectChanged} dateSerializationFormat={"yyyy-MM-dd"}></DateBox>
      </div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state} onInitialized={onAddCheckInitialized} onValueChanged={onAddCheckChanged}></DxCheckBox>
        </div>
      </div>
      <div className={styles.customEditor_container}>
        <div className={styles.customEditor_container_icon}>
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <div className={styles.customEditor_container_checkbox}>
          <DxCheckBox disabled={state} onInitialized={onRemovedCheckInitialized} onValueChanged={onRemoveCheckChanged}></DxCheckBox>
        </div>
      </div>
    </div>
  );
}
