import React from "react";
import Grid from "components/data-grid/data-grid.js";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  // stores

  const fieldStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/assetcustomfieldvalue/byassetId`,
          loadOptions: loadOptions,
          params: { assetId: props.data.assetId },
        });
      },
      update: (_, data) => {
        return request({ url: `${url}/assetcustomfieldvalue`, method: "Put", data: data });
      },
    }),
  };

  // event Handler

  return (
    <Grid
      title={"Field"}
      showTitle={false}
      dataSource={fieldStore}
      allowDownloading={false}
      allowAdding={false}
      allowDeleting={false}
      allowExpanding={false}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column visible={false} caption={"AssetId"} dataField={"node"} formItem={{ visible: false }} />
      <Column caption={"Field"} dataField={"field"} allowFiltering={true} allowEditing={false} formItem={{ readOnly: true }} />
      <Column caption={"Unit"} dataField={"unit"} allowFiltering={true} width={150} allowEditing={false} formItem={{ readOnly: true }} />
      <Column caption={"Value"} dataField={"value"} allowFiltering={true} width={150}></Column>
    </Grid>
  );
}

export default Component;
