import React from "react";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Grid from "components/data-grid/data-grid";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // store

  const roleStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/resource/resourcegroup/${props.id}`, loadOptions: loadOptions });
      },
      update: (_, data) => {
        return request({ url: `${url}/resource`, method: "Put", data: data });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Resource"}
      dataSource={roleStore}
      editingMode="batch"
      rowNumbering={true}
      allowDownloading={false}
      allowAdding={false}
      allowUpdating={true}
      allowDeleting={false}
    >
      <Column caption="Name" dataField="name" sortOrder={"asc"} sortIndex={1} allowEditing={false} width={400} />
      <Column caption="Description" dataField="description" allowEditing={false} />
      {/* <Column caption="Type" dataField="type" /> */}
      <Column caption="Included" dataField="included" sortOrder={"desc"} sortIndex={0} width={200} allowFiltering={false} />
    </Grid>
  );
}

export default Component;
