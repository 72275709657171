import React, { useState, useRef, useMemo } from "react";
import styles from "./masterlog.module.scss";
import Grid from "components/data-grid/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import JsonViewer from "../jsonviewer/jsonviewer";

function Component(props) {
  //hooks
  const [state, setState] = useState({ visible: false, oldValue: {}, newValue: {} });
  const exporting = useRef(false);

  const auditStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!exporting.current) {
          return request({
            url: `${url}/auditlog/kore`,
            loadOptions: loadOptions,
          });
        } else {
          return request({
            url: `${url}/auditlog/kore/download`,
            loadOptions: loadOptions,
          });
        }
      },
    }),
  };

  const userStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/user`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/user/${id}` });
      },
    }),
  };

  const typeStore = [
    { id: 0, name: "Create" },
    { id: 1, name: "Update" },
    { id: 2, name: "Delete" },
  ];

  // event handlers

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("oldRecord", "visible", true);
    e.component.columnOption("newRecord", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("oldRecord", "visible", false);
    e.component.columnOption("newRecord", "visible", false);
    exporting.current = false;
  }

  // render

  const gridRender = useMemo(() => {
    return (
      <Grid
        title={"Audit Log"}
        showTitle={false}
        dataSource={auditStore}
        onExported={onExported}
        onExporting={onExporting}
        onSelectionChanged={props.onSelectionChanged}
        allowExpanding={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
      >
        <Column caption={"Id"} visible={false} dataField={"id"} width={100} formItem={{ visible: false }} />
        <Column
          caption={"DateTime (UTC)"}
          dataField={"dateTime"}
          dataType={"date"}
          format={"yyyy-MM-dd HH:mm:ss"}
          width={200}
          sortOrder={"desc"}
        />
        <Column caption={"UserName"} dataField={"userId"} width={200} f>
          <Lookup dataSource={userStore} valueExpr={"id"} displayExpr="userName" />
        </Column>
        <Column caption={"Type"} dataField={"type"} width={200}>
          <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
        </Column>
        <Column caption={"Resource"} dataField={"resource"} />
        <Column caption={"New Value"} dataField={"newRecord"} visible={false} />
        <Column caption={"Old Value"} dataField={"oldRecord"} visible={false} />
        <Column
          type={"buttons"}
          buttons={[
            {
              hint: "Detail",
              icon: "detailslayout",
              visible: true,
              onClick: (e) => {
                console.log(e);
                setState({
                  oldValue: e.row.data.oldRecord,
                  newValue: e.row.data.newRecord,
                  visible: true,
                });
              },
            },
          ]}
          width={125}
          fixed={true}
        />
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.main}>
      {state.visible && (
        <JsonViewer
          visible={state.visible}
          oldValue={state.oldValue}
          newValue={state.newValue}
          onHiding={() => setState({ visible: false })}
        />
      )}
      {gridRender}
    </div>
  );
}

export default Component;
