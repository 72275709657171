import React from "react";
import styles from "./condition-profile.module.scss";
import Chart from "components/chart/chart";
//import Chart from "devextreme-react/chart";
import { url } from "config.js";
import { connect } from "react-redux";
import { currencyFormat } from "helper/global";
import {
  CommonSeriesSettings,
  Series,
  Pane,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  Point,
  MinorTick,
  Format,
  Tooltip,
} from "devextreme-react/chart";
import { currency, dict } from "helper/global";

function Component(props) {
  // event handlers

  function customizeText(e) {
    return { text: `Year: ${e.argument}, Value: ${e.seriesName === "Condition" ? e.value.toFixed(1) : currencyFormat(e.value)}` };
  }

  // render
  // url={`${url}/engine/output/condition-profile/${props.scenarioNodeId}`}
  return (
    <div className={styles.main}>
      <Chart url={`${url}/activitycostprofile/${props.nodeId}`}>
        <CommonSeriesSettings argumentField="year" />
        <Pane name="topPane" visible={false} />
        <Pane name="bottomPane" />
        <Legend visible={false} />
        <Tooltip enabled={true} customizeTooltip={customizeText} />

        <ArgumentAxis allowDecimals={false} minorTickCount={4} wholeRange={[props.firstYear + 1, props.lastYear]}>
          <MinorTick visible={false} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis
          name="condition"
          inverted={true}
          minValueMargin={0}
          maxValueMargin={0}
          title={dict("Condition")}
          tickInterval={1}
          wholeRange={[props.minCondition, props.maxCondition]}
        />
        <ValueAxis name="cost" minValueMargin={0} maxValueMargin={0} title={`${currency()}`}></ValueAxis>

        <Series valueField="condition" pane="topPane" type="spline" name={"Condition"}>
          <Point visible={false} />
        </Series>
        <Series valueField="cost" pane="bottomPane" type="bar" name={"Cost"} axis="cost" />
      </Chart>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    maxCondition: state.scenario.worstCondition,
    minCondition: state.scenario.bestCondition,
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
