import React, { useState, useEffect, useRef } from "react";
import styles from "./asset-editor.module.scss";
import Form from "./form/form";
import { TabPanel, Item } from "devextreme-react/tab-panel";
import { dict, logError } from "helper/global";
import Summary from "./summary/summary";
import Measurement from "./measurement/measurement";
import Field from "./field/field";
import Alias from "./alias/alias";
import Group from "./group/group";
import ActivityLog from "./activityLog/activityLog";
import Tag from "./tag/tag";
import Image from "./image/image";
import Document from "./document/document";
import File from "./file/file";
import { request } from "helper/http-client";
import { url } from "config.js";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Button } from "devextreme-react/button";
import { Popup } from "devextreme-react/popup";

function Component(props) {
  return props.visible && <Editor {...props} />;
}

export default Component;

function Editor(props) {
  //
  // hooks

  const [data, setData] = useState(null);
  const fieldChanged = useRef(false);
  // const saveData = useRef(null);

  useEffect(() => {
    if (!props.nodeId) {
      return;
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // event handlers

  function onFieldDataChanged(e) {
    fieldChanged.current = true;
  }

  // funcitons

  async function saveEditor() {
    try {
      if (fieldChanged.current) {
        setData(null);
        await request({ url: `${url}/scenarionode/node`, method: "Put", data: data });
        fieldChanged.current = false;
        props.refresh && props.refresh();
      }
    } catch (error) {
      logError(error);
    } finally {
      props.hideEditor && props.hideEditor();
    }
  }

  function hideEditor() {
    // setData(JSON.parse(JSON.stringify(saveData.current)));
    props.hideEditor && props.hideEditor();
  }

  // functions

  function load() {
    (async () => {
      var results = await request({ url: `${url}/scenarionode/bynode`, params: { nodeId: props.nodeId } });
      // saveData.current = JSON.parse(JSON.stringify(results));
      setData(results);
    })();
  }

  // render

  function loadingIndicatorRender() {
    return (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  }

  function contentRender() {
    return (
      <React.Fragment>
        <Content data={data} onFieldDataChanged={onFieldDataChanged} hideEditor={hideEditor} saveEditor={saveEditor} />
      </React.Fragment>
    );
  }

  return (
    <Popup
      visible={props.visible}
      onHiding={props.hideEditor}
      showCloseButton={true}
      dragEnabled={true}
      hideOnOutsideClick={false}
      showTitle={true}
      title={"Asset"}
      width={1000}
      height={1000}
      deferRendering={true}
    >
      <div id="context" className={styles.main}>
        <div className={styles.main}>{data ? contentRender() : loadingIndicatorRender()}</div>
      </div>
    </Popup>
  );
}

function Content(props) {
  function summaryRender() {
    return <Summary data={props.data} />;
  }

  function measurementRender() {
    return <Measurement data={props.data} />;
  }

  function fieldRender() {
    return <Field data={props.data} />;
  }

  function aliasRender() {
    return <Alias data={props.data} />;
  }

  function activityLogRender() {
    return <ActivityLog data={props.data} />;
  }

  function groupRender() {
    return <Group data={props.data} />;
  }

  function tagRender() {
    return <Tag data={props.data} />;
  }

  function imageRender() {
    return <Image data={props.data} />;
  }

  function documentRender() {
    return <Document data={props.data} />;
  }

  function fileRender() {
    return <File data={props.data} />;
  }

  function formRender() {
    return <Form data={props.data} onFieldDataChanged={props.onFieldDataChanged} />;
  }

  function tabsRender() {
    return (
      <TabPanel height={"100%"} deferRendering={true} animationEnabled={true} showNavButtons={true}>
        <Item title={dict("Summary")} render={summaryRender} />
        <Item title={dict("Attribute")} render={formRender} />
        <Item title={dict("Actvity")} render={activityLogRender} />
        <Item title={dict("Measurement")} render={measurementRender} />
        <Item title={dict("Alias")} render={aliasRender} />
        <Item title={dict("Group")} render={groupRender} />
        <Item title={dict("Tag")} render={tagRender} />
        <Item title={dict("Field")} render={fieldRender} />
        <Item title={dict("Image")} render={imageRender} />
        <Item title={dict("Document")} render={documentRender} />
        <Item title={dict("File")} render={fileRender} />
      </TabPanel>
    );
  }
  return (
    <React.Fragment>
      <div className={styles.tabs}>{tabsRender()}</div>
      <div className={styles.buttons}>
        <Button text={dict("Save")} type={"success"} stylingMode="text" onClick={props.saveEditor} />
        <Button className={styles.cancelbutton} text={dict("Cancel")} type={"success"} stylingMode="text" onClick={props.hideEditor} />
      </div>
    </React.Fragment>
  );
}
