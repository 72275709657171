import React, { useState, useEffect, useContext } from "react";
import styles from "./deficit-profile.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart/chart";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { currencyFormat, withMemo } from "helper/global";
import { CommonSeriesSettings, Series, ValueAxis, ArgumentAxis, Legend, Label, MinorTick, Format, Tooltip } from "devextreme-react/chart";
import HierarchyContext from "components/hierarchy/context";

function Component() {
  //
  // useContext

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // render

  return (
    selectedRowsData.length > 0 && (
      <div className={styles.main}>
        <Profile selectedRowsData={selectedRowsData} filter={filter} />
      </div>
    )
  );
}

export default Component;

const Profile = withMemo(
  (props) => {
    //
    // stores

    useEffect(() => {
      response();
      async function response() {
        try {
          setData(null);
          var result = await request({
            url: `${url}/budgeting/deficiet`,
            params: { ids: props.selectedRowsData.map((i) => i.id), filterExpression: props.filter },
          });
          setData(result);
        } catch {}
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedRowsData, props.filter]);

    // hooks

    const [data, setData] = useState(null);

    // event handlers

    function customizeText(arg) {
      return { text: currencyFormat(arg.value) };
    }

    function customizeLegendText(e) {
      return e.seriesName;
    }

    function seriesRender() {
      var series = [];
      data.scenarios.forEach((object) => {
        var name = `${object.name}`;
        series.push(<Series key={name} valueField={name} name={name} />);
      });

      return series;
    }

    return data ? (
      <Chart dataSource={data.profile}>
        <CommonSeriesSettings argumentField="year" type="line" />
        <Legend position="inside" horizontalAlignment="left" customizeText={customizeLegendText} />
        <Tooltip enabled={true} customizeTooltip={customizeText} />

        <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
          <MinorTick visible={true} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis name="amount" title={""} inverted={true} />
        {/* <Size height={300}></Size> */}
        {seriesRender()}
      </Chart>
    ) : (
      <div className={styles.indicator}>
        <LoadIndicator />
      </div>
    );
  },
  ["selectedRowsData", "filter"]
);
