import React, { useState, useMemo } from "react";
import styles from "./loginform.module.scss";
import "./loginform.scss";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import * as actions from "redux/actions/index";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { connect } from "react-redux";
import { status } from "redux/enums/auth";

function Component(props) {
  //
  // styles

  let inlineStyles = {
    height: "16px",
    width: "16 px",
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "0px",
  };

  // hooks

  const [state, setState] = useState({
    userName: null,
    password: null,
    passwordMode: "password",
  });

  const appendScript = useMemo(() => {
    const script = document.createElement("script");
    script.src = "https://seal.godaddy.com/getSeal?sealID=4zSvwdqpz38ArMUe7JCNItpujNbqakDbIjwf5sxkoP1qxXztaBax2EDKvrU4";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  // event handers

  function onSubmit(e) {
    e.preventDefault();
    props.login(state);
  }

  // render

  function sslSearlRender() {
    return (
      <div className={styles.seal}>
        <span id="siteseal">{appendScript}</span>
      </div>
    );
  }

  function onShowPassword() {
    setState((s) => {
      return { ...s, passwordMode: s.passwordMode === "text" ? "password" : "text" };
    });
  }

  function formRender() {
    return (
      <div className={styles.main}>
        <form onSubmit={onSubmit}>
          <div className={`${styles.container} dx-card `}>
            <div className={styles.logo}>
              <img src={require("assets/icons/logogray.png")} width="60" height="38" alt="" />
            </div>
            <div className={styles.title}>User Login</div>

            <div className={styles.textbox}>
              <TextBox
                placeholder={"Username"}
                stylingMode={"filled"}
                onValueChanged={(e) => {
                  setState({ ...state, userName: e.value });
                }}
              >
                <Validator>
                  <RequiredRule message="Username is required" />
                </Validator>
              </TextBox>
            </div>
            <div className={styles.textbox}>
              <TextBox
                placeholder={"Password"}
                mode={state.passwordMode}
                stylingMode={"filled"}
                onValueChanged={(e) => {
                  setState({ ...state, password: e.value });
                }}
              >
                <Validator>
                  <RequiredRule message="Password is required" />
                </Validator>
                <TextBoxButton
                  name="password"
                  location="after"
                  options={{
                    icon: "fad fa-eye",
                    type: "outlined",
                    onClick: onShowPassword,
                  }}
                />
              </TextBox>
            </div>
            {/* <div className={styles.footer}>
              <div className={styles.footer_left}>
                Remember Me
                <div className={styles.footer_switch}>
                  <Switch
                    onValueChanged={(e) => {
                      setState({ ...state, persistent: e.value });
                    }}
                  />
                </div>
              </div>
            </div> */}
            <div className={styles.empty}></div>
            <div className={styles.button}>
              <Button
                text="LOGIN"
                type={"success"}
                icon={"fas fa-sign-in-alt"}
                stylingMode={"contained"}
                width={"100%"}
                height={50}
                useSubmitBehavior={true}
              >
                <LoadIndicator
                  className="button-indicator"
                  indicatorSrc={require("assets/gifs/4.gif")}
                  visible={props.loading}
                  style={inlineStyles}
                />
                <span className="dx-button-text">{props.loading ? "LOGGING IN" : "LOGIN"}</span>
              </Button>
            </div>
            <br />
            {sslSearlRender()}
            <br />
            {props.status === status.LoginDenied ? <div className={styles.error}>Incorrect username or password!</div> : null}
          </div>
        </form>
      </div>
    );
  }

  return formRender();
}

function mapsDispatchToProps(dispatch) {
  return {
    login: (state) => dispatch(actions.credLogin(state)),
  };
}

function mapStateToProps(state) {
  return {
    loading: state.auth.loading,
    status: state.auth.status,
  };
}

export default connect(mapStateToProps, mapsDispatchToProps)(Component);
