import React, { useState } from "react";
import styles from "./query.module.scss";
import CustomStore from "devextreme/data/custom_store";
import List from "components/list/list";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import Grid from "./grid/grid";
import { withMemo, dict } from "helper/global";
import { connect } from "react-redux";

function Component(props) {
  // useState

  const initialState = { id: null };
  const [query, setQuery] = useState(initialState);
  const [render, setRender] = useState(0);

  // event handlers

  function onSelectionChanged(e) {
    setQuery(e.selectedRowsData[0] || initialState);
  }

  function onQueryModeified() {
    setRender((render) => render + 1);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.list}>
        <Query departmentId={props.departmentId} onQueryModeified={onQueryModeified} onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.container}>
        <div className={`${styles.grid} theme-datagrid`}>
          <div className={styles.grid_row}>{<Grid query={query} render={render} />}</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    departmentId: state.user.departmentId,
  };
};

export default connect(mapStateToProps, null)(Component);

const Query = withMemo((props) => {
  //
  // stores

  const queryStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/query`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/query`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/query`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/query/${id}`, method: "Delete" });
      },
      onInserted: props.onQueryModeified,
      onUpdated: props.onQueryModeified,
    }),
  };

  const userStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/user`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/user/${id}` });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/department/${id}` });
      },
    }),
  };

  // render

  return (
    <List
      title={dict("Query")}
      dataSource={queryStore}
      selection={{ mode: "single" }}
      onSelectionChanged={props.onSelectionChanged}
      allowDownloading={false}
    >
      <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
      <Column visible={true} caption={"Name"} dataField={"name"}>
        <RequiredRule />
      </Column>
      <Column visible={false} caption={"Description"} dataField={"description"} />
      <Column visible={false} caption={"User"} dataField={"userId"} allowFiltering={true} width={200}>
        <Lookup dataSource={userStore} valueExpr={"id"} displayExpr="userName" allowClearing={true} />
      </Column>
      <Column caption={"Department"} dataField={"departmentId"} visible={false} formItem={{ visible: props.departmentId === null }}>
        <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" allowClearing={true} />
      </Column>
      {props.departmentId && <Column visible={false} formItem={{ itemType: "empty" }} />}
      <Column
        caption={"Text"}
        dataField={"text"}
        visible={false}
        allowFiltering={true}
        width={400}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 170 } }}
      >
        <RequiredRule />
      </Column>
      <Column
        caption={"Notes"}
        dataField={"notes"}
        visible={false}
        allowFiltering={true}
        width={400}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
    </List>
  );
}, []);
