import React from "react";
import PieChart from "components/piechart/piechart";
import { Series, Label, Legend, Font, Margin, Border } from "devextreme-react/chart";
import styles from "./condition.module.scss";
import { dict } from "helper/global";

function Component(props) {
  // useState

  function customizePoint(e) {
    return {
      color: e.data.color,
    };
  }

  function customizeText(e) {
    return `${e.value}%`;
  }

  // function innerDonutRender(pieChart) {
  //   var cost = pieChart
  //     .getAllSeries()[0]
  //     .getVisiblePoints()
  //     .reduce((s, p) => s + p.originalValue, 0);

  //   if (!cost) return null;
  //   return (
  //     <svg>
  //       {/* <circle cx="0" cy="0" r="50" fill="red" /> */}
  //       {/* <text textAnchor="start" x="0" y="5" style={{ fontSize: 12, fill: "# #adadad" }}>
  //         Conditions
  //       </text> */}
  //     </svg>
  //   );
  // }

  function chartRender() {
    return (
      <PieChart
        key={"id"}
        dataSource={props.data}
        type="donut"
        innerRadius={0.65}
        //centerRender={TooltipTemplate}
        palette={"Office"}
        customizePoint={customizePoint}
        //onPointClick={onPointClick}
      >
        <Series argumentField="rating" valueField="percent">
          <Label format="fixedPoint" visible={true} position="inside" backgroundColor="none" customizeText={customizeText}>
            <Font size={11} />
          </Label>
        </Series>
        <Legend visible={true} orientation="vertical" verticalAlignment="center" horizontalAlignment="left">
          <Margin right={0} left={0} Top={0} bottom={0}></Margin>
          <Border visible={false}></Border>
        </Legend>
      </PieChart>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.title}>{dict("Condition")}</div>
      <div className={styles.chart}>{chartRender()}</div>
      <div className={styles.note}>% based on cost</div>
    </div>
  );

  //return chartRender();
}

export default Component;
