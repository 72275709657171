import React, { useRef } from "react";
import { FileUploader as DxFileUploader } from "devextreme-react/file-uploader";
import { Popup } from "devextreme-react/popup";
import { api, url } from "config.js";
import ScrollView from "devextreme-react/scroll-view";
import { dict } from "helper/global";

function Component(props) {
  // hooks

  const fileUploader = useRef();

  // event handlers

  function onFileUploaded(e) {
    fileUploader.current.instance && fileUploader.current.instance.reset();
    props.onUploaded && props.onUploaded();
  }

  function onHiding() {
    props.hideFileLoader();
  }

  // render

  return (
    <div>
      <Popup
        className={"popup"}
        visible={props.fileLoaderVisible}
        onHiding={onHiding}
        showCloseButton={true}
        dragEnabled={true}
        showTitle={true}
        title={dict("File Uploader")}
        width={600}
        height={600}
      >
        <ScrollView>
          <DxFileUploader
            ref={fileUploader}
            uploadMode={"useButtons"}
            multiple={true}
            allowedFileExtensions={props.allowedFileExtensions}
            uploadUrl={`${api}/${props.uploadUrl}`}
            onFilesUploaded={onFileUploaded}
          />
          <span className="note">
            {`${dict("Allowed file extensions")}: `}
            <span>{props.allowedFileExtensions && props.allowedFileExtensions.map((i) => `  ${i}`)}</span>
          </span>
        </ScrollView>
      </Popup>
    </div>
  );
}

export default Component;
