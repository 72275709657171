import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadapp = (result) => {
  return {
    type: actionTypes.APP_LOAD,
    payload: result,
  };
};

export const loadApp = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/app` });
      dispatch(_loadapp(result));
    } catch (error) {
      throw Error("Unable to load app settings");
    }

    return "done";
  };
};
