import React from "react";
import styles from "./asset.module.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import Tabs from "components/tabs/tabs";
import { selectedTabItem } from "helper/global";
import Hierarchy from "components/hierarchy/hierarchy";
import Register from "./register/asset/asset";
import Map from "./map/map";
import { dict } from "helper/global";
import { url } from "config.js";

function Component(props) {
  // tabs

  const tabItems = [
    {
      text: dict("Asset"),
      icon: "fad fa-fan",
      path: "/register",
    },
    {
      text: dict("Map"),
      icon: "fad fa-map",
      path: "/map",
    },
  ];

  function changeView(e) {
    props.history.push(`/klient/data/asset${e.path}`);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>
        <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView} />
      </div>
      <div className={styles.container}>
        <Hierarchy
          addButton={false}
          allowEditing={true}
          allowDragging={false}
          multiSelect={true}
          showScenarioName={true}
          countUrl={`${url}/engine/output/node-count`}
          showFilter={true}
          showCount={true}
          filterFields={[
            "Class",
            "Type",
            "Size",
            "Material",
            "Manufacturer",
            "Status",
            "Ownership",
            "Group",
            "Tag",
            "Risk",
            "Condition",
            "CoF",
            "Install Year",
          ]}
          filterType={1}
        >
          <Switch>
            <Route exact path={["/klient/data/asset", "/klient/data/asset/register"]} component={Register} />
            <Route exact path="/klient/data/asset/map" component={Map} />
            <Redirect to="/" />
          </Switch>
        </Hierarchy>
      </div>
    </div>
  );
}

export default Component;
