import React from "react";
import styles from "./card.module.scss";

function Card(props) {
  function onClick() {
    props.onClick && props.onClick(props.id);
  }

  return (
    <div className={`dx-card ${styles.main} ${props.cursor && styles.main_cursor}`} onClick={onClick}>
      <div className={styles.grid}>
        <div className={styles.line}></div>
        <div className={styles.title}>{props.title || "No Title"}</div>
        <div className={styles.value}>{props.value || "No Value"}</div>
        {/* <div className={styles.icon} style={shade}>
          <FontAwesomeIcon icon={props.icon} />
        </div> */}
      </div>
    </div>
  );
}

export default Card;
