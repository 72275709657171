import React, { useState, useEffect } from "react";
import styles from "./profile.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart/chart";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { currencyFormat, colorLuminance, withMemo } from "helper/global";
import {
  CommonSeriesSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";

function Component(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    response();
    async function response() {
      try {
        setData(null);
        var result = await request({
          url: `${url}/financing/funding`,
          params: { financeScenarioId: props.scenarioId },
        });
        setData(result);
      } catch (error) {
        console.log(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.scenarioId]);

  function onPointClick(e) {
    var year = e.target.originalArgument;
    props.onPointClick && props.onPointClick(year);
  }

  function getColor(seriesName) {
    if (!seriesName || seriesName === "balance") return "#fff";
    var object = data.funds.find((object) => {
      return seriesName.includes(object.name);
    });

    if (seriesName.includes("NotUsed")) return colorLuminance(object.color, 0.3);
    else return object.color;
  }

  // function customizePoint(e) {
  //   return {
  //     color: e.series.name && getColor(e.series.name),
  //   };
  // }

  function customizeText(e) {
    return { text: `Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
  }

  // function markerTemplate(item) {
  //   var color = getColor(item.series.name);
  //   return (
  //     <svg>
  //       <defs>
  //         <pattern id="horzLines" width="8" height="4" patternUnits="userSpaceOnUse">
  //           <line x1="0" y1="0" x2="8" y2="0" stroke="white" strokeWidth="1.5" />
  //         </pattern>
  //       </defs>{" "}
  //       <rect x={0} y={0} width={18} height={18} fill={color}></rect>
  //     </svg>
  //   );
  // }

  function customizeLegendText(e) {
    if (e.seriesName.includes("NotUsed")) return e.seriesName.replace("NotUsed", " Not Used");
    return e.seriesName.replace("Used", " Used");
  }

  // render

  function seriesRender() {
    var series = [];
    data.funds.forEach((object) => {
      var used = `${object.name}Used`;
      var notUsed = `${object.name}NotUsed`;
      series.push(<Series key={used} valueField={used} name={used} color={getColor(used)} />);
      series.push(<Series key={notUsed} valueField={notUsed} name={notUsed} color={getColor(notUsed)} />);
    });
    // data.funds.forEach((object) => {
    //   var notUsed = `${object.name}NotUsed`;
    //   series.push(<Series key={notUsed} valueField={notUsed} name={notUsed} point={{ color: getColor(object.name) }} showInLegend={false} />);
    // });
    series.push(<Series key={"balance"} valueField={"balance"} name={"balance"} color={"#fff"} type={"line"} showInLegend={false} />);
    return series;
  }

  return data ? (
    <Chart dataSource={data.profile} onPointClick={onPointClick}>
      <CommonSeriesSettings argumentField="year" type="stackedBar" />
      <Legend visible={true} verticalAlignment="top" horizontalAlignment="center" customizeText={customizeLegendText} />
      <Tooltip enabled={true} customizeTooltip={customizeText} />
      <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
        <MinorTick visible={true} />
        <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
          <Format type="decimal" />
        </Label>
      </ArgumentAxis>
      <ValueAxis name="amount" title={""}>
        {/* <ConstantLine width={2} value={0} /> */}
      </ValueAxis>
      <ZoomAndPan argumentAxis="both" valueAxis="both" />
      {seriesRender()}
    </Chart>
  ) : (
    <div className={styles.indicator}>
      <LoadIndicator />
    </div>
  );
}

export default withMemo(Component, ["scenarioId"]);
