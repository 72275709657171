import React from "react";
import Profile from "./profile/profile";
import styles from "./styles.module.scss";
import Hierarchy from "components/hierarchy/hierarchy";
import { url } from "config.js";

function Component() {
  // render

  return (
    <div className={styles.main}>
      <Hierarchy
        addButton={false}
        allowEditing={false}
        allowDragging={false}
        multiSelect={true}
        showScenarioName={true}
        countUrl={`${url}/engine/output/node-count`}
        showFilter={true}
        showCount={true}
        filterFields={[
          "Class",
          "Type",
          "Size",
          "Material",
          "Manufacturer",
          "Status",
          "Ownership",
          "Group",
          "Tag",
          "Risk",
          "Condition",
          "CoF",
          "Install Year",
        ]}
        filterType={1}
      >
        <Profile />
      </Hierarchy>
    </div>
  );
}

export default Component;
