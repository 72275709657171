import React, { useState } from "react";
import styles from "./resource-group.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { url } from "config.js";
import { request } from "helper/http-client";
import Resource from "./resource/resource";
import Grid from "components/list/list";
import { Column } from "devextreme-react/data-grid";

function Component() {
  // useState

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  const resourceRender = () => {
    return state.id == null ? null : <Resource id={state.id} />;
  };

  return (
    <div className={styles.main}>
      <div className={styles.resourcegroup}>
        <ResourceGroup onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.resource}>{resourceRender()}</div>
    </div>
  );
}

export default Component;

const ResourceGroup = React.memo(
  (props) => {
    const resourceGroupStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/resourcegroup`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/resourcegroup`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/resourcegroup`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/resourcegroup/${id}`, method: "Delete" });
        },
      }),
    };

    return (
      <Grid
        title={"Resource Group"}
        dataSource={resourceGroupStore}
        selection={{ mode: "single" }}
        onSelectionChanged={props.onSelectionChanged}
        allowDownloading={false}
      >
        <Column dataField="name" caption="Name" />
        <Column dataField="description" caption="Description" visible={false} />
        <Column
          caption={"Notes"}
          dataField={"notes"}
          visible={false}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
        />
      </Grid>
    );
  },
  () => {
    return true;
  }
);
